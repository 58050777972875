
// eslint-disable-next-line
import { mblSingle, mblList } from '@/api/mbl'
// eslint-disable-next-line
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
const mbl = {
  state: {
    mblSearchInputList: [{
      label: '船名Vessel',
      input: '',
      type: 'input',
      key: 'vsl_name'
    }, {
      label: '航次Voyage',
      input: '',
      type: 'input',
      key: 'voyage'
    }, {
      label: '收貨港(PLR)',
      input: '',
      type: 'input',
      key: 'receipt_place'
    }, {
      label: 'SO No',
      input: '',
      type: 'input',
      key: 'so_no'
    }],
    mblTableLabel: [{
      label: 'View',
      minWidth: 130,
      fixed: 'left',
      icon: true,
      prop: 'View',
      node: 'edit',
      url: 'mbl'
    }, {
      label: '船名Vessel',
      minWidth: 200,
      prop: 'vsl_name'
    }, {
      label: '航次Voyage',
      minWidth: 130,
      prop: 'voyage'
    }, {
      label: '收貨港(PLR)',
      prop: 'receipt_place'
    }, {
      label: 'SO No',
      minWidth: 100,
      prop: 'so_no'
    }, {
      label: '船公司代碼',
      minWidth: 180,
      prop: 'carrier'
    }],
    mblDetailTableLabel: [{
      label: 'version',
      prop: 'version',
      minWidth: 50
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: '上傳MCD',
      btn: true,
      prop: 'button',
      minWidth: 20,
      node: 'MCD'
    }, {
      label: '上傳MCA',
      btn: true,
      prop: 'button',
      minWidth: 20,
      node: 'MCA'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    mblOrderid: ''
  },

  getters: {
    mblTableLabel: (state) => state.mblTableLabel,
    mblOrderid: (state) => state.mblOrderid,
    mblDetailTableLabel: (state) => state.mblDetailTableLabel,
    mblMasterItem: (state) => state.mblMasterItem,
    mblSearchInputList: (state) => state.mblSearchInputList
  },

  mutations: {
    mblTableLabel: (state, payload) => { state.mblTableLabel = payload },
    mblOrderid: (state, payload) => { state.mblOrderid = payload },
    mblDetailTableLabel: (state, payload) => { state.mblDetailTableLabel = payload },
    mblMasterItem: (state, payload) => { state.mblMasterItem = payload }
  },
  actions: {
    mblInputList: async (state) => {
      state.commit('searchInputList', state.getters.mblSearchInputList)
    },
    mblSearch: async (state, payload) => {
      state.commit('tableLabel', [])
      // console.log('mblSearch', payload)
      const body = { offset: 0, limit: 10 }
      Object.values(state.getters.searchInputList).forEach(item => {
        // console.log('item', item)
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      console.log('mblSearch body', body)
      var result = await mblList(body)
      console.log('mblSearch result', result)
      // const orderDataInputList = []
      // Object.values(state.getters.mblTableLabel).forEach(async (item, idx) => {
      //   if (idx > 1) {
      //     orderDataInputList.push({ label: item.label, input: result.data.data.ListData[2][item.prop], key: item.prop })
      //   }
      // })
      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            // item.Edit = faEdit
            item.View = faEye
            item.file_name = item.file_name.mc
            state.getters.mblTableLabel[1].path = 'mbl'
            state.getters.mblTableLabel[1].prop2 = 'file_path'
            // item.pre_book_no = state.getters.orderId
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          state.commit('tableLabel', state.getters.mblTableLabel)
          // await state.commit('orderDataInfo', orderDataInputList)
          // state.commit('tableLabel', state.getters.mblDetailTableLabel)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
      console.log('tableData', state.getters.tableData)
      console.log('mblSearch result', result)
    },
    mblDetailSearch: async (state, payload) => {
      state.commit('tableLabel', [])
      // console.log('mblSearch', payload)
      const body = { offset: 0, limit: 10, ship_num: payload }
      Object.values(state.getters.searchInputList).forEach(item => {
        // console.log('item', item)
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      console.log('mblSearch detail body', body)
      var result = await mblSingle(body)
      console.log('mblSearch result', result)
      const orderDataInputList = []
      Object.values(state.getters.mblTableLabel).forEach(async (item, idx) => {
        if (idx > 0) {
          orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
        }
      })
      state.dispatch('getPdfEmailList', 'MC')
      // await state.commit('pdfUrl', result.data.data.ListData[0].file_path)
      switch (result.status) {
        case 200:
          var maxVersion = result.data.data.ListData.length > 0 ? result.data.data.ListData[0].version : 0 // Math.max(...result.data.data.ListData.map(item => item.version))
          console.log('maxVersion', maxVersion)
          await Object.values(result.data.data.ListData).forEach(item => {
            if (Number(item.version) === maxVersion) {
              item.max = true
            } else {
              item.max = false
            }
            item.Edit = faEdit
            item.View = faEye
            // item.messageid = state.getters.orderId
            item.button = true
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          state.commit('tableLabel', state.getters.mblDetailTableLabel)
          if (result.data.data.ListData.length > 0) {
            state.commit('infoData', result.data.data.ListData[0])
          }
          await state.commit('orderDataInfo', orderDataInputList)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
      console.log('tableData', state.getters.tableData)
      console.log('mblSearch detail result', result)
    },
    beforEditBymbl: async (state, payload) => {
      var result = await mblSingle({ pre_book_no: payload.orderId })
      console.log('beforEditBymbl', result)
      state.commit('mblMasterItem', result.data.data.ListData[0])
    },
    mblHandleCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      if (JSON.stringify(state.getters.mblQuery) !== '{}' && state.getters.mblQuery !== undefined) {
        body.track_point = state.getters.mblQuery.track_point
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      result = await mblList(body)
      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            // item.Edit = faEdit
            item.View = faEye
            item.file_name = item.file_name.mc
            state.getters.mblTableLabel[1].path = 'mbl'
            state.getters.mblTableLabel[1].prop2 = 'file_path'
            // item.pre_book_no = state.getters.orderId
          })
          result.data.data.PageData.totalPage = state.getters.pageData.totalPage
          result.data.data.PageData.currentPage = currentPage
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          // await state.commit('orderDataInfo', orderDataInputList)
          // state.commit('tableLabel', state.getters.mblDetailTableLabel)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
      // console.log('handleCurrentChange body', body)
      // console.log('handleCurrentChange result', result)
    }
  }
}

export default mbl
