<template>
  <div>
    <card>
      <template #title>
        {{ $route.meta.title }}
      </template>
      <template #titleBtn>
        <btnList
          @add="addlist = true"
          :hasAddbtn="true"
        />
      </template>
      <template #manipulatlist>
        <inputlist/>
      </template>
      <template #table>
        <tableComponent/>
      </template>
      <template #pagination>
        <pageination/>
      </template>
    </card>
  </div>
</template>

<script>
import card from '@/components/card/card.vue'
import pageination from '@/components/plug/page/pageination.vue'
import inputlist from '@/components/share/inputlist.vue'
import btnList from '@/components/plug/button/button.vue'
import tableComponent from '@/views/mainfile/components/table.vue'
export default {
  components: {
    card,
    pageination,
    inputlist,
    btnList,
    tableComponent
  }
}
</script>

<style lang="scss" module>
</style>
