<template>
  <div>
    <card>
      <template #title>
        {{ $route.meta.title }}
      </template>
      <template #titleBtn>
        <btnList
          :hasAddbtn="true"
          @add="popupfunction('newpopup','新增')"
        />
      </template>
      <template #manipulatlist>
        <tabnav @tabRouter="tabRouter($event)"/>
      </template>
      <template #table>
        <router-view @edit="popupfunction" @delete="deletePermission" />
      </template>
      <template #pagination>
        <pageination/>
      </template>
    </card>
    <component
      :is="componentName"
      v-if="componentName"
      @close="componentName = null"
      @updatePermission="updatePermission"
      :titleBtnText="titleBtnText"
    />
  </div>
</template>

<script>
import card from '@/components/card/card.vue'
import pageination from '@/components/plug/page/pageination.vue'
import btnList from '@/components/plug/button/button.vue'
import tabnav from '@/views/systemfile/components/tablenav.vue'
import popup from '@/views/systemfile/popup/authoritypopup.vue'
import newpopup from '@/views/systemfile/popup/newauthoritypopup.vue'
import { userPermissionAction } from '@/api/system'
export default {
  components: {
    card,
    pageination,
    btnList,
    tabnav,
    popup,
    newpopup
  },
  data () {
    return {
      componentName: null,
      titleBtnText: ''
    }
  },
  methods: {
    popupfunction (componentName, text, row) {
      console.log('!@# componentName', componentName)
      console.log('!@# row', row)
      this.$store.commit('editRowData', row)
      console.log(componentName)
      this.componentName = componentName
      this.titleBtnText = text
    },
    tabRouter (value) {
      if (this.$route.name !== value) {
        this.$router.push({ name: value })
      }
    },
    async updatePermission (row) {
      console.log(row)
      if (!confirm('確定要更新權限:' + row.name)) {
        return
      }
      var body = row
      body.action = 'U'
      const r = await userPermissionAction(body)
      switch (r.code) {
        case '200':
          this.$message({
            type: 'success',
            message: '更新成功'
          })
          await this.$store.dispatch('userPermissions')
          break
        case '400':
          this.$message({
            type: 'error',
            message: '更新失敗(' + r.detail + ')'
          })
          break
        default:
          this.$message({
            type: 'info',
            message: '更新失敗(' + r.detail + ')'
          })
      }
      this.componentName = null
    },
    async deletePermission (row) {
      console.log(row)
      if (!confirm('確定要刪除權限:' + row.name)) {
        return
      }
      var body = {
        action: 'D',
        id: row.id
      }
      const r = await userPermissionAction(body)
      switch (r.code) {
        case '200':
          this.$message({
            type: 'success',
            message: '刪除成功'
          })
          await this.$store.dispatch('userPermissions')
          break
        case '400':
          this.$message({
            type: 'error',
            message: '刪除失敗(' + r.detail + ')'
          })
          break
        default:
          this.$message({
            type: 'info',
            message: '刪除失敗(' + r.detail + ')'
          })
      }
    }
  }
}
</script>

<style lang="scss" module>
</style>
