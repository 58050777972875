import axios from 'axios'

export const hblsoSingle = async (body) => await axios.post('api/hblso/single', body).then((response) => response)// HBL提單作法SO single

export const hblsoList = async (body) => await axios.post('api/hblso/list', body).then((response) => response)

export const hblChecklistSingle = async (body) => await axios.post('api/hbl_checklist/single', body).then((response) => response)

export const hblChecklistList = async (body) => await axios.post('api/hbl_checklist/list', body).then((response) => response)

export const hblSingle = async (body) => await axios.post('api/hbl/single', body).then((response) => response)

export const hblList = async (body) => await axios.post('api/hbl/list', body).then((response) => response)

export const billSingle = async (body) => await axios.post('api/bill/single', body).then((response) => response)
