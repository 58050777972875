
// eslint-disable-next-line
import { containerloadSingle, containerloadList } from '@/api/containerload'
// eslint-disable-next-line
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
const containerload = {
  state: {
    containerloadSearchInputList: [{
      label: '船名Vessel',
      input: '',
      type: 'input',
      key: 'vsl_name'
    }, {
      label: '航次Voyage',
      input: '',
      type: 'input',
      key: 'voyage'
    }, {
      label: '貨櫃場代碼 ',
      input: '',
      type: 'input',
      key: 'cfs_delivery_place'
    }, {
      label: '卸貨港',
      input: '',
      type: 'input',
      key: 'port_discharge'
    }, {
      label: '船期編號',
      input: '',
      type: 'input',
      key: 'ship_num'
    }, {
      label: '結關日',
      input: '',
      type: 'date',
      key: 'closing_date'
    }],
    containerloadTableLabel: [{
      label: 'View',
      minWidth: 130,
      fixed: 'left',
      icon: true,
      prop: 'View',
      node: 'edit',
      url: 'containerload'
    }, {
      label: '船名Vessel',
      minWidth: 200,
      prop: 'vsl_name'
    }, {
      label: '航次Voyage',
      minWidth: 130,
      prop: 'voyage'
    }, {
      label: '貨櫃場代碼',
      prop: 'cfs_delivery_place'
    }, {
      label: '卸貨港',
      minWidth: 180,
      prop: 'port_discharge'
    }, {
      label: '結關日',
      minWidth: 180,
      prop: 'closing_date'
    }, {
      label: '船期編號',
      minWidth: 200,
      prop: 'ship_num'
    }],
    containerloadDetailTableLabel: [{
      label: 'version',
      prop: 'version',
      minWidth: 50
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    containerloadOrderid: ''
  },

  getters: {
    containerloadTableLabel: (state) => state.containerloadTableLabel,
    containerloadOrderid: (state) => state.containerloadOrderid,
    containerloadDetailTableLabel: (state) => state.containerloadDetailTableLabel,
    containerloadMasterItem: (state) => state.containerloadMasterItem,
    containerloadSearchInputList: (state) => state.containerloadSearchInputList
  },

  mutations: {
    containerloadTableLabel: (state, payload) => { state.containerloadTableLabel = payload },
    containerloadOrderid: (state, payload) => { state.containerloadOrderid = payload },
    containerloadDetailTableLabel: (state, payload) => { state.containerloadDetailTableLabel = payload },
    containerloadMasterItem: (state, payload) => { state.containerloadMasterItem = payload }
  },
  actions: {
    containerloadInputList: async (state) => {
      state.commit('searchInputList', state.getters.containerloadSearchInputList)
    },
    containerloadSearch: async (state, payload) => {
      state.commit('tableLabel', [])
      // console.log('containerloadSearch', payload)
      const body = { offset: 0, limit: 10, closing_date: state.getters.todayDate }
      console.log('state.getters.searchInputList', state.getters.searchInputList)
      Object.values(state.getters.searchInputList).forEach(item => {
        // console.log('item', item)
        if ((item.type === 'input' || item.type === 'date') && item.input !== '') {
          body[item.key] = item.input
        }
        // if (item.key === 'closing_date') {
        //   item.input = state.getters.todayDate
        // }
      })
      console.log('containerloadSearch body', body)
      var result = await containerloadList(body)
      console.log('containerloadSearch result', result)
      // const orderDataInputList = []
      // Object.values(state.getters.containerloadTableLabel).forEach(async (item, idx) => {
      //   if (idx > 1) {
      //     orderDataInputList.push({ label: item.label, input: result.data.data.ListData[2][item.prop], key: item.prop })
      //   }
      // })

      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            // item.Edit = faEdit
            item.View = faEye
            state.getters.containerloadTableLabel[1].path = 'containerload'
            state.getters.containerloadTableLabel[1].prop2 = 'file_path'
            // item.pre_book_no = state.getters.orderId
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          state.commit('tableLabel', state.getters.containerloadTableLabel)
          // await state.commit('orderDataInfo', orderDataInputList)
          // state.commit('tableLabel', state.getters.containerloadDetailTableLabel)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
      console.log('containerloadSearch result', result)
    },
    containerloadDetailSearch: async (state, payload) => {
      state.commit('tableLabel', [])
      var editRow = state.getters.editRowData
      console.log('containerloadSearch', payload)
      console.log('containerloadSearch editRow', editRow)
      // const body = { offset: 0, limit: 10, vsl_name: editRow.vsl_name, voyage: editRow.voyage, cfs_delivery_place: editRow.cfs_delivery_place, port_discharge: editRow.port_discharge }
      const body = { offset: 0, limit: 10, vsl_name: payload.vsl_name, voyage: payload.voyage, cfs_delivery_place: payload.cfs_delivery_place, port_discharge: payload.port_discharge }
      // console.log('containerloadDetailSearch body 123', body)
      var result = await containerloadSingle(body)
      console.log('containerloadDetailSearch result', result)
      const orderDataInputList = []
      Object.values(state.getters.containerloadTableLabel).forEach(async (item, idx) => {
        if (idx > 0) {
          // orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
          orderDataInputList.push({ label: item.label, input: payload[item.prop], key: item.prop })
          // orderDataInputList.push({ label: item.label, input: result.data.data.ListData[0][item.prop], key: item.prop })
        }
      })
      state.dispatch('getPdfEmailList', 'CL')
      var tmpResult = []
      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            item.Edit = faEdit
            item.View = faEye
            // item.pre_book_no = state.getters.orderId
            if (item.id !== 343) {
              tmpResult.push(item)
            }
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', tmpResult)
          state.commit('tableLabel', state.getters.containerloadDetailTableLabel)
          if (tmpResult.length > 0) {
            state.commit('infoData', tmpResult[0])
          }
          await state.commit('orderDataInfo', orderDataInputList)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
    },
    beforEditBycontainerload: async (state, payload) => {
      var result = await containerloadSingle({ pre_book_no: payload.orderId })
      console.log('beforEditBycontainerload', result)
      state.commit('containerloadMasterItem', result.data.data.ListData[0])
    },
    containerloadHandleCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      if (JSON.stringify(state.getters.containerloadQuery) !== '{}' && state.getters.containerloadQuery !== undefined) {
        body.track_point = state.getters.containerloadQuery.track_point
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        if ((item.type === 'input' || item.type === 'date') && item.input !== '') {
          body[item.key] = item.input
        }
      })
      result = await containerloadList(body)
      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            // item.Edit = faEdit
            item.View = faEye
            state.getters.containerloadTableLabel[1].path = 'containerload'
            state.getters.containerloadTableLabel[1].prop2 = 'file_path'
            // item.pre_book_no = state.getters.orderId
          })
          result.data.data.PageData.totalPage = state.getters.pageData.totalPage
          result.data.data.PageData.currentPage = currentPage
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          // await state.commit('orderDataInfo', orderDataInputList)
          // state.commit('tableLabel', state.getters.containerloadDetailTableLabel)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
      // console.log('handleCurrentChange body', body)
      // console.log('handleCurrentChange result', result)
    }
  }
}

export default containerload
