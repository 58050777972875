<template>
  <div>
    <card>
      <template #datainput>
        <div :class="$style.outside">
          <datainput
            v-for="(item, i) in orderDataInfo"
            :key="i"
            class="perRowFormat"
          >
            <template #title>
              <div style="width: 100px;">
                {{ $t(item.label) }}
              </div>
            </template>
            <template #data>
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.input"
                placement="top"
              >
                <p :class="$style.text">
                  {{ item.input }}
                </p>
              </el-tooltip>
            </template>
          </datainput>
          <el-button class="btnDownloadPouch" type="primary" v-if="checkPuchRouter" @click="pouchMassDownload">
            Download Pouch
          </el-button>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
import card from '@/components/card/card.vue'
import datainput from '@/components/card/datainput.vue'
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
  // mounted: function () {
  //   console.log('orderId', this.orderId, 'orderDataInfo', this.orderDataInfo)
  // },
  components: {
    card,
    datainput
  },
  computed: {
    ...mapGetters(['spouchReleasePoint']),
    checkPuchRouter: function () {
      var massDownload = false
      switch (this.$router.currentRoute.name) {
        case 'spouchDetailDC':
        case 'spouchDetailMB':
        case 'spouchDetailMA':
        case 'spouchDetailHB':
        case 'spouchDetailIV':
        case 'spouchDetailPL':
          massDownload = true
          break
      }
      return massDownload
    },
    isSpouch: function () {
      console.log(this.$router.currentRoute.name)
      var spouchFlag = false
      switch (this.$router.currentRoute.name) {
        case 'spouchDetailDC':
        case 'spouchDetailMB':
        case 'spouchDetailMA':
        case 'spouchDetailHB':
        case 'spouchDetailIV':
        case 'spouchDetailPL':
          spouchFlag = true
          break
      }
      return spouchFlag
    }
  },
  props: {
    orderId: String,
    orderDataInfo: Array
  },
  methods: {
    async pouchMassDownload () {
      var params = this.$store.state.routerParams
      var body = {
        vsl_name: params.vsl_name,
        voyage: params.voyage,
        carrier_agent: params.carrier_agent,
        dc_no: params.dc_no
      }
      var result = await axios.post('api/pouch/pdflist', body).then((response) => response)
      console.log('pouchMassDownload', result)
      if (result.data.zip_path !== '') {
        window.open(this.globalPDFHost + result.data.zip_path)
      } else {
        this.$message({
          type: 'error',
          message: '找不到檔案'
        })
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" module>
.outside {
  padding: 0 10px 10px 0;
  display: grid;
  grid-template-columns: repeat(4, 23.3%);
  grid-auto-rows: minmax(35px, auto);
  align-items: center;
  grid-gap: 0px 20px;
  position: relative;
}

.text {
  overflow : hidden;
  text-overflow : ellipsis;
  white-space : nowrap;
}
.datepicker{
  width: 80px;
}
</style>
<style scoped>
.perRowFormat{
  border-right: 2px solid #EDEDEE;
  margin-left: 10px;
  height: 35px;
}
.perRowFormat:nth-child(4n+0) {
  border-right: 0;
}
.releaseNotDone{
  border: 1px solid #999999;
  border-radius: 10px;
}
.releaseDone{
  border: 1px solid #4BA676;
  color: #4BA676;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 12px;
  vertical-align: middle;
  line-height: 3;
}
.btnDownloadPouch{
  padding: 10px 20px;
}
</style>
