<template>
  <div>
    <card>
      <!-- <template
      v-if="spouchTitle"
      #title>
        {{ spouchTitle + 'bb' }}
      </template>
      <template v-else-if="$route.params.titleB" #title>
        {{ $route.params.titleB  + 'cc'}}
      </template>
      <template v-else #title>
        {{ $route.meta.subtitle + 'dd' }}
      </template> -->
      <template v-if="$route.meta.class === 'spouchdc2'" #title>
        {{ spouchTitle }}
        <div class="spouchReleaseBlock">
          <div class="spouchDoRelease" v-if="$route.meta.doc_type === 'HB' && currentPouchDetail.pre_book_no && tableData.length>0" >
            <div>
              Release Date：<date-picker v-model="releasePoint" :disabled="spouchReleasePoint !== null" valueType="format" format="YYYY/MM/DD" :class="$style.datepicker"></date-picker>
            </div>
            <div>
              <el-button size="mini" v-if="spouchReleasePoint === null" type="info" icon="el-icon-finished" @click="saveDate">Release</el-button>
              <span class="releaseDone" v-if="spouchReleasePoint !== null">
                done
              </span>
            </div>
          </div>
        </div>
      </template>
      <template v-else #title>
        {{ $route.meta.subtitle }}
      </template>
      <template #rownav>
        <tab
          :class="$style.tab"
          @hasAddBtnFn="hasAddBtn = $event"
          @hasDelBtnFn="hasDelBtn = $event"
          @hasPdfBtnFn="checkPdfBtn($event)"
          @quotationType="quotationType = $event"
        />
      </template>
      <template #table>
        <info/>
        <card :class="$style.noline">
          <template #title>
            <span :class="$style.title">
              上傳紀錄
            </span>
          </template>
          <template #titleBtn>
            <btnList
              :typeBtn="'danger'"
              :hasAddbtn="hasAddBtn"
              :hasDelbtn="hasDelBtn"
              :hasPdfbtn="hasPdfBtn"
              @add="addData"
              @updataPdf="routerFn"
              @del="delDate"
            />
          </template>
        </card>
        <tableComponent
          :tableData="tableData"
          :tableLabel="tableLabel"
          @Edit="openDetail"
          @View="openPdf"
          @SOA="routerFn"
          @ViewPouchBDetail="ViewPouchBDetail"
        />
      </template>
      <template #pagination>
        <pageination/>
      </template>
    </card>
    <addFile
      v-if="addFile"
      @close="addFile = false"
      :quotationType="quotationType"
    />
  </div>
</template>

<script>
import card from '@/components/card/card.vue'
import pageination from '@/components/plug/page/pageination.vue'
import tab from '@/views/list/list/components/tab.vue'
import btnList from '@/components/plug/button/button.vue'
import addFile from '@/views/list/list/components/addfile.vue'
// import pouchBCTable from '@/views/list/list/components/pouchBTable.vue'
import tableComponent from '@/components/plug/table.vue'
import info from '@/views/list/list/components/info.vue'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
export default {
  computed: {
    ...mapGetters(['tableData', 'tableLabel', 'commRsp', 'pafupdata', 'orderDataInfo', 'deliveryDetailItems', 'deliveryMasterItem', 'soTab', 'orderId', 'spouchTitle', 'spouchLevel', 'routerParams', 'spouchReleasePoint'])
  },
  components: {
    card,
    pageination,
    tab,
    btnList,
    addFile,
    tableComponent,
    info,
    DatePicker
  },
  data () {
    return {
      hasAddBtn: null,
      hasDelBtn: null,
      hasPdfBtn: null,
      quotationType: null,
      addFile: false,
      orderDataItem: {},
      currentPouchDetail: {},
      releasePoint: ''
    }
  },
  methods: {
    ...mapActions(['delTableSelectData', 'deliveryDetailSearch']),
    saveDate: async function () {
      if (!confirm('確定要Release?')) {
        return
      }
      if (!this.releasePoint.match(/^[0-9]{4}\/[0-9]{2}\/[0-9]{2}$/)) {
        alert('請輸入時間')
        return
      }
      var result = this.$store.dispatch('saveReleasePoint', {
        releasePoint: this.releasePoint,
        pre_book_no: this.currentPouchDetail.pre_book_no
      })
      if (result) {
        this.$message({
          type: 'success',
          message: 'Release Done'
        })
      } else {
        this.$message({
          type: 'Error',
          message: 'release fail'
        })
      }
    },
    checkPdfBtn (ev) {
      switch (this.$route.name) {
        case 'spouchdc2':
          if (this.spouchLevel === '') {
            console.log('this.hasPdfBtn = true')
            this.hasPdfBtn = true
          } else {
            this.hasPdfBtn = false
          }
          break
        default:
          this.hasPdfBtn = ev
          break
      }
    },
    ViewPouchBDetail (row, item) {
      this.currentPouchDetail = row
      this.releasePoint = row.release_point_createon
      row.ViewPouchBDetail = true
      if (this.$store.getters.spouchLevel === 'B') {
        row.pouchDetailType = 'B'
      }
      if (this.$store.getters.spouchLevel === 'C') {
        row.pouchDetailType = 'C'
      }
      this.$store.commit('spouchLevel', '')
      this.$store.dispatch('spouchDetailSearch', row)
      // console.log('params:', row, item)
    },
    async routerFn (val1, val2) {
      if (this.pafupdata !== null) {
        console.log('routerFn val1', val1, 'val2', val2, 'orderDataInfo', this.orderDataInfo)
        Object.values(this.orderDataInfo).forEach(item => {
          this.orderDataItem[item.key] = String(item.input).replace(/\s*/g, '')
        })
        var emailList = val1
        console.log('uploadFile', this.$route.name)
        console.log('file', this.pafupdata)
        const form = new FormData()
        form.append('file', this.pafupdata)
        // form.append('JobNo', this.orderDataItem.pre__book__no)
        // form.append('sono', (this.orderDataItem.so_no === '' ? '0' : this.orderDataItem.so_no))
        var fname = ''
        switch (this.$route.name) {
          case 'measure':
            fname = this.orderDataItem.vsl_name + '__' + this.orderDataItem.voyage + '__' + this.orderDataItem.cfs_delivery_place + '__' + this.orderDataItem.closing_date + '__' + this.orderDataItem.marine_survey + '__MS.pdf'
            break
          case 'containerload':
            fname = this.orderDataItem.vsl_name + '__' + this.orderDataItem.voyage + '__' + this.orderDataItem.cfs_delivery_place + '__' + this.orderDataItem.port_discharge + '__CL.pdf'
            break
          case 'mblsi':
            switch (this.soTab) {
              case 0:
                console.log('this.$store.getters.pafupdataType', this.$store.getters.pafupdataType)
                if (this.$store.getters.pafupdataType === '') {
                  fname = fname = this.orderDataItem.vsl_name + '__' + this.orderDataItem.voyage + '__' + this.orderDataItem.carrier_agent + '__' + this.orderDataItem.so_no + '__SI.pdf'
                } else {
                  fname = fname = this.orderDataItem.vsl_name + '__' + this.orderDataItem.voyage + '__' + this.orderDataItem.carrier_agent + '__' + this.orderDataItem.so_no + '__SIA.pdf'
                }
                break
            }
            break
          case 'mbl':
            switch (this.soTab) {
              case 0:
                console.log('this.$store.getters.pafupdataType', this.$store.getters.pafupdataType)
                if (this.$store.getters.pafupdataType === '') {
                  fname = fname = this.orderDataItem.vsl_name + '__' + this.orderDataItem.voyage + '__' + this.orderDataItem.receipt_place + '__' + this.orderDataItem.so_no + '__MC.pdf'
                } else if (this.$store.getters.pafupdataType === 'MCA') {
                  fname = fname = this.orderDataItem.vsl_name + '__' + this.orderDataItem.voyage + '__' + this.orderDataItem.receipt_place + '__' + this.orderDataItem.so_no + '__MCA.pdf'
                } else if (this.$store.getters.pafupdataType === 'MCD') {
                  fname = fname = this.orderDataItem.vsl_name + '__' + this.orderDataItem.voyage + '__' + this.orderDataItem.receipt_place + '__' + this.orderDataItem.so_no + '__MCD.pdf'
                }
                break
            }
            break
          case 'spouchDetailDC':
            fname = this.orderDataItem.vsl_name + '__' + this.orderDataItem.voyage + '__' + this.orderDataItem.carrier_agent + '__' + this.orderDataItem.dc_no + '__DC.pdf'
            break
          case 'spouchDetailMB':
            fname = this.orderDataItem.vsl_name + '__' + this.orderDataItem.voyage + '__' + this.orderDataItem.carrier_agent + '__' + this.orderDataItem.dc_no + '__' + this.currentPouchDetail.mbl_no + '__MB.pdf'
            break
          case 'spouchDetailMA':
            fname = this.orderDataItem.vsl_name + '__' + this.orderDataItem.voyage + '__' + this.orderDataItem.carrier_agent + '__' + this.orderDataItem.dc_no + '__' + this.currentPouchDetail.mbl_no + '__MA.pdf'
            break
          case 'spouchDetailHB':
            fname = this.orderDataItem.vsl_name + '__' + this.orderDataItem.voyage + '__' + this.orderDataItem.carrier_agent + '__' + this.orderDataItem.dc_no + '__' + this.currentPouchDetail.o_bl_no + '__HB.pdf'
            if (typeof val1 !== 'undefined') {
              if (val2.node === 'SOA') {
                fname = val1.vsl_name + '__' + val1.voyage + '__' + val1.carrier_agent + '__' + val1.dc_no + '__' + val1.o_bl_no + '__HBA.pdf'
              }
            }
            break
          case 'spouchDetailIV':
            fname = this.orderDataItem.vsl_name + '__' + this.orderDataItem.voyage + '__' + this.orderDataItem.carrier_agent + '__' + this.orderDataItem.dc_no + '__' + this.currentPouchDetail.o_bl_no + '__IV.pdf'
            break
          case 'spouchDetailPL':
            fname = this.orderDataItem.vsl_name + '__' + this.orderDataItem.voyage + '__' + this.orderDataItem.carrier_agent + '__' + this.orderDataItem.dc_no + '__' + this.currentPouchDetail.o_bl_no + '__PL.pdf'
            break
        }
        console.log('fname', fname)
        form.append('filename', fname)
        form.append('email', JSON.stringify(emailList))
        console.log('form', form)
        const rsp = await axios.post('api/delivery/pdfuploader/' + fname, form, { headers: { 'Content-Type': 'multipart/form-data;application/pdf', Accept: '*/*' } }).then((response) => response)// 報價單PDF上傳
        console.log('rsp', rsp)
        if (rsp.status === 200) {
          this.$message({
            type: 'success',
            message: '上傳成功!'
          })
          var params = {
            ...this.$router.currentRoute.params,
            ...this.$router.currentRoute.meta
          }
          switch (this.$route.name) {
            case 'measure':
            case 'mblsi':
            case 'mbl':
            case 'containerload':
              this.$store.dispatch('preProcessinghandler', { name: this.$route.name, params: params })
              break
            case 'spouchDetailDC':
              await this.$store.dispatch('spouchDetailSearch', params)
              break
            case 'spouchDetailMB':
            case 'spouchDetailMA':
              await this.$store.commit('spouchLevel', 'B')
              await this.$store.dispatch('spouchDetailSearch', params)
              break
            case 'spouchDetailHB':
            case 'spouchDetailIV':
            case 'spouchDetailPL':
              await this.$store.commit('spouchLevel', 'C')
              await this.$store.dispatch('spouchDetailSearch', params)
              break
            default:
              this.$store.dispatch('preProcessinghandler', { name: this.$route.name, params: { orderId: this.orderDataItem.pre_book_no } })
          }
        } else {
          this.$message({
            type: 'info',
            message: '上傳失敗!'
          })
        }
        this.$store.commit('pafupdataType', '')
        this.$store.commit('pafupdata', null)
        this.$store.commit('showUpload', true)
      }
    },
    openDetail (val1, val2) {
      console.log('openDetail val1', val1, 'val2', val2)
      this.$store.commit('editRowData', val1)
      const parm = { orderId: val1.pre_book_no === undefined ? val1.messageid : val1.pre_book_no, fileType: 'data', operating: 'edit', version: val1.version }
      // console.log('!@!@!!!!', parm)
      // console.log('!@!@!!!!', this.$route.name)
      // console.log('!@!@!!!!', this.quotationType)
      let pathName = ''
      switch (this.$route.name) {
        case 'measure':
          pathName = 'measureEdit'
          break
        default:
          pathName = this.$route.name
          break
      }
      this.$router.push({
        name: pathName,
        params: parm
      }).catch(err => err)
    },
    openPdf (val1, val2) {
      console.log('val1', val1, 'val2', val2, 'this.$route.name', this.$route.name, 'params', this.$route.params)
      this.$store.commit('pdfUrl', this.globalPDFHost + val1.file_path)
      var pathName = ''
      switch (this.$route.name) {
        case 'measure':
          pathName = 'measureEdit'
          break
        case 'containerload':
          pathName = 'containerloadView'
          break
        case 'mblsi':
          if (val1.file_path.si !== undefined) {
            this.$store.commit('pdfUrl', this.globalPDFHost + val1.file_path.si)
          }
          if (val1.file_path.sia !== undefined) {
            this.$store.commit('pdfUrlBySOA', this.globalPDFHost + val1.file_path.sia)
          }
          pathName = 'mblsiView'
          break
        case 'mbl':
          if (val1.file_path.mc !== undefined) {
            this.$store.commit('pdfUrl', this.globalPDFHost + val1.file_path.mc)
          }
          if (val1.file_path.mca !== undefined) {
            this.$store.commit('pdfUrlBySOA', this.globalPDFHost + val1.file_path.mca)
          }
          if (val1.file_path.mcd !== undefined) {
            this.$store.commit('pdfUrlBySOA2', this.globalPDFHost + val1.file_path.mcd)
          }
          pathName = 'mblView'
          break
        case 'spouchDetailDC':
          console.log(this.routerParams)
          pathName = 'spouchdc2View'
          break
        case 'spouchDetailMB':
          pathName = 'spouchmb2View'
          break
        case 'spouchDetailMA':
          pathName = 'spouchma2View'
          break
        case 'spouchDetailHB':
          pathName = 'spouchhb2View'
          break
        case 'spouchDetailIV':
          pathName = 'spouchiv2View'
          break
        case 'spouchDetailPL':
        // case 'spouchpl2':
          pathName = 'spouchpl2View'
          break
        default:
          break
      }
      console.log('check this.routerParams.hba_file_path', this.routerParams)
      if (this.routerParams.hba_file_path !== undefined) {
        this.$store.commit('pdfUrlBySOA', this.globalPDFHost + this.routerParams.hba_file_path)
      }
      this.$store.commit('infoData', val1)
      this.$router.push({
        name: pathName,
        params: { orderId: val1.pre_book_no === undefined ? val1.messageid : val1.pre_book_no, fileType: 'pdf', operating: 'view', version: val1.version, titleA: this.$route.params.titleA, titleB: this.$route.params.titleB }
      })
    },
    addData () {
      this.addFile = true
      switch (this.quotationType) {
        case 'quotation':
          Object.values(this.orderDataInfo).forEach(item => {
            this.orderDataItem[item.label] = item.input
          })
          this.$store.commit('deliveryDetailItems', [])
          this.$store.commit('deliveryMasterItem', { pre_book_no: this.orderDataItem.pre_book_no })
          console.log('deliveryMasterItem', this.orderDataInfo)
          break
        case 'notice':
          this.$store.commit('editRowData', {})
          break
      }
    },
    async delDate () {
      console.log('delDate', this.$route.params)
      await this.delTableSelectData(this.$route.params)
      switch (this.commRsp.status) {
        case '200':
          this.$message({
            type: 'success',
            message: '成功'
          })
          break
        default:
          this.$message({
            type: 'info',
            message: '失敗'
          })
          break
      }
    }
  },
  watch: {
    spouchLevel (newval, oldval) {
      console.log('newval', newval, 'oldval', oldval)
      if (newval === '') {
        console.log('this.hasPdfBtn = true')
        this.hasPdfBtn = true
      } else {
        this.hasPdfBtn = false
      }
    }
  },
  updated () {
    console.log('routerParams', this.routerParams)
  }
}
</script>

<style lang="scss" module>
.title {
  font-size: 16px;
  font-weight: 500;
  color: #68686a;
}

.table {
  :global(.el-table__body-wrapper) {
    min-height: 475px;
  }
}

.icon {
  font-size: 20px;
  font-weight: bold;
  color: #39A85D;
  cursor: pointer;

  &.margin {
    margin-right: 10px;
  }
}

.btn {
  margin-left: auto;
    align-self: flex-end;
}

.tab {
  padding: 0 20px;
  box-sizing: border-box;
}

.noline {
  border: 0 !important;
}
</style>
<style scoped>
.releaseDone{
  border: 1px solid #4BA676;
  color: #4BA676;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 12px;
  vertical-align: middle;
  line-height: 3;
}
.spouchReleaseBlock{
  position:relative;
  min-width:340px;
}
.spouchReleaseBlock .spouchDoRelease{
  position: absolute;
  right: 10px;
  top: 0px;
  display:flex;
  font-size:16px;
}
.releaseDone{
  border: 1px solid #4BA676;
  color: #4BA676;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 12px;
  vertical-align: middle;
  line-height: 3;
}
.mx-datepicker{
  width:120px;
  margin-right: 10px;
}
</style>
