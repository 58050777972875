import axios from 'axios'

export const userList = async (body) => await axios.post(`api/user/list?timestamp=${new Date().getTime()}`, body).then((response) => response.data)// 使用者列表

export const userCreate = async (body) => await axios.post('api/user/create', body).then((response) => response)// 使用者新增

export const userUpdate = async (body) => await axios.post('api/user/update', body).then((response) => response.data)// 使用者修改

export const userAction = async (body) => await axios.post('api/user/action', body).then((response) => response.data)// 使用者修改

export const userUpdateStatus = async (body) => await axios.post('api/update/status', body).then((response) => response.data)// 使用者單獨修改

export const userDelete = async (body) => await axios.post('api/user/delete', body).then((response) => response.data)// 使用者刪除

export const userDetail = async (body) => await axios.post('api/user/detail', body).then((response) => response.data)// 使用者清單

export const paswordChange = async (body) => await axios.post('api/password_change', body).then((response) => response.data)// 使用者清單

export const companyList = async (body) => await axios.post(`api/user/customer_list?timestamp=${new Date().getTime()}`, body).then((response) => response.data)// 使用者列表
