<template>
  <el-table
    :data="tableData"
    tooltip-effect="dark"
    :class="$style.table"
    empty-text="無數據資料"
    :border="true"
    @select="selectChange"
    @select-all="selectGateAll"
  >
    <el-table-column
      type="selection"
      width="50"
      align="center"
      fixed="left"
    />
    <el-table-column
      label="基本資料代碼"
      align="center"
      prop="basic_code"
    />
    <el-table-column
      label="英文名稱"
      prop="english_name"
      align="center"
    />
    <el-table-column
      label="中文名稱"
      prop="local_name"
      align="center"
    />
    <el-table-column
      label="資料類別"
      prop="basic_type"
      align="center"
    />
    <el-table-column
      label="傳送者"
      prop="transfer_by"
      align="center"
    />
    <el-table-column
      label="建立日期"
      prop="transfer_dt"
      align="center"
    />
    <el-table-column
      label="操作"
      align="center"
      min-width="30"
    >
      <template slot-scope="scope">
        <i
          class="el-icon-edit"
          :class="$style.icon"
          @click="$emit('edit', '編輯', scope.row)"
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  mounted: function () {
    console.log('tableData', this.tableData)
    console.log('tableLabel', this.tableLabel)
  },
  props: {
    tableHasSelect: Boolean,
    tableHeight: Number,
    tableData: Array,
    tableLabel: Array
  },
  methods: {
    ...mapMutations(['tableSelectData']),
    selectChange (row) {
      this.$store.commit('tableSelectData', row)
      console.log('selectChange', this.$store.getters.tableSelectData)
    },
    selectGateAll (row) {
      this.$store.commit('tableSelectData', row)
      console.log('selectGateAll', this.$store.getters.tableSelectData)
    }
  }
}
</script>

<style lang="scss" module>
.table {
  :global(.el-table__body-wrapper) {
    min-height: 475px;
  }
}

.icon {
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
  color: #39a85d;
}
</style>
