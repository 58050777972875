<template>
  <el-table
    :data="tableData"
    tooltip-effect="dark"
    :class="$style.table"
    empty-text="無數據資料"
    :border="true"
  >
    <el-table-column
      label="角色ID"
      align="center"
      prop="id"
    />
    <el-table-column
      label="角色名稱"
      prop="name"
      align="center"
    />
    <el-table-column
      label="角色說明"
      prop="remark"
      align="center"
    />
    <!---<el-table-column
      label="狀態"
      prop="enable"
      align="center"
      width="120"
    >
      <template slot-scope="scope">
        <el-switch v-model="scope.row.enable"/>
      </template>
    </el-table-column>--->
    <el-table-column
      label="操作"
      align="center"
      width="60"
    >
      <template slot-scope="scope">
        <i
          class="el-icon-edit"
          :class="$style.icon"
          @click="$emit('edit','popup','編輯',scope.row)"
        />
      </template>
    </el-table-column>
    <el-table-column
      label="刪除"
      align="center"
      width="60"
    >
     <template slot-scope="scope">
      <i
        class="el-icon-delete"
        :class="$style.icon"
        @click="$emit('delete',scope.row)"
      />
       </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['tableData'])
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" module>
.table {
  :global(.el-table__body-wrapper) {
    min-height: 475px;
  }
}

.icon {
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
  color: #39A85D;
}
</style>
