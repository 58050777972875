<template>
  <div
    :class="[$style.icon, {icondisabled: editDisable}]"
    @click="iconClick"
  >
    <FontAwesomeIcon
      :icon="icon"
    />
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    icon: Object
  },
  computed: {
    editDisable () {
      return this.icon.iconName === 'edit' && (this.role === 'FW' || this.role === 'LC')
    }
  },
  methods: {
    iconClick () {
      if (!this.editDisable) {
        this.$emit('click')
      }
    }
  },
  data () {
    return {
      role: localStorage.getItem('basic_type')
    }
  }
}
</script>

<style lang="scss" module>
.icon {
  font-size: 22px;
  cursor: pointer;
  color: #9dc7ab;

  :hover {
    color: #39A85D;
  }

  :active {
    color: #628a6f;
  }
}
</style>
<style scoped>
.icondisabled {
  color: #ccc;
}
.icondisabled :hover {
  color: #ccc !important;
}
.icondisabled :active {
  color: #ccc !important;
}
</style>
