<template>
  <el-tabs v-model="active" @tab-click="hasAddBtnFn">
    <template v-for="(item, i) in list">
      <el-tab-pane
        :label="item.label"
        :name="item.key"
        :key="i"
      />
    </template>
  </el-tabs>
</template>

<script>
export default {
  data () {
    return {
      active: null,
      list: [],
      spouchDispatched: false
    }
  },
  watch: {
    $route () {
      this.tabFunction()
      this.hasAddBtnFn({ index: 0 })
    }
  },
  methods: {
    async hasAddBtnFn (val) {
      if (val.index === undefined || isNaN(val.index)) {
        val.index = 0
      }
      console.log('hasAddBtnFn', this.list, val)
      this.$emit('hasAddBtnFn', this.list[val.index].addBtn)
      this.$emit('hasDelBtnFn', this.list[val.index].delBtn)
      this.$emit('hasPdfBtnFn', this.list[val.index].pdfBtn)
      this.$emit('quotationType', this.list[val.index].key)
      // console.log('!!!!!!!!!!!', val)
      // console.log('!!!!!!!!!!!', this.list[Number(val.index)].addBtn, this.list[Number(val.index)].delBtn, this.list[Number(val.index)].pdfBtn)
      // console.log('!!!!!!!!!!!', val.index)
      this.$store.commit('soTab', Number(val.index))
      switch (this.$route.name) {
        case 'soReleased':
        case 'ivReleased':
        case 'plReleased':
          switch (Number(val.index)) {
            case 0:
              this.active = 'bill'
              // await this.$store.dispatch('sethblsoSingleTableLabel')
              // console.log('$$$$$$$$', this.$store.getters.hblsoSingleTableLabel)
              // console.log('$$$$$$$$', this.$store.getters.tableLabel)
              await this.$store.dispatch('hblsoSingleSearch', { messageid: this.$route.params.orderId })
              break
            case 1:
              this.active = 'invoice'
              // await this.$store.dispatch('setinvoicePackinglistTableLabel')
              await this.$store.dispatch('invoicePackinglistSearch', { orderId: this.$route.params.orderId, file_type: 'IV' })
              break
            case 2:
              this.active = 'packingList'
              // await this.$store.dispatch('setinvoicePackinglistTableLabel')
              await this.$store.dispatch('invoicePackinglistSearch', { orderId: this.$route.params.orderId, file_type: 'PL' })
              break
          }
          break
        case 'documentReleased':
          switch (Number(val.index)) {
            case 0:
              this.active = 'lading'
              await this.$store.dispatch('hblSingleSearch', { orderId: this.$route.params.orderId })
              break
            case 1:
              this.active = 'customer'
              await this.$store.dispatch('billSingleSearch', { orderId: this.$route.params.orderId })
              break
          }
          if (this.$store.getters.orderDataInfo.length < 1) {
            await this.$store.dispatch('deliveryDetailSearch', { orderId: this.$route.params.orderId, withoutInfoData: true })
          }
          break
        case 'spouchDetailDC':
        case 'spouchDetailMA':
        case 'spouchDetailMB':
        case 'spouchDetailHB':
        case 'spouchDetailIV':
        case 'spouchDetailPL':
          var params = this.$router.currentRoute.params
          params.doc_type = this.$router.currentRoute.meta.doc_type
          if (!params.carrier_agent && !params.voyage) {
            // this.$router.push({ name: 'spouchdc' })
            // return
          }
          if (this.spouchDispatched) {
            var spouchUrl = ''
            var strDocType = ''
            switch (Number(val.index)) {
              case 0:
                strDocType = 'DC'
                break
              case 1:
                strDocType = 'MB'
                break
              case 2:
                strDocType = 'MA'
                break
              case 3:
                strDocType = 'HB'
                break
              case 4:
                strDocType = 'IV'
                break
              case 5:
                strDocType = 'PL'
                break
            }
            spouchUrl = `/spouch/${strDocType}/${params.vsl_name}/${params.voyage}/${params.carrier_agent}/${params.dc_no}/${params.mbl_no}/${params.o_bl_no}`
            this.$router.push({
              path: spouchUrl,
              params: { showNode: 'display', titleA: '表單管理-titleA', titleB: 'POUCH-titleB' }
            })
            this.spouchDispatched = false
            return
          }
          switch (params.doc_type) {
            case 'DC':
              this.active = 'spouchdc2'
              this.$store.commit('spouchLevel', '')
              this.$store.commit('spouchTitle', '國外代理帳單')
              await this.$store.dispatch('spouchDetailSearch', { doc_type: 'DC', voyage: params.voyage, carrier_agent: params.carrier_agent, vsl_name: params.vsl_name, dc_no: params.dc_no })
              // await this.$store.dispatch('spouchDetailSearch', { doc_dype: 'DC', dc_no: this.$store.getters.dcNo })
              break
            case 'MB':
              this.active = 'spouchmb2'
              this.$store.commit('spouchLevel', 'B')
              this.$store.commit('spouchTitle', 'MB/L(主提單)')
              await this.$store.dispatch('spouchDetailSearch', { doc_type: 'MB', voyage: params.voyage, carrier_agent: params.carrier_agent, vsl_name: params.vsl_name, dc_no: params.dc_no })
              break
            case 'MA':
              this.active = 'spouchma2'
              this.$store.commit('spouchLevel', 'B')
              this.$store.commit('spouchTitle', 'Manifest(艙單)')
              await this.$store.dispatch('spouchDetailSearch', { doc_type: 'MA', voyage: params.voyage, carrier_agent: params.carrier_agent, vsl_name: params.vsl_name, dc_no: params.dc_no })
              break
            case 'HB':
              this.active = 'spouchhb2'
              this.$store.commit('spouchTitle', '(分提單) HB/L')
              this.$store.commit('spouchLevel', 'C')
              await this.$store.dispatch('spouchDetailSearch', { doc_type: 'HB', voyage: params.voyage, carrier_agent: params.carrier_agent, vsl_name: params.vsl_name, dc_no: params.dc_no })
              break
            case 'IV':
              this.active = 'spouchiv2'
              this.$store.commit('spouchTitle', 'Invoice')
              this.$store.commit('spouchLevel', 'C')
              await this.$store.dispatch('spouchDetailSearch', { doc_type: 'IV', voyage: params.voyage, carrier_agent: params.carrier_agent, vsl_name: params.vsl_name, dc_no: params.dc_no })
              break
            case 'PL':
              this.active = 'spouchpl2'
              this.$store.commit('spouchTitle', 'Packing List')
              this.$store.commit('spouchLevel', 'C')
              await this.$store.dispatch('spouchDetailSearch', { doc_type: 'PL', voyage: params.voyage, carrier_agent: params.carrier_agent, vsl_name: params.vsl_name, dc_no: params.dc_no })
              break
          }
          this.spouchDispatched = true
          break
      }
    },
    tabFunction () {
      const routerName = this.$route.name
      switch (routerName) {
        case 'shipment':
          this.active = 'quotation'
          this.list = [{ label: '出貨通知單', key: 'quotation', addBtn: true, delBtn: true, pdfBtn: true }]
          break
        case 'completeBooking':
          this.active = 'notice'
          this.list = [{ label: '裝船通知書', key: 'notice', addBtn: true, delBtn: true, pdfBtn: true }]
          break
        case 'cargoMeasure':
          this.active = 'cargo'
          this.list = [{ label: '丈量證明文件', key: 'cargo', addBtn: false, delBtn: false, pdfBtn: true }]
          break
        case 'soReleased':
          this.$store.commit('soTab', 0)
          this.active = 'bill'
          this.list = [{
            label: 'HB/L 提單作法 (S/O)', key: 'bill', addBtn: false, delBtn: false, pdfBtn: true
          }, {
            label: 'Invoice', key: 'invoice', addBtn: false, delBtn: false, pdfBtn: true
          }, {
            label: 'PackingList', key: 'packingList', addBtn: false, delBtn: false, pdfBtn: true
          }]
          break
        case 'ivReleased':
          this.$store.commit('soTab', 1)
          this.active = 'invoice'
          this.list = [{
            label: 'HB/L 提單作法 (S/O)', key: 'bill', addBtn: false, delBtn: false, pdfBtn: true
          }, {
            label: 'Invoice', key: 'invoice', addBtn: false, delBtn: false, pdfBtn: true
          }, {
            label: 'PackingList', key: 'packingList', addBtn: false, delBtn: false, pdfBtn: true
          }]
          break
        case 'plReleased':
          this.$store.commit('soTab', 2)
          this.active = 'packingList'
          this.list = [{
            label: 'HB/L 提單作法 (S/O)', key: 'bill', addBtn: false, delBtn: false, pdfBtn: true
          }, {
            label: 'Invoice', key: 'invoice', addBtn: false, delBtn: false, pdfBtn: true
          }, {
            label: 'PackingList', key: 'packingList', addBtn: false, delBtn: false, pdfBtn: true
          }]
          break
        case 'checkReleased':
          this.active = 'checklist'
          this.list = [{ label: 'HB/L 核對單', key: 'checklist', addBtn: false, delBtn: false, pdfBtn: true }]
          break
        case 'quotationConfirmed':
          this.active = 'confirmed'
          this.list = [{ label: '報價確認單', key: 'confirmed', addBtn: false, delBtn: false, pdfBtn: true }]
          break
        case 'documentReleased':
          this.active = 'lading'
          this.list = [{
            label: 'HB/L 提單', key: 'lading', addBtn: false, delBtn: false, pdfBtn: true
          }, {
            label: '客戶帳單', key: 'customer', addBtn: false, delBtn: false, pdfBtn: true
          }]
          break
        // case 'pouchReceived':
          // this.active = 'lading'
          // this.list = [{
          //  label: '分提單 (HB/L)', key: 'lading', addBtn: true
          // }, {
          //  label: '商業發票', key: 'invoice', addBtn: false
          // }, {
          //  label: '裝箱清單 (PackingList)', key: 'packingList', addBtn: false
          // }]
          // break
        case 'measure':
          this.active = 'measure'
          this.list = [{ label: '貨物丈量單', key: 'measure', addBtn: false, delBtn: false, pdfBtn: true }]
          break
        case 'containerload':
          this.active = 'containerload'
          this.list = [{ label: '裝櫃清單', key: 'containerload', addBtn: false, delBtn: false, pdfBtn: true }]
          break
        case 'mblsi':
          this.active = 'mblsi'
          this.list = [{ label: '表單管理-MB/L 提單做法(SI)', key: 'mblsi', addBtn: false, delBtn: false, pdfBtn: true }]
          break
        case 'mbl':
          this.active = 'mbl'
          this.list = [{ label: '表單管理-MB/L 核對單', key: 'mbl', addBtn: false, delBtn: false, pdfBtn: true }]
          break
        case 'pouchDocument':
          this.active = 'dinvoice'
          this.list = [{
            label: '代理帳單', key: 'dinvoice', addBtn: true
          }, {
            label: 'MB/L 主提單', key: 'mblmain', addBtn: false
          }, {
            label: 'Manifest(艙單)', key: 'manifest', addBtn: false
          }]
          break
        case 'pouchReceived':
        case 'spouchDetailDC':
        case 'spouchDetailMA':
        case 'spouchDetailMB':
        case 'spouchDetailHB':
        case 'spouchDetailIV':
        case 'spouchDetailPL':
          this.active = 'spouchdc2'
          this.list = [{
            label: '國外代理帳單', key: 'spouchdc2', addBtn: false, pdfBtn: true
          }, {
            label: 'MB/L(主提單)', key: 'spouchmb2', addBtn: false, pdfBtn: true
          }, {
            label: 'Manifest(艙單)', key: 'spouchma2', addBtn: false, pdfBtn: true
          }, {
            label: '(分提單) HB/L', key: 'spouchhb2', addBtn: false, pdfBtn: true
          }, {
            label: 'Invoice', key: 'spouchiv2', addBtn: false, pdfBtn: true
          }, {
            label: 'Packing List', key: 'spouchpl2', addBtn: false, pdfBtn: true
          }]
          break
      }
    }
  },
  created () {
    this.tabFunction()
    this.hasAddBtnFn({ index: this.$store.getters.soTab })
  }
}
</script>
