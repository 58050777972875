
// eslint-disable-next-line
import { hblSingle, hblList, billSingle, pdfEmails } from '@/api/hblso'

// eslint-disable-next-line
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
const documentReleased = {
  state: {
    hblSingleTableLabel: [{
      label: 'version',
      prop: 'version',
      minWidth: 50
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'Edit',
      prop: 'Edit',
      icon: true,
      minWidth: 20,
      node: 'Edit',
      key: 'id'
    }, {
      label: '上傳HBA',
      btn: true,
      prop: 'button',
      minWidth: 20,
      node: 'SOA'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    billSingleTableLabel: [{
      label: 'version',
      prop: 'version',
      minWidth: 50
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'Edit',
      prop: 'Edit',
      icon: true,
      minWidth: 20,
      node: 'Edit',
      key: 'id'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    hblTab: 0
  },

  getters: {
    hblSingleTableLabel: (state) => state.hblSingleTableLabel,
    billSingleTableLabel: (state) => state.billSingleTableLabel
  },

  mutations: {
    hblSingleTableLabel: (state, payload) => { state.hblSingleTableLabel = payload },
    billSingleTableLabel: (state, payload) => { state.billSingleTableLabel = payload }
  },
  actions: {
    setHblSingleTableLabel: async (state) => {
      await state.commit('tableLabel', state.getters.hblSingleTableLabel)
    },
    setBillSingleTableLabel: async (state) => {
      await state.commit('tableLabel', state.getters.billSingleTableLabel)
    },
    hblSingleSearch: async (state, payload) => {
      await state.commit('tableLabel', [])
      await state.commit('pdfEmailList', [])
      console.log('hblSingleSearch payload', payload)
      state.dispatch('getPdfEmailList', 'HB')
      const body = { offset: 0, limit: 10 }
      if (payload !== null) {
        body.pre_book_no = payload.orderId
        if (payload.version !== undefined) {
          body.version = payload.version
        }
      }
      console.log('hblSingleSearch body', body)
      var result = await hblSingle(body)
      state.dispatch('singleFileInfoData', { rows: result.data, pre_book_no: '', pdf_serial: 0 })
      console.log('hblSingleSearch result', result)
      const orderDataInputList = []
      Object.values(state.getters.formlistTableLabel).forEach(async (item, idx) => {
        if (idx > 1) {
          orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
        }
      })
      switch (result.status) {
        case 200:
          // var rsp = await hblSingle({ pre_book_no: body.pre_book_no })
          var maxVersion = result.data.data.ListData[0].version // Math.max(...result.data.data.ListData.map(item => item.version))
          console.log('maxVersion', maxVersion)
          await Object.values(result.data.data.ListData).forEach(item => {
            if (Number(item.version) === maxVersion) {
              item.max = true
            } else {
              item.max = false
            }
            item.Edit = faEdit
            item.View = faEye
            item.messageid = state.getters.orderId
            item.button = true
          })
          await state.commit('pageData', result.data.data.PageData)
          await state.commit('tableData', result.data.data.ListData)
          await state.commit('tableLabel', state.getters.hblSingleTableLabel)
          // await state.commit('orderDataInfo', orderDataInputList)
          break
        default:
          await state.commit('pageData', [])
          await state.commit('tableData', [])
          await state.commit('tableLabel', [])
      }
      // console.log('tableData', state.getters.tableData)
      // console.log('tableLabel', state.getters.tableLabel)
    },
    billSingleSearch: async (state, payload) => {
      await state.commit('tableLabel', [])
      await state.commit('pdfEmailList', [])
      console.log('billSingleSearch payload', payload)
      const body = { offset: 0, limit: 10 }
      if (payload !== null) {
        body.pre_book_no = payload.orderId
        if (payload.version !== undefined) {
          body.version = payload.version
        }
      }
      // 測試demo用
      // body.pre_book_no = 'E21063333'
      console.log('billSingleSearch body', body)
      var result = await billSingle(body)
      state.dispatch('singleFileInfoData', { rows: result.data, pre_book_no: '', pdf_serial: 0, multiVersio: true })
      console.log('billSingleSearch result', result)
      const orderDataInputList = []
      Object.values(state.getters.containerloadTableLabel).forEach(async (item, idx) => {
        if (idx > 1) {
          orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
        }
      })
      switch (result.status) {
        case 200:
          // var rsp = await hblList({ pre_book_no: body.pre_book_no })
          // var maxVersion = rsp.data.data.ListData[0].version // Math.max(...result.data.data.ListData.map(item => item.version))
          // console.log('maxVersion', maxVersion)
          await Object.values(result.data.data.ListData).forEach(item => {
            // if (Number(item.version) === maxVersion) {
            //   item.max = true
            // } else {
            //   item.max = false
            // }
            item.DebitList.forEach(debitItem => {
              debitItem.Edit = faEdit
              debitItem.View = faEye
              debitItem.messageid = state.getters.orderId
              debitItem.button = true
            })
          })
          // await state.commit('pageData', result.data.data.PageData)
          await state.commit('pageData', [])
          await state.commit('tableData', result.data.data.ListData)
          await state.commit('tableLabel', state.getters.billSingleTableLabel)
          // await state.commit('orderDataInfo', orderDataInputList)
          break
        default:
          await state.commit('pageData', [])
          await state.commit('tableData', [])
          await state.commit('tableLabel', [])
      }
      // console.log('tableData', state.getters.tableData)
      // console.log('tableLabel', state.getters.tableLabel)
    },
    hblChecklistSingleCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      switch (Number(state.getters.soTab)) {
        case 0:
          body.pre_book_no = state.getters.orderId
          result = await billSingle(body)
          await Object.values(result.data.data.ListData).forEach(item => {
            item.Edit = faEdit
            item.View = faEye
            item.pre_book_no = state.getters.orderId
          })
          await state.commit('tableLabel', state.getters.billSingleTableLabel)
          break
        case 2:
          body.messageid = state.getters.orderId
          result = await hblSingle(body)
          var rsp = await hblList({ messageid: body.messageid })
          var maxVersion = rsp.data.data.ListData[0].version // Math.max(...result.data.data.ListData.map(item => item.version))
          console.log('maxVersion', maxVersion)
          await Object.values(result.data.data.ListData).forEach(item => {
            if (Number(item.version) === maxVersion) {
              item.max = true
            } else {
              item.max = false
            }
            item.Edit = faEdit
            item.View = faEye
            item.messageid = state.getters.orderId
            item.button = true
          })
          await state.commit('tableLabel', state.getters.hblSingleTableLabel)
          break
      }
      // console.log('hblsoSingleAndInvoicePackinglistCurrentChange body', body)
      // console.log('hblsoSingleAndInvoicePackinglistCurrentChange result', result)
      // console.log('hblsoSingleAndInvoicePackinglistCurrentChange PageData', state.getters.pageData)
      result.data.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.data.PageData.currentPage = currentPage
      // console.log('hblsoSingleAndInvoicePackinglistCurrentChange PageData', state.getters.pageData)
      await state.commit('pageData', result.data.data.PageData)
      await state.commit('tableData', result.data.data.ListData)
    }
  }
}

export default documentReleased
