<template>
  <popup class="userpopup" :class="$style.outside" @close="$emit('close')">
    <template #title> {{ titleBtnText }}使用者 </template>
    <template #content>
      <div :class="$style.out">
        <inputStyle
          v-for="(item, i) in userInputlist"
          :key="i"
          :fixWidth="120"
          :class="$style.heightfix"
        >
          <template #title>
            <span class="notRequired" :class="{ requiredField: item.required }">*</span>  {{ item.label }}
          </template>
          <template #data>
            <el-select multiple collapse-tags v-if="item.type === 'select' && item.key ==='companyid'" @change="chooseCompany()" v-model="item.input" filterable placeholder="關鍵字搜尋" :class="$style.select_basic_code">
              <el-option
                v-for="(option, key) in basicCodeOptions"
                :key="key"
                :label="option.label"
                :value="option.value">
                <span>{{ option.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ option.basicCode }}</span>
              </el-option>
            </el-select>
            <el-select v-else-if="item.type === 'select' && item.key === 'nameId'" @change="chooseUsername()" v-model="item.input" filterable placeholder="關鍵字搜尋" :disabled="item.hidden" :class="$style.select_basic_code">
              <el-option
                v-for="(option, key) in usernameOptions"
                :key="key"
                :label="option.label"
                :value="option.value">
              </el-option>
            </el-select>
            <el-select v-else-if="item.type === 'select' && item.key === 'departmentId'" @change="chooseDepartment()" filterable placeholder="關鍵字搜尋" :disabled="item.hidden" v-model="item.input" :class="$style.select_basic_code">
              <el-option
                v-for="(option, key) in departmentOptions"
                :key="key"
                :label="option.label"
                :value="option.value">
              </el-option>
            </el-select>
            <input
              class="userInput"
              :class="{allowInput: item.required }"
              v-else-if="item.type === 'input'"
              v-model="item.input"
              :readonly="item.hidden || item.key === 'userrole' "
              :placeholder="item.placehodler"
            />
            <div :class="$style.radio" v-else-if="item.type === 'radio' && item.key==='isAdmin'" >
              <el-radio-group v-model="item.input" >
                <el-radio label="true">是</el-radio>
                <el-radio label="false">否</el-radio>
              </el-radio-group>
            </div>
            <div :class="$style.radio" v-else-if="item.type === 'radio' && item.key==='status'">
              <el-radio-group v-model="item.input" >
                <el-radio label="true">是</el-radio>
                <el-radio label="false">否</el-radio>
              </el-radio-group>
            </div>
          </template>
          <template #msg>
            {{ item.msg }}
          </template>
        </inputStyle>
      </div>
      <el-button type="primary" :class="$style.btn" @click="saveUser">
        {{ titleBtnText }}
      </el-button>
    </template>
  </popup>
</template>

<script>
import popup from '@/components/card/popup.vue'
import inputStyle from '@/components/card/datainput.vue'
import { mapGetters } from 'vuex'
import { userAction } from '@/api/user'
export default {
  computed: {
    ...mapGetters(['addInputlist', 'userManagementCompanyList'])
  },
  props: {
    titleBtnText: String,
    rowData: Object,
    action: String
  },
  components: {
    popup,
    inputStyle
  },
  data () {
    return {
      errors: [],
      basicCodeOptions: [],
      departmentOptions: [],
      usernameOptions: [],
      currentBasicCode: 'Option1',
      companyId: 0,
      departmentId: 0,
      userInputlist: [],
      roleName: {},
      depId: {},
      depIndex: 0,
      depName: {},
      userIndex: 0,
      userId: {},
      userName: {}
    }
  },
  methods: {
    saveValidation () {
      var self = this
      // 部門名稱，電話，傳真，公司名稱，角色，使用者名稱
      Object.values(this.addInputlist).forEach(item => {
        switch (item.key) {
          case ('basic_code'):
            if (item.input === '') {
              self.errors.push('請輸入角色')
            }
            break
          case ('departmentname'):
            if (item.input === '') {
              self.errors.push('請輸入部門名稱')
            }
            break
          case ('phone'):
            if (item.input === '') {
              self.errors.push('請輸入電話')
            }
            break
          case ('fax'):
            if (item.input === '') {
              self.errors.push('請輸入傳真')
            }
            break
          case ('companyid'):
            if (item.input.length < 1) {
              self.errors.push('請輸入公司名稱')
            }
            break
          case ('name'):
            if (item.input === '') {
              self.errors.push('請輸入使用者名稱')
            }
            break
          case ('email'):
            if (!this.validateEmail(item.input)) {
              self.errors.push('請輸入正確的Email')
            }
            break
          case ('passwd'):
            if (!(item.input.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/))) {
              self.errors.push('密碼須符合 1.八位數 2.大小寫字母、數字組成')
            }
            break
        }
        this.rowData[item.key] = item.input
      })
      if (self.errors.length > 0) {
        this.$message({
          type: 'error',
          message: self.errors[0]
        })
        self.errors = []
        return false
      }
      return true
    },
    async saveUser () {
      if (!this.saveValidation()) {
        return false
      }
      var action = ''
      if (this.action === 'add') {
        action = 'A'
      } else {
        action = 'U'
      }
      const body = {
        id: this.rowData.id,
        action: action
      }
      Object.values(this.addInputlist).forEach(item => {
        if (typeof item.input === 'string') {
          item.input = item.input.trim()
        }
        switch (item.key) {
          case ('companyid'):
            body[item.key] = item.input.join(',')
            break
          case ('name'):
            body[item.key] = item.input.split('(')[0]
            break
          default:
            body[item.key] = item.input === undefined ? '' : item.input
            break
        }
      })
      console.log(body)
      const r = await userAction(body)
      console.log('r', r)
      switch (r.code) {
        case '200':
          this.$message({
            type: 'success',
            message: '存檔成功'
          })
          if (action === 'A') {
            await this.$store.dispatch('userManagementInputList')
            await this.$store.dispatch('userManagementSearch')
          } else {
            this.$store.commit('addInputlist', this.addInputlist)
          }
          this.$emit('close')
          break
        case '400':
          Object.keys(r.data).forEach(key => {
            Object.values(this.addInputlist).forEach(item => {
              if (item.key === key) {
                item.msg = r.data[key][0]
              }
            })
          })
          this.$message({
            type: 'error',
            message: '請檢查欄位'
          })
          break
        default:
          this.$message({
            type: 'info',
            message: '儲存失敗(' + r.detail + ')'
          })
      }
    },
    setDepartmentOptions (editFlag = false) {
      if (this.companyId.length < 1) {
        this.departmentOptions = []
        return
      }
      var lastCompanyId = this.companyId[this.companyId.length - 1]
      // console.log('lastCompanyId', lastCompanyId)
      // console.log('userManagementCompanyList', this.userManagementCompanyList)
      var departmentList = this.userManagementCompanyList[lastCompanyId].department_list
      if (departmentList !== null && departmentList.length > 0) {
        // console.log('departmentList.length', departmentList.length)
        // console.log('departmentList', departmentList)
        var usernameList = departmentList[0].user_list
        departmentList.forEach(item => {
          this.departmentOptions.push({
            value: item.id,
            label: item.department_name
          })
        })
        if (!editFlag) {
          this.depId.input = departmentList[0].id
        }
        usernameList.forEach(item => {
          // console.log('username options', item)
          this.usernameOptions.push({
            value: item.id,
            label: item.user_name + '(' + item.basic_code + ')',
            rolename: item.role_name
          })
        })
        this.depId.hidden = false
        this.userId.hidden = false
        this.depName.hidden = true
        this.userName.hidden = true
        this.depName.required = false
        this.userName.required = false
        this.chooseDepartment(editFlag)
      } else {
        this.departmentOptions = []
        this.usernameOptions = []
        // this.depId.input = ''
        // this.userId.input = ''
        // if (!editFlag) {
        //   this.depName.input = ''
        //   this.userName.input = ''
        // }
        this.depId.hidden = true
        this.depName.hidden = false
        this.depName.required = true
        this.userId.hidden = true
        this.userName.hidden = false
        this.userName.required = true
        this.roleName.input = this.userManagementCompanyList[lastCompanyId].role_name
        // console.log(this.userInputlist)
      }
    },
    validateEmail (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    chooseCompany () {
      console.log('chooseCompany')
      // console.log(this.userInputlist)
      this.companyId = this.userInputlist[0].input
      // console.log(this.companyId)
      this.setDepartmentOptions()
    },
    chooseDepartment (editFlag = false) {
      this.departmentOptions.forEach(item => {
        if (item.value === this.depId.input) {
          this.depName.input = item.label
        }
      })
      if (!this.userManagementCompanyList[this.companyId]) {
        return
      }
      this.userManagementCompanyList[this.companyId].department_list.forEach(item1 => {
        if (this.depId.input === item1.id) {
          var usernameList = item1.user_list
          // console.log('usernameList', usernameList)
          this.usernameOptions = []
          usernameList.forEach(item => {
            this.usernameOptions.push({
              value: item.id,
              label: item.user_name + '(' + item.basic_code + ')',
              rolename: item.role_name
            })
          })
        }
      })
      // console.log('this.usernameOptions', this.usernameOptions)
      if (!editFlag) {
        this.userId.input = this.usernameOptions[0].value
        this.userName.input = this.usernameOptions[0].label
        this.roleName.input = this.usernameOptions[0].rolename
      }
      // console.log('this.userId', this.userId)
    },
    chooseUsername () {
      this.usernameOptions.forEach(item => {
        if (item.value === this.userId.input) {
          this.userName.input = item.label
          this.roleName.input = item.rolename
          // console.log(item)
        }
      })
    }
  },
  mounted: async function () {
    var self = this
    // console.log(this.addInputlist)
    this.userInputlist = this.addInputlist
    Object.values(this.userManagementCompanyList).forEach((item, id) => {
      // console.log('userManagementSearch item', item.id, 'name', item.company_name)
      self.basicCodeOptions.push({
        value: item.id,
        label: item.company_name,
        basicCode: item.basic_code
      })
    })
    this.roleName = this.userInputlist[1]
    this.depId = this.userInputlist[2]
    this.depName = this.userInputlist[3]
    this.userId = this.userInputlist[4]
    this.userName = this.userInputlist[5]
    if (this.action === 'edit') {
      Object.values(this.userInputlist).forEach(item => {
        // console.log(item.key)
        switch (item.key) {
          case ('status'):
            item.input = this.rowData[item.key] ? 'true' : 'false'
            break
          case ('companyid'):
            this.companyId = this.rowData[item.key]
            item.input = this.rowData[item.key]
            break
          case ('departmentId'):
            this.depId.input = this.rowData.departmentid
            break
          case ('account'):
            item.hidden = true
            item.required = false
            item.input = this.rowData[item.key]
            break
          default:
            if (typeof this.rowData[item.key] === 'string') {
              item.input = this.rowData[item.key].replace(/(:.[\b])|[\u0000-\u001f]/g, '').trim()
            } else {
              item.input = this.rowData[item.key]
            }
            break
        }
      })
      if (this.companyId !== null) {
        this.setDepartmentOptions(true)
      }
      if (this.departmentOptions.length > 0) {
        this.usernameOptions.forEach(row => {
          if (row.label.indexOf(this.userName.input) === 0) {
            this.userId.input = row.value
          }
        })
      }
    } else {
      Object.values(this.userInputlist).forEach(item => {
        switch (item.key) {
          case ('account'):
            item.hidden = false
            item.required = true
            item.input = this.rowData[item.key]
            break
          case ('status'):
            item.input = 'true'
            break
          case ('isAdmin'):
            item.input = 'false'
            break
          default:
            item.input = ''
            break
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.input {
  border: none;
  width: 100%;
  background: none;
  color: #7e7e7e;
}

.out {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 16px;
  margin-top: 30px;
  padding: 0 20px;
  box-sizing: border-box;
}

.btn {
  align-self: center;

  &:global(.el-button) {
    margin: 25px 0px;
  }
}

.outside {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select {
  :global(.el-select) {
    width: 100%;
  }

  :global(.el-input__inner) {
    height: 30px;
  }
  :global(.el-input__icon) {
    line-height: 30px;
  }
}

.radio {
  width: 100%;
}
</style>
<style>
.userpopup .el-input__inner{
  height: 30px !important;
}
</style>
<style scoped>

.userInput{
  border: none;
  width: 100%;
  background: none;
  color: #7e7e7e;
}
.allowInput{
  background-color: #ffffff;
}
.notRequired{
  font-size:0;
}
.requiredField{
  color: #ff0000;
  font-weight: bold;
  font-size: 12px;
}
</style>
<style scoped>
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 0.6; /* Firefox */
}
</style>
<style>
.el-tag--info{
  max-width: 120px;
  overflow: hidden;
}
</style>
