<template>
  <popup :class="$style.outside" @close="$emit('close')">
    <template #title>
      {{ titleBtnText }}{{ $route.name === 'userauthority' ? '角色' : '群組'  }}
    </template>
    <template #content>
      <el-table
        v-if="$route.name !== 'userauthority'"
        :data="tableData"
        tooltip-effect="dark"
        :class="$style.table"
        empty-text="無數據資料"
        :border="true"
      >
        <el-table-column
          type="selection"
          width="50"
          align="center"
          fixed="left"
        />
        <el-table-column
          label="項目"
          align="center"
          prop="vesselName"
          min-width="200"
        />
      </el-table>
      <div
        :class="$style.checkboxout"
        v-for="(item, i) in editRowData.webPage"
        :key="i"
        v-else
      >
        <p :class="$style.title">{{ item.name }}</p>
          <el-checkbox label="Create" v-model="item.permission[0].created" :checked="item.permission[0].created===1"></el-checkbox>
          <el-checkbox label="Read" v-model="item.permission[0].read" :checked="item.permission[0].read===1"></el-checkbox>
          <el-checkbox label="Update" v-model="item.permission[0].updated" :checked="item.permission[0].updated===1"></el-checkbox>
          <el-checkbox label="Delete" v-model="item.permission[0].deleted" :checked="item.permission[0].deleted===1"></el-checkbox>
          <el-checkbox label="審核" v-model="item.permission[0].approved" :checked="item.permission[0].approved===1"></el-checkbox>
          <el-checkbox label="上鏈" v-model="item.permission[0].onchain" :checked="item.permission[0].onchain===1"></el-checkbox>
      </div>
      <el-button
        type="primary"
        :class="$style.btn"
        @click="updatePermission"
      >
        {{ titleBtnText }}
      </el-button>
    </template>
  </popup>
</template>

<script>
import popup from '@/components/card/popup.vue'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['editRowData'])
  },
  props: {
    titleBtnText: String
  },
  components: {
    popup
  },
  data () {
    return {
      tableData: [{
        vesselName: '裝船通知書'
      }, {
        vesselName: '公證行丈量單'
      }, {
        vesselName: '丈量證明文件'
      }, {
        vesselName: '報關文件'
      }, {
        vesselName: 'HB/L提單作法'
      }, {
        vesselName: '裝櫃清單'
      }, {
        vesselName: 'HB/L核對單'
      }, {
        vesselName: '出貨報價'
      }, {
        vesselName: '發單文件(HB/L)'
      }, {
        vesselName: '發單文件(客戶帳單)'
      }, {
        vesselName: 'POUCH文件'
      }, {
        vesselName: '客戶管理'
      }, {
        vesselName: '商業夥伴管理'
      }, {
        vesselName: '權限管理'
      }, {
        vesselName: '使用者管理'
      }],
      list: [{
        label: '海運承攬管理-訂單',
        more: false,
        checkList: []
      }, {
        label: '主檔管理-客戶管理',
        more: false,
        checkList: []
      }, {
        label: '主檔管理-商業夥伴管理',
        more: false,
        checkList: []
      }, {
        label: '系統管理-使用者管理',
        more: false,
        checkList: []
      }, {
        label: '系統管理-權限管理',
        more: false,
        checkList: []
      }, {
        label: '表單管理-裝船通知書',
        more: true,
        checkList: []
      }, {
        label: '表單管理-公證行丈量單',
        more: true,
        checkList: []
      }, {
        label: '表單管理-丈量證明文件',
        more: true,
        checkList: []
      }, {
        label: '表單管理-報關文件',
        more: true,
        checkList: []
      }, {
        label: '表單管理-HB/L 提單作法',
        more: true,
        checkList: []
      }, {
        label: '表單管理-裝櫃清單',
        more: true,
        checkList: []
      }, {
        label: '表單管理-HB/L 核對單',
        more: true,
        checkList: []
      }, {
        label: '表單管理-出貨報價',
        more: true,
        checkList: []
      }, {
        label: '表單管理-發單文件(HB/L)',
        more: true,
        checkList: []
      }, {
        label: '表單管理-發單文件(客戶帳單)',
        more: true,
        checkList: []
      }, {
        label: '表單管理-POUCH文件',
        more: true,
        checkList: []
      }]
    }
  },
  methods: {
    updatePermission () {
      this.$emit('updatePermission', this.editRowData)
    }
  },
  mounted () {
    console.log('this.editRowData', this.editRowData)
  }
}
</script>

<style lang="scss" module>
.btn {
  align-self: center;

  &:global(.el-button) {
    margin: 25px 0px;
  }
}

.outside {
  margin: 0 auto;
}

.table {
  margin-top: 34px;
}

.checkboxout {
  display: flex;
  grid-template-columns: 200px 1fr;
  align-items: center;
  margin-left: 20px;
  margin-top: 20px;
}

.title {
  font-size: 13px;
  font-weight: 500;
  color: #7e7e7e;
  margin-right: 20px;
  min-width: 150px
}
</style>
