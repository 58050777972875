<template>
  <div>
    <card>
      <template #title>
        {{ $route.meta.subtitle }}
      </template>
      <template #datainput>
        目前狀況：
        <span :class="$style.status" v-if="status">已處理</span>
        <span :class="$style.status" v-else>未處理</span>
       </template>
      <template #data>
        時間：<date-picker v-model="time1" :disabled="status" valueType="format" format="YYYY/MM/DD" :class="$style.datepicker"></date-picker>
        <div>
          <el-button size="mini" v-if="!status" type="info" icon="el-icon-finished" @click="saveDate">確認</el-button>
        </div>
      </template>
    </card>
  </div>
</template>

<script>
import card from '@/components/card/card.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['deliveryDetailData'])
  },
  components: {
    card,
    DatePicker
  },
  data () {
    return {
      dateCol: '',
      statusSource: '',
      date: '',
      statusList: [
        '未處裡',
        '已處理'
      ],
      status: false,
      time1: null
    }
  },
  methods: {
    currentStatus: function () {
      const dealDate = this.deliveryDetailData.purchaseSingle[this.statusSource]
      if (dealDate === 0) {
        this.time1 = null
        this.status = false
      } else if (typeof dealDate !== 'undefined') {
        this.time1 = this.deliveryDetailData.purchaseSingle[this.dateCol]
        this.status = true
      }
      console.log('currentStatus:', this.status)
    },
    saveDate: async function () {
      if (!this.time1.match(/^[0-9]{4}\/[0-9]{2}\/[0-9]{2}$/)) {
        alert('請輸入時間')
        return
      }
      var body = {}
      body.id = this.deliveryDetailData.purchaseSingle.id
      body[this.statusSource] = this.time1
      body.key = this.statusSource
      this.$store.dispatch('updateDealDate', body)
      // await purchaseSingle(body)
      this.status = true
    },
    checkSource: function () {
      console.log(this.deliveryDetailData)
      switch (this.$route.name) {
        case ('delivery'):
          this.statusSource = 'container_inyard_point'
          break
        case ('customsReleased'):
          this.statusSource = 'export_released_point'
          break
        case ('departure'):
          this.statusSource = 'peparture_point'
          break
        case ('pouchReceived'):
          this.statusSource = 'pouch_point'
          break
        case ('arrival'):
          this.statusSource = 'arrival_point'
          break
        case ('doRelease'):
          this.statusSource = 'release_point'
          break
      }
      this.dateCol = this.statusSource + '_createon'
      console.log('statusSource', this.statusSource)
    }
  },
  mounted: function () {
    console.log('mounted')
    this.checkSource()
    this.currentStatus()
  },
  watch: {
    deliveryDetailData () {
      console.log('雌watch deliveryDetailData')
      this.time1 = null
      this.checkSource()
      this.currentStatus()
    },
    $route () {
      this.time1 = null
      console.log('route watch')
      this.checkSource()
      this.currentStatus()
    }
  }
}
</script>

<style lang="scss" module>
.title {
  font-size: 16px;
  font-weight: 500;
  color: #68686a;
}

.noline {
  border: none;
}
.statusDropdown{
  display: grid;
  width: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.datepicker{
  margin-bottom: 15px;
}
.status{
  margin-top: 10px;
  margin-bottom: 10px;
  color: #409EFF;
}
</style>
