import order from '@/router/list/module/order.js'
import form from '@/router/list/module/form.js'
import masterfile from '@/router/list/module/masterfile.js'
import systemfile from '@/router/list/module/systemfile.js'

export default [
  ...order,
  ...form,
  ...masterfile,
  ...systemfile
]
