import MainComponent from '@/views/mainfile/index.vue'
import Customermanagement from '@/views/mainfile/customermanagement.vue'

export default [{
  path: 'customermanagement',
  name: 'customermanagement',
  component: Customermanagement,
  meta: { title: '主檔管理-客戶管理', class: 'customermanagement' }
}, {
  path: 'suppliermanagement',
  name: 'suppliermanagement',
  component: Customermanagement,
  meta: { title: '主檔管理-商業夥伴管理', class: 'suppliermanagement' }
}, {
  path: 'routecode',
  name: 'routecode',
  component: MainComponent,
  meta: { title: '主檔管理-航線代碼管理', class: 'routecode' }
}, {
  path: 'countrycode',
  name: 'countrycode',
  component: MainComponent,
  meta: { title: '主檔管理-國家代碼管理', class: 'countrycode' }
}, {
  path: 'portcode',
  name: 'portcode',
  component: MainComponent,
  meta: { title: '主檔管理-港口代碼管理', class: 'portcode' }
}, {
  path: 'businesscode',
  name: 'businesscode',
  component: MainComponent,
  meta: { title: '主檔管理-業務人員代碼管理', class: 'businesscode' }
}, {
  path: 'transportation',
  name: 'transportation',
  component: MainComponent,
  meta: { title: '主檔管理-運務人員代碼管理', class: 'transportation' }
}]
