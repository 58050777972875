import List from '@/views/list/list/list.vue'
import ListorderidNew from '@/views/list/list/listorderidNew.vue'
import OrdercomponentNew from '@/views/list/list/router/ordercomponentNew.vue'
import Quotation from '@/views/list/list/router/quotation.vue'

export default [{
  path: 'scargo',
  name: 'scargo',
  component: List,
  meta: { title: '表單管理-裝船通知書', subtitle: '裝船通知書', class: 'scargo', inputType: 'common' }
}, {
  path: 'smeasure',
  name: 'smeasure',
  component: List,
  meta: { title: '表單管理-貨物丈量單', subtitle: '貨物丈量單', class: 'smeasure', inputType: 'common' }
}, {
  path: 'smcertificate',
  name: 'smcertificate',
  component: List,
  meta: { title: '表單管理-丈量證明文件', subtitle: '丈量證明文件', class: 'smcertificate', inputType: 'common' }
}, {
  path: 'sclearanceIV',
  name: 'sclearanceIV',
  component: List,
  meta: { title: '表單管理-Invoice', subtitle: 'Invoice', class: 'sclearanceIV', inputType: 'common' }
}, {
  path: 'sclearancePL',
  name: 'sclearancePL',
  component: List,
  meta: { title: '表單管理-Packing List', subtitle: 'Packing List', class: 'sclearancePL', inputType: 'common' }
}, {
  path: 'shblso',
  name: 'shblso',
  component: List,
  meta: { title: '表單管理-HB/L提單作法(S/O)', subtitle: 'HB/L 提單作法', class: 'shblso', inputType: 'common' }
}, {
  path: 'spackinglist',
  name: 'spackinglist',
  component: List,
  meta: { title: '表單管理-裝櫃清單', subtitle: '裝櫃清單', class: 'spackinglist', inputType: 'common' }
}, {
  path: 'shblcheck',
  name: 'shblcheck',
  component: List,
  meta: { title: '表單管理-HB/L核對單', subtitle: 'HB/L 核對單', class: 'shblcheck', inputType: 'common' }
}, {
  path: 'smblso',
  name: 'smblso',
  component: List,
  meta: { title: '表單管理-MB/L 提單做法(SI)', subtitle: 'MB/L 提單做法', class: 'smblso', inputType: 'common' }
}, {
  path: 'smblcheck',
  name: 'smblcheck',
  component: List,
  meta: { title: '表單管理-MB/L 核對單', subtitle: 'MB/L 核對單', class: 'smblcheck', inputType: 'common' }
}, {
  path: 'sshippingprice',
  name: 'sshippingprice',
  component: List,
  meta: { title: '表單管理-出貨通知單', subtitle: '出貨通知單', class: 'sshippingprice', inputType: 'common' }
}, {
  path: 'sdochbl',
  name: 'sdochbl',
  component: List,
  meta: { title: '表單管理-HB/L 提單', subtitle: 'HB/L 提單', class: 'sdochbl', inputType: 'common' }
}, {
  path: 'sdocbill',
  name: 'sdocbill',
  component: List,
  meta: { title: '表單管理-客戶帳單', subtitle: '客戶帳單', class: 'sdocbill', inputType: 'common' }
}, {
  path: 'spouchdc',
  name: 'spouchdc',
  component: List,
  meta: { title: '表單管理-POUCH文件', subtitle: 'POUCH-代理帳單', class: 'spouchdc', inputType: 'common' }
}, {
  path: 'spouchmb',
  name: 'spouchmb',
  component: List,
  meta: { title: '表單管理-POUCH-MBL(主提單)', subtitle: 'POUCH-MBL(主提單)', class: 'spouchmb', inputType: 'common' }
}, {
  path: 'spouchma',
  name: 'spouchma',
  component: List,
  meta: { title: '表單管理-POUCH-Manifest(艙單)', subtitle: 'POUCH-Manifest(艙單)', class: 'spouchma', inputType: 'common' }
}, {
  path: 'spouchhb',
  name: 'spouchhb',
  component: List,
  meta: { title: '表單管理-POUCH-分提單HB/L', subtitle: 'POUCH-分提單HB/L', class: 'spouchhb', inputType: 'common' }
}, {
  path: 'spouchiv',
  name: 'spouchiv',
  component: List,
  meta: { title: '表單管理-POUCH-Invoice', subtitle: 'POUCH-Invoice', class: 'spouchiv', inputType: 'common' }
}, {
  path: 'spouchpl',
  name: 'spouchpl',
  component: List,
  meta: { title: '表單管理-Packing List', subtitle: 'POUCH-Packing List', class: 'spouchpl', inputType: 'common' }
}, {
  path: 'quotationConfirmation',
  name: 'quotationConfirmation',
  component: List,
  meta: { title: '表單管理-報價確認單', subtitle: '報價確認單', class: 'quotationConfirmation', inputType: 'common' }
}, {
  path: 'OrderId',
  component: ListorderidNew,
  redirect: { name: 'measure' },
  children: [{
    path: 'measure/:ship_num',
    name: 'measure',
    component: OrdercomponentNew,
    meta: { title: '表單管理-貨物丈量單', subtitle: '貨物丈量單', class: 'measure', inputType: 'common' }
  }, {
    path: 'containerload/:vsl_name/:voyage/:cfs_delivery_place/:port_discharge/:ship_num',
    // path: 'containerload/:ship_num',
    name: 'containerload',
    component: OrdercomponentNew,
    meta: { title: '表單管理-裝櫃清單', subtitle: '裝櫃清單', class: 'containerload', inputType: 'common' }
  }, {
    path: 'mblsi/:vsl_name/:voyage/:carrier_agent/:so_no',
    // path: 'mblsi/:ship_num',
    name: 'mblsi',
    component: OrdercomponentNew,
    meta: { title: '表單管理-MB/L 提單做法(SI)', subtitle: 'MB/L 提單做法', class: 'mblsi', inputType: 'common' }
  }, {
    path: 'mbl/:ship_num',
    name: 'mbl',
    component: OrdercomponentNew,
    meta: { title: '表單管理-MB/L 核對單', subtitle: 'MB/L 核對單', class: 'mbl', inputType: 'common' }
  }]
}, {
  path: 'edit/measureEdit/:fileType/:operating',
  name: 'measureEdit',
  component: Quotation,
  meta: { title: '表單管理-貨物丈量單', subtitle: '貨物丈量單', class: 'list' }
}, {
  path: 'edit/containerloadView/:fileType/:operating',
  name: 'containerloadView',
  component: Quotation,
  meta: { title: '表單管理-裝櫃清單', subtitle: '裝櫃清單', class: 'list' }
}, {
  path: 'edit/mblsiView/:fileType/:operating',
  name: 'mblsiView',
  component: Quotation,
  meta: { title: '表單管理-MB/L 提單做法(SI)', subtitle: '表單管理-MB/L 提單做法(SI)', class: 'list' }
}, {
  path: 'edit/mblView/:fileType/:operating',
  name: 'mblView',
  component: Quotation,
  meta: { title: '表單管理-MB/L 核對單', subtitle: '表單管理-MB/L 核對單', class: 'list' }
}, {
  path: 'spouchdc2View',
  name: 'spouchdc2View',
  component: Quotation,
  meta: { title: '表單管理-POUCH-代理帳單', subtitle: 'POUCH-代理帳單', class: 'spouchdc2View', inputType: 'common' }
}, {
  path: 'spouchmb2View',
  name: 'spouchmb2View',
  component: Quotation,
  meta: { title: '表單管理-POUCH-MBL(主提單)', subtitle: 'POUCH-MBL(主提單)', class: 'spouchmb2View', inputType: 'common' }
}, {
  path: 'spouchma2View',
  name: 'spouchma2View',
  component: Quotation,
  meta: { title: '表單管理-POUCH-Manifest(艙單)', subtitle: 'POUCH-Manifest(艙單)', class: 'spouchma2View', inputType: 'common' }
}, {
  path: 'spouchhb2View',
  name: 'spouchhb2View',
  component: Quotation,
  meta: { title: '表單管理-POUCH-分提單HB/L', subtitle: 'POUCH-分提單HB/L', class: 'spouchhb2View', inputType: 'common' }
}, {
  path: 'spouchiv2View',
  name: 'spouchiv2View',
  component: Quotation,
  meta: { title: '表單管理-POUCH-Invoice', subtitle: 'POUCH-Invoice', class: 'spouchiv2View', inputType: 'common' }
}, {
  path: 'spouchpl2View',
  name: 'spouchpl2View',
  component: Quotation,
  meta: { title: '表單管理-Packing List', subtitle: 'Packing List', class: 'spouchpl2View', inputType: 'common' }
}]
