<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="$style.left">
        <div :class="$style.border">
          Job No
        </div>
        <div :class="$style.border">
          船名
        </div>
        <div :class="$style.border">
          航次
        </div>
        <div :class="$style.border">
          SO No
        </div>
        <div :class="$style.border">
          HB/L No
        </div>
        <div :class="$style.border">
          客戶代碼
        </div>
        <div :class="$style.border">
          結關日
        </div>
        <div :class="$style.border">
          裝船日
        </div>
        <div :class="$style.border">
          收貨港 PLR
        </div>
        <div :class="$style.border">
          卸貨港 POD
        </div>
        <div :class="$style.border">
          裝貨港 POL
        </div>
        <div :class="$style.border">
          目的地 PLD
        </div>
        <div :class="$style.border">
          船期編號
        </div>
        <div :class="$style.border">
          業務代碼
        </div>
        <div :class="$style.border">
          OP代碼
        </div>
      </div>
      <div :class="$style.right">
        <input :class="$style.input" v-model="editRowData.pre_book_no" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.vsl_name" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.voyage" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.so_no" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.o_bl_no" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.customer_code" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.closing_date" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.onboard_date" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.receipt_place" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.port_discharge" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.port_load" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.delivery_place" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.ship_num" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.sales_code" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.entry_user_code" :disabled="editRowData.status_code === 3"/>
      </div>
      <div :class="$style.left">
        <div :class="$style.border">
          CFS交貨處
        </div>
        <div :class="$style.border">
          報關行名稱
        </div>
        <div :class="$style.border">
          貨運行代碼
        </div>
        <div :class="$style.border">
          船公司代碼
        </div>
        <div :class="$style.border">
          國外代理商代碼
        </div>
        <div :class="$style.border">
          預計起航日
        </div>
        <div :class="$style.border">
          預計抵達日
        </div>
        <div :class="$style.border">
          運輸條款_1
        </div>
        <div :class="$style.border">
          運輸條款_2
        </div>
        <div :class="$style.border">
          CY貨量
        </div>
        <div :class="$style.border">
          CY領櫃處
        </div>
        <div :class="$style.border">
          CY領櫃代碼
        </div>
        <div :class="$style.border">
          CY交櫃處
        </div>
        <div :class="$style.border">
          CY交櫃號碼
        </div>
        <div :class="$style.border">
          CFS貨量
        </div>
      </div>
      <div :class="$style.right">
        <input :class="$style.input" v-model="editRowData.cfs_delivery_place" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.broker_code" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.truck_company" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.carrier" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.self_pod_agent" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.pre_depart_date" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.pre_arr_date" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.loading_term" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.discharge_term" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.cy_number_desc" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.cy_receipt_place" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.cy_receipt_code" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.cy_delivery_place" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.cy_delivery_code" :disabled="editRowData.status_code === 3"/>
        <input :class="$style.input" v-model="editRowData.cfs_number_desc" :disabled="editRowData.status_code === 3"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['shipmentMasterItem', 'editRowData'])
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" module>
.root {
  align-self: center;
  width: 95%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: #fff;
  margin: 20px 0;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.rootinner {
  width: 100%;
  display: grid;
  grid-template-columns: 150px 1fr 150px 1fr;
  grid-auto-rows: minmax(200px, auto);
}

.border {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding-left: 10px;
  font-size: 14px;
  color: #6d6b6b;
  box-sizing: border-box;

  &.borderconter {
    justify-content: center;
  }
}

.left {
  display: grid;
  grid-auto-rows: 35px;
  border-left: 1px solid #eaeaea;
  border-top: 1px solid #eaeaea;
}

.right {
  display: grid;
  border-left: 1px solid #eaeaea;
  grid-auto-rows: 35px;
  border-top: 1px solid #eaeaea;
}

.bottom {
  grid-area: bottom;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.input {
  background: #fff;
  border: none;
  border-bottom: 1px solid #eaeaea;
  box-shadow: none;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  color: #6d6b6b;

  &.inputborder {
    border-right: 1px solid #eaeaea;
  }
}

.title {
  display: grid;
  grid-template-columns: repeat(7, 1fr) 120px;
  height: 35px;
}

.btn {
  align-self: flex-end;
  margin: 20px 10px;
  font-size: 12px;
  color: #6d6b6b;
  cursor: pointer;
}

.bottomtitle {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 35px 35px;
  margin: 10px;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.text {
  width: 100px;
}

.textarea {
  border: 2px solid #eaeaea;
  width: 100%;
  height: 200px;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
  color: #6d6b6b;
}
</style>
