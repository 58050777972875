import Spouch2 from '@/views/list/list/router/spouchcomponent.vue'

export default [
  {
    path: 'spouch/DC/:vsl_name/:voyage/:carrier_agent/:dc_no/:mbl_no/:o_bl_no',
    name: 'spouchDetailDC',
    component: Spouch2,
    meta: { doc_type: 'DC', title: '表單管理-POUCH-文件管理', subtitle: 'POUCH-代理帳單', class: 'spouchdc2', inputType: 'common' }
  },
  {
    path: 'spouch/MB/:vsl_name/:voyage/:carrier_agent/:dc_no/:mbl_no/:o_bl_no',
    name: 'spouchDetailMB',
    component: Spouch2,
    meta: { doc_type: 'MB', title: '表單管理-POUCH-文件管理', subtitle: 'POUCH-代理帳單', class: 'spouchdc2', inputType: 'common' }
  },
  {
    path: 'spouch/MA/:vsl_name/:voyage/:carrier_agent/:dc_no/:mbl_no/:o_bl_no',
    name: 'spouchDetailMA',
    component: Spouch2,
    meta: { doc_type: 'MA', title: '表單管理-POUCH-文件管理', subtitle: 'POUCH-代理帳單', class: 'spouchdc2', inputType: 'common' }
  },
  {
    path: 'spouch/HB/:vsl_name/:voyage/:carrier_agent/:dc_no/:mbl_no/:o_bl_no',
    name: 'spouchDetailHB',
    component: Spouch2,
    meta: { doc_type: 'HB', title: '表單管理-POUCH-文件管理', subtitle: 'POUCH-代理帳單', class: 'spouchdc2', inputType: 'common' }
  },
  {
    path: 'spouch/IV/:vsl_name/:voyage/:carrier_agent/:dc_no/:mbl_no/:o_bl_no',
    name: 'spouchDetailIV',
    component: Spouch2,
    meta: { doc_type: 'IV', title: '表單管理-POUCH-文件管理', subtitle: 'POUCH-代理帳單', class: 'spouchdc2', inputType: 'common' }
  },
  {
    path: 'spouch/PL/:vsl_name/:voyage/:carrier_agent/:dc_no/:mbl_no/:o_bl_no',
    name: 'spouchDetailPL',
    component: Spouch2,
    meta: { doc_type: 'PL', title: '表單管理-POUCH-文件管理', subtitle: 'POUCH-代理帳單', class: 'spouchdc2', inputType: 'common' }
  }
]
