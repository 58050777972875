<template>
  <el-table
    :class="$style.tablecss"
    :data="tableData"
    :empty-text="$t('NoData')"
    tooltip-effect="dark"
    :border="true"
    @sort-change="sortChange"
  >
    <template v-for="(item, i) in tableLabel">
      <el-table-column
        v-if="item.type"
        :key="i"
        :label="$t(item.label)"
        :sortable="item.sortable"
        :min-width="item.minWidth"
        :type="item.type"
        :prop="item.prop"
        align="center"
      />
      <el-table-column
        v-else
        :key="i"
        :prop="item.prop"
        :label="$t(item.label)"
        :sortable="item.sortable"
        :min-width="item.minWidth"
        :type="item.type"
        align="center"
      >
        <template slot-scope="scope">
          <div class="editArea" v-if="item.prop === 'reference_no'">
            <el-input v-model="scope.row.reference_no"></el-input>
            <el-button type="primary" @click="editReferenceNo(scope.row)">儲存</el-button>
          </div>
          <p v-else-if="!item.icon && !item.link && !item.btn">
            {{ scope.row[item.prop] }}
          </p>
          <el-popover trigger="hover" placement="top" v-else-if="item.link && item.prop=='file_name'">
            <p>{{scope.row[item.prop]}}</p>
            <div slot="reference" class="name-wrapper">
              <el-link
                type="info"
                @click="openFunction(scope.row,item)"
              >
                  {{ scope.row[item.prop] }}
              </el-link>
            </div>
          </el-popover>
          <el-link
            type="info"
            @click="openFunction(scope.row,item)"
            v-else-if="item.link"
          >
              {{ scope.row[item.prop] }}
          </el-link>
          <iconcomponent
            v-else-if="scope.row['status_code'] === undefined ? item.icon && item.prop==='Edit' : $route.name !== 'quotationConfirmed' ? item.icon && item.prop==='Edit' && (scope.row['status_code'] === 0 || scope.row['status_code'] === 3) : item.icon && item.prop==='Edit'"
            :icon="scope.row[item.prop]"
            :class="'iconstyle'"
            @click="openFunction(scope.row,item)"
          />
          <iconcomponent
            v-else-if="scope.row['status_code'] === undefined ? item.icon && item.prop==='View' : $route.name !== 'quotationConfirmed' ? item.icon && item.prop==='View' &&  (scope.row['status_code'] === 2 || scope.row['status_code'] === 3) : item.icon && item.prop==='View'"
            :icon="scope.row[item.prop]"
            :class="'iconstyle'"
            @click="openFunction(scope.row, item)"
          />
          <div  v-else-if="item.btn && scope.row[item.prop]">
          <label
            tag="div"
            :for="item.node ? item.node : 'updataSOA'"
            :class="[$style.btn, 'el-button', 'el-button--default']"
            v-if="!(scope.row['status'] === '未上傳'?  true : scope.row['max'] ? !scope.row['max'] : true )"
          >
            上傳
          </label>
          <input
            :ref="item.node ? item.node : 'updataSOA'"
            :id="item.node ? item.node : 'updataSOA'"
            type="file"
            multiple
            accept="application/pdf"
            @change="onChange(scope.row, item)"
            v-if="showUpload && !(scope.row['status'] === '未上傳'?  true : scope.row['max'] ? !scope.row['max'] : true )"
          />
        </div>
        </template>
      </el-table-column>
    </template>
  </el-table>
</template>

<script>
import iconcomponent from '@/components/plug/icon.vue'
// import updateComponent from '@/components/update.vue'
import { mapMutations, mapGetters } from 'vuex'
// element el-teble el-table-column 裡不能有任何的templete 故拆成兩個
export default {
  computed: {
    ...mapGetters(['showUpload'])
  },
  components: {
    iconcomponent
  },
  props: {
    tableHasSelect: Boolean,
    tableHeight: Number,
    tableData: Array,
    tableLabel: Array
  },
  methods: {
    ...mapMutations(['tableSelectData']),
    // selectChange (row) {
    //   this.$store.commit('tableSelectData', row)
    //   console.log('selectChange', this.$store.getters.tableSelectData)
    // },
    // selectGateAll (row) {
    //   this.$store.commit('tableSelectData', row)
    //   console.log('selectGateAll', row)
    // },
    async editReferenceNo (payload) {
      console.log('editReferenceNo', payload)
      var res = await this.$store.dispatch('saveRefrenceNo', { pre_book_no: payload.pre_book_no, reference_no: payload.reference_no })
      console.log(res)
      switch (res.code) {
        case '200':
          this.$message({
            type: 'success',
            message: '修改成功'
          })
          break
        default:
          this.$message({
            type: 'info',
            message: '修改失敗'
          })
          break
      }
    },
    openFunction (row, item) {
      // console.log('item.node', item.node, 'row[item.prop]', row[item.prop], 'row[item.key]', row[item.key], 'row', row)
      // this.$emit(item.node, row[item.prop], row[item.key])
      this.$emit(item.node, row, item)
    },
    onChange (row, item) {
      // console.log('@@@@@@@upload HBA@@@@@@@@', row)
      // const files = [...this.$refs.updata.files]
      // this.$store.commit('pafupdata', files[0])
      // this.$store.commit('pafupdataType', 'SOA')
      // this.$emit(item.node, row, item)
      // this.$refs.updata.files = {}
      // console.log('@@@@@@@@@@@@@@@', this.$refs)
      // console.log('@@@@@@@@@@@@@@@', this.$refs.updataSOA)
      // console.log('@@@@@@@@@@@@@@@', this.$refs.updataSOA[0].files)
      // console.log('@@@@@@@@@@@@@@@', this.$refs.updataSOA[1].files)
      const files = !item.node ? this.$refs.updataSOA[0].files.length > 0 ? [...this.$refs.updataSOA[0].files] : [...this.$refs.updataSOA[1].files] : this.$refs[item.node][0].files.length > 0 ? [...this.$refs[item.node][0].files] : [...this.$refs[item.node][1].files]
      // console.log('updataSOA files', files[0])
      this.$store.commit('pafupdata', files[0])
      switch (this.$route.name) {
        case 'soReleased':
          this.$store.commit('pafupdataType', 'SOA')
          break
        case 'checkReleased':
          this.$store.commit('pafupdataType', 'HCA')
          break
        case 'documentReleased':
          this.$store.commit('pafupdataType', 'HBA')
          break
        case 'mblsi':
          this.$store.commit('pafupdataType', 'SIA')
          break
        case 'mbl':
          console.log('onChange node', item.node)
          if (item.node === 'MCA') {
            this.$store.commit('pafupdataType', 'MCA')
          } else if (item.node === 'MCD') {
            this.$store.commit('pafupdataType', 'MCD')
          } else {
            this.$store.commit('pafupdataType', '')
          }
          break
      }
      this.$store.commit('showUpload', false)
      this.$emit('SOA', row, item)
      // this.$refs[row.version][0].files = ''
      // this.$refs[row.version][1].files = ''
      // this.$refs.updataSOA[0].values = ''
      // this.$refs.updataSOA[1].values = ''
    },
    sortChange (column) {
      var orderStr = ''
      if (column.order === 'descending') {
        orderStr = '-'
      }
      if (column.order !== null) {
        this.$emit('search', {
          order_by: orderStr + column.prop
        })
      } else {
        this.$emit('search')
      }
    }
  }
}
</script>
<style type="text/css">
.el-link {
  max-height: 11px;
  width: 100%;
  text-overflow: ellipsis;
  justify-content: left !important;
  vertical-align: baseline !important;
}
</style>
<style lang="scss" module>
.icon {
  font-size: 30px;
}

.tablecss {
  :global(.el-table__body-wrapper) {
    // min-height: 650px;
    min-height: 350px;
  }
}
input[type="file"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.root {
  display: flex;
  align-content: center;
}

.btn {
  &:global(.el-button) {
    padding: 8px 12px;
    font-size: 12px;
  }
}
</style>
<style >
.editArea{
  display:flex;
}
.editArea .el-input__inner{
  height: 24px !important;
  line-height: 24px !important;
  padding-left: 10px;
  padding-right: 10px;
}
.editArea .el-button{
  height: 24px !important;
  line-height: 24px !important;
  padding: 0 5px;
  font-size: 14px;
}
</style>
