<template>
  <div>
    <card>
      <template #title v-if="$route.params.titleB">
        {{ $route.params.titleB }}
      </template>
      <template #title v-else>
        {{ $route.meta.subtitle }}
      </template>
      <template #datainput>
        <el-button type="warning" @click="goback" :class="$style.back_button">
          返回
        </el-button>
        <pagerdata
        :class="$style.pagerdata"
        :orderDataInfo="orderDataInfo"/>
        <info/>
      </template>
      <template #manipulatlist>
        <manipulate :class="$style.manipulate">
          <template #button>
            <div>
              <el-form v-if="infoData.sender_status < 1">
                <el-form-item v-if="infoData.currentIdentity === 'sender'">
                  <el-button size="mini" type="success" icon="el-icon-share" @click="uploadChain()">確認上鏈</el-button>
                  <el-button size="mini" type="danger" icon="el-icon-delete" @click="markAvoid()">作廢</el-button>
                </el-form-item>
              </el-form>
              <el-form >
                <el-form-item v-if="infoData.currentIdentity === 'receiver_1' || infoData.currentIdentity === 'receiver_2' || infoData.currentIdentity === 'receiver_3' || infoData.currentIdentity === 'receiver_4'">
                  <el-button :disabled="infoData.sender_status !== 1 || parseInt(infoData[infoData.currentIdentity + '_status']) !== 0" size="mini" type="success" icon="el-icon-share" @click="uploadChain()">確認上鏈</el-button>
                  <el-button :disabled="infoData.sender_status !== 1 || parseInt(infoData[infoData.currentIdentity + '_status']) !== 0" size="mini" type="danger" icon="el-icon-circle-close" @click="rejectChain()">不同意</el-button>
                </el-form-item>
              </el-form>
            </div>
          </template>
        </manipulate>
      </template>
      <template #soShowBtn>
        <div v-if="isShowBtn">
              <!--<el-button size="mini" type="info" icon="el-icon-upload" @click="save">上傳</el-button>-->
              <el-button size="mini" type="info" icon="el-icon-finished" @click="clickShow('so')">{{btn1}}</el-button>
              <el-button size="mini" type="info" icon="el-icon-finished" @click="clickShow('soa')" v-show="isShowBtn">{{btn2}}</el-button>
              <el-button v-if="$route.name === 'mblView'" size="mini" type="info" icon="el-icon-finished" @click="clickShow('soa2')" v-show="isShowBtn">{{btn3}}</el-button>
              <!--<el-button size="mini" type="info" icon="el-icon-lock">上鏈</el-button>-->
            </div>
      </template>
      <template #data>
        <embed
          v-if="$route.params.fileType === 'pdf' && $route.params.operating === 'edit'"
          :src="replacePdfHost(pdfUrl)" width="100%" height="1000"
        />
        <embed
          v-else-if="$route.params.fileType === 'pdf' && $route.params.operating === 'view'"
          :src="showPdfUrl" width="100%" height="1000"
        />
        <quotationpage v-else-if="$route.params.fileType === 'data' && $route.name === 'quotation'"/>
        <cargopage v-else-if="$route.params.fileType === 'data' && $route.name === 'cargo'"/>
        <noticefile v-else-if="$route.params.fileType === 'data' && $route.name === 'notice'"/>
        <billfile v-else-if="$route.params.fileType === 'data' && $route.name === 'bill'"/>
        <ladingfile v-else-if="$route.params.fileType === 'data' && $route.name === 'lading'"/>
        <customerfile v-else-if="$route.params.fileType === 'data' && $route.name === 'customer'"/>
        <quotationConfirmed v-else-if="$route.params.fileType === 'data' && $route.name === 'confirmed'"/>
        <measure v-else-if="$route.params.fileType === 'data' && $route.name === 'measureEdit'"/>
        <div v-else>無資料</div>
      </template>
    </card>
    <popupPrivateKey
      :dialogFormVisible = "openPrivatePopup"
      @close = "openPrivatePopup=false"
    />
  </div>
</template>

<script>
import card from '@/components/card/card.vue'
import pagerdata from '@/components/pager/pagerdata.vue'
import manipulate from '@/components/card/manipulate.vue'
// import datainput from '@/components/card/datainput.vue'
import info from '@/views/list/list/components/info.vue'
import quotationpage from '@/views/list/list/components/quotationpage.vue'
import cargopage from '@/views/list/list/components/cargopage.vue'
import noticefile from '@/views/list/list/components/noticefile.vue'
import billfile from '@/views/list/list/components/billfile.vue'
import ladingfile from '@/views/list/list/components/ladingfile.vue'
import customerfile from '@/views/list/list/components/customerfile.vue'
import quotationConfirmed from '@/views/list/list/components/quotationConfirmed.vue'
import measure from '@/views/list/list/components/measure.vue'
import popupPrivateKey from '@/views/privateKey/popup.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    card,
    pagerdata,
    manipulate,
    popupPrivateKey,
    // datainput,
    info,
    quotationpage,
    cargopage,
    noticefile,
    billfile,
    ladingfile,
    customerfile,
    quotationConfirmed,
    measure
  },
  data () {
    return {
      so: true,
      soa: false,
      soa2: false,
      pdfVal2: null,
      pdfHost: '',
      openPrivatePopup: false
    }
  },
  computed: {
    ...mapGetters(['pdfUrl', 'orderDataInfo', 'commRsp', 'commTitle', 'pdfUrlBySOA', 'pdfUrlBySOA2', 'editRowData', 'infoData', 'routerParams']),
    btn1: {
      get () {
        switch (this.$route.name) {
          case 'bill':
            return 'SO'
          case 'hblcheck':
            return 'HC'
          case 'lading':
            return 'HB'
          case 'mblsiView':
            return 'SI'
          case 'mblView':
            return 'MC'
          case 'spouchhb2View':
            if (this.routerParams.doc_type === 'HB') {
              return 'HB'
            }
            return ''
          default:
            return ''
        }
      }
    },
    showPdfUrl: {
      get () {
        if (this.so) {
          return this.replacePdfHost(this.pdfUrl)
        }
        if (this.soa) {
          console.log('showPdfUrl pdfUrlBySOA', this.pdfUrlBySOA)
          return this.replacePdfHost(this.pdfUrlBySOA)
        }
        if (this.soa2) {
          console.log('showPdfUrl pdfUrlBySOA2', this.pdfUrlBySOA2)
          return this.replacePdfHost(this.pdfUrlBySOA2)
        }
        return ''
      }
    },
    btn2: {
      get () {
        switch (this.$route.name) {
          case 'bill':
            return 'SOA'
          case 'hblcheck':
            return 'HCA'
          case 'lading':
            return 'HBA'
          case 'mblsiView':
            return 'SIA'
          case 'mblView':
            return 'MCA'
          case 'spouchhb2View':
            if (this.routerParams.doc_type === 'HB') {
              return 'HBA'
            }
            return ''
          default:
            return ''
        }
      }
    },
    btn3: {
      get () {
        switch (this.$route.name) {
          case 'mblView':
            return 'MCD'
          default:
            return ''
        }
      }
    },
    isShow: {
      get () {
        console.log('isShow this.$route.name', this.$route.name)
        console.log('isShow pdfUrl', this.pdfUrl)
        console.log('isShow pdfUrlBySOA', this.pdfUrlBySOA)
        console.log('isShow pdfUrlBySOA2', this.pdfUrlBySOA2)
        switch (this.$route.name) {
          case 'hblcheck':
          case 'lading':
          case 'mblsiView':
          case 'mblView':
            return true
          default:
            return false
        }
      }
    },
    isShowBtn: {
      get () {
        switch (this.$route.name) {
          case 'bill':
          case 'hblcheck':
          case 'lading':
          case 'mblsiView':
          case 'mblView':
            console.log('isShowBtn this.pdfUrlBySOA', this.pdfUrlBySOA)
            if (this.pdfUrlBySOA !== '') {
              return true
            }
            return false
          case 'spouchhb2View':
            if (this.routerParams.doc_type === 'HB') {
              if (this.pdfUrlBySOA !== '') {
                return true
              }
            }
            return false
          default:
            return false
        }
      }
    },
    pdfVal () {
      return this.$store.state.pafupdata || null
    },
    pdfRouter () {
      return '/media/E21070768_2815_QU_210703212838.pdf'
      // let link
      // switch (this.$route.name) {
      //   case 'quotation':
      //     link = require('../../assets/pdf/1.pdf')
      //     break
      //   case 'notice':
      //     link = require('../../assets/pdf/2.pdf')
      //     break
      //   case 'cargo':
      //     link = require('../../assets/pdf/3.pdf')
      //     break
      //   case 'packingList':
      //     link = require('../../assets/pdf/4.pdf')
      //     break
      //   case 'bill':
      //     link = require('../../assets/pdf/5.pdf')
      //     break
      //   case 'checklist':
      //     link = require('../../assets/pdf/6.pdf')
      //     break
      //   case 'lading':
      //     link = require('../../assets/pdf/7.pdf')
      //     break
      //   case 'customer':
      //     link = require('../../assets/pdf/8.pdf')
      //     break
      //   case 'invoice':
      //     link = require('../../assets/pdf/9.pdf')
      //     break
      //   default:
      //     link = null
      //     break
      // }
      // return link
    }
  },
  methods: {
    ...mapActions(['editDatahandler']),
    clickShow (type) {
      switch (type) {
        case 'so':
          this.so = true
          this.soa = false
          this.soa2 = false
          break
        case 'soa':
          this.so = false
          this.soa = true
          this.soa2 = false
          break
        case 'soa2':
          this.so = false
          this.soa = false
          this.soa2 = true
          break
      }
    },
    getFileUrl () {
      console.log(this.pdfVal)
      this.pdfVal2 = URL.createObjectURL('http://kfrd.ioneit.com/media/E21070768_2815_QU_210703212838.pdf')
      console.log(this.pdfVal2)
    },
    replacePdfHost (url) {
      var returnUrl = ''
      if (url.match(/^http[s]?:\/\//)) {
        returnUrl = url.replace(/(^http[s]?:\/\/[^/]+)(.*)/, this.globalPDFHost + '$2')
      } else {
        returnUrl = this.globalPDFHost + url
      }
      console.log(returnUrl)
      return returnUrl
    },
    async save () {
      // console.log('@@@@@@@', this.$route.params)
      const parm = this.$route.params
      await this.editDatahandler(parm)
      switch (this.commRsp.status) {
        case 200:
        case 201:
          this.$message({
            type: 'success',
            message: '成功'
          })
          break
        default:
          this.$message({
            type: 'info',
            message: '失敗'
          })
          break
      }
    },
    backPage () {
      console.log('backPage this.$route', this.$route)
      this.$store.commit('pdfUrlBySOA', '')
      this.$router.back()
    },
    goback () {
      history.back()
    },
    checkPrivateKey () {
      var self = this
      if (window.localStorage.getItem('pkCode') === null) {
        this.$message({
          type: 'error',
          message: '請先匯入私鑰再上傳'
        })
        setTimeout(() => {
          self.openPrivatePopup = true
        }, 1200)
        return false
      } else {
        return true
      }
    },
    uploadChain () {
      if (confirm('確定要上鏈?')) {
        if (!this.checkPrivateKey()) {
          return
        }
        this.$store.dispatch('uploadChain')
      }
    },
    markAvoid () {
      if (confirm('確定要作廢?')) {
        if (!this.checkPrivateKey()) {
          return
        }
        this.$store.dispatch('markChainAvoid')
      }
    },
    rejectChain () {
      if (confirm('確定不同意?')) {
        if (!this.checkPrivateKey()) {
          return
        }
        this.$store.dispatch('rejectChain')
      }
    }
  },
  mounted () {
    this.pdfHost = this.globalPDFHost
  }
}
</script>

<style lang="scss" module>
.pagerdata {
  padding-bottom: 45px;
  border-bottom: solid 2px #e4e7ec;
}

.status {
  width: 177px;
}

.manipulate {
  margin: 45px 0 20px 0;
  padding: 0 34px;
  box-sizing: border-box;
}

.deletebtn {
  margin-left: 35px !important;
}
.back_button{
  position: absolute;
  width: 80px;
  right: 56px;
}
</style>
