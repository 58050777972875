export default {
  tw: {
    Language: '語言',
    Login: '登入',
    Account: '帳號',
    Password: '密碼',
    ForgetPassword: '忘記密碼',
    CompanyAccount: '公司帳號',
    Admin: '管理員',
    ChangePassword: '變更密碼',
    PrivateKey: '私鑰設定',
    Signout: '登出',
    Home: '首頁',
    BookingMgmt: '海運承攬管理',
    delivery: '出貨通知單管理',
    FormMgmt: '表單管理',
    MasterFileMgmt: '主檔管理',
    SystemMgmt: '系統管理',
    Delete: '刪除',
    Edit: '編輯',
    Add: '新增',
    Neworder: '新增訂單',
    JobNo: 'Job No',
    VesselName: '船名',
    Voyage: '航次',
    SONo: 'SO No',
    CustomerName: '客戶中文名稱',
    CustomsClearanceDate: '結關日期',
    entry_user_code: '客戶名稱',
    ShipDate: '裝船日期',
    HBLNo: 'HB/LNo',
    PLR: '收貨港',
    POD: '卸貨港',
    All: '全部',
    Shipment: '出貨通知',
    CompleteBooking: '完成訂艙',
    Delivery: '貨進櫃場',
    CargoMeasure: '貨物丈量',
    SoReleased: 'SO提供',
    CustomsReleased: '出口放行',
    CheckReleased: '提單核對',
    QuotationConfirmed: '報價確認',
    DocumentReleased: '發單通知',
    Departure: '啟航',
    PouchReceived: 'POUCH 文件接收',
    Arrival: '抵達',
    DoRelease: 'DO release',
    NoData: '無資料',
    OrderId: '訂單編號',
    Status: '狀態',
    CargoConditionNode: '貨況節點',
    sentence: {
      sentence_001: '艾旺區塊鏈平台'
    },
    vslName: '船名',
    closingDate: '結關日',
    onboardDate: '裝船日',
    id: '訂單編號',
    ship_num: '船期編號',
    line_code: '航線',
    pre_book_no: 'Job No',
    vsl_name: '船名',
    voyage: '航次',
    so_no: 'SO No',
    o_bl_no: 'HB/LNo',
    customer_code: '客戶中文名稱',
    closing_date: '結關日期',
    onboard_date: '裝船日期',
    receipt_place: '收貨港',
    port_discharge: '卸貨港',
    track_point: '節點',
    status: '狀態',
    update_time: '上傳時間',
    uploader: '上傳者',
    version: '版本',
    View: '檢視'
  }
}
