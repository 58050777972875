<template>
  <div>
    <img
      src="../assets/image/logo.png"
      :class="$style.logo"
      @click="$route.name !== 'home' ? $router.push({ name: 'home' }) : ''"
    />
    <el-menu
      :default-active="this.$route.meta.class"
      class="el-menu-vertical-demo"
      router="router"
      :class="$style.menu"
    >
      <el-menu-item
        index="home"
        :route="{ path: '/home' }" v-if="permissions.indexOf('home')>-1"
      >
        <span slot="title">
          {{ $t('Home') }}
        </span>
      </el-menu-item>
      <el-menu-item
        index="list"
        :route="{ path: '/list/list' }"
        v-if="permissions.indexOf('list_list')>-1"
      >
        <span slot="title">
          {{ $t('BookingMgmt') }}
        </span>
      </el-menu-item>
      <el-submenu index="5" v-if="groupUserIdMap.form.indexOf(userId)>-1">
        <template slot="title">
          <span>{{ $t('FormMgmt') }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            index="sshippingprice"
            :route="{ path: '/sshippingprice' }"
            v-if="permissions.indexOf('sshippingprice')>-1"
          >
            出貨通知單
          </el-menu-item>
          <el-menu-item
            index="scargo"
            :route="{ path: '/scargo' }"
            v-if="permissions.indexOf('scargo')>-1"
          >
            裝船通知書
          </el-menu-item>
          <el-menu-item
            index="smeasure"
            :route="{ path: '/smeasure' }"
            v-if="permissions.indexOf('smeasure')>-1"
          >
            貨物丈量單
          </el-menu-item>
          <el-menu-item
            index="smcertificate"
            :route="{ path: '/smcertificate' }"
            v-if="permissions.indexOf('smcertificate')>-1"
          >
            丈量證明文件
          </el-menu-item>
          <el-menu-item
            index="sclearanceIV"
            :route="{ path: '/sclearanceIV' }"
            v-if="permissions.indexOf('sclearanceIV')>-1"
          >
            Invoice
          </el-menu-item>
          <el-menu-item
            index="sclearancePL"
            :route="{ path: '/sclearancePL' }"
            v-if="permissions.indexOf('sclearancePL')>-1"
          >
            Packing List
          </el-menu-item>
          <el-menu-item
            index="shblso"
            :route="{ path: '/shblso' }"
            v-if="permissions.indexOf('shblso')>-1"
          >
            HB/L提單作法(S/O)
          </el-menu-item>
          <el-menu-item
            index="spackinglist"
            :route="{ path: '/spackinglist' }"
            v-if="permissions.indexOf('spackinglist')>-1"
          >
            裝櫃清單
          </el-menu-item>
          <el-menu-item
            index="shblcheck"
            :route="{ path: '/shblcheck' }"
            v-if="permissions.indexOf('shblcheck')>-1"
          >
            HB/L核對單
          </el-menu-item>
          <el-menu-item
            index="quotationConfirmation"
            :route="{ path: '/quotationConfirmation' }"
            v-if="permissions.indexOf('quotationConfirmation')>-1"
          >
            報價確認單
          </el-menu-item>
          <el-menu-item
            index="smblso"
            :route="{ path: '/smblso' }"
            v-if="permissions.indexOf('smblso')>-1"
          >
            MB/L 提單做法(SI)
          </el-menu-item>
          <el-menu-item
            index="smblcheck"
            :route="{ path: '/smblcheck' }"
            v-if="permissions.indexOf('smblcheck')>-1"
          >
            MB/L 核對單
          </el-menu-item>

          <el-menu-item
            index="sdochbl"
            :route="{ path: '/sdochbl' }"
            v-if="permissions.indexOf('sdochbl')>-1"
          >
            HB/L 提單
          </el-menu-item>
          <el-menu-item
            index="sdocbill"
            :route="{ path: '/sdocbill' }"
            v-if="permissions.indexOf('sdocbill')>-1"
          >
            客戶帳單
          </el-menu-item>
          <el-menu-item
            index="spouchdc"
            :route="{ path: '/spouchdc' }"
            v-if="permissions.indexOf('spouchdc')>-1"
          >
            POUCH-文件
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="mainfile" v-if="userId<3">
        <template slot="title">
          <span>{{ $t('MasterFileMgmt') }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            index="customermanagement"
            :route="{ path: '/customermanagement' }"
            v-if="permissions.indexOf('customermanagement')>-1"
          >
            客戶管理
          </el-menu-item>
          <el-menu-item
            index="suppliermanagement"
            :route="{ path: '/suppliermanagement' }"
            v-if="permissions.indexOf('suppliermanagement')>-1"
          >
            商業夥伴管理
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="msupplier" v-if="userId<3">
        <template slot="title">
          <span>{{ $t('SystemMgmt') }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            index="authority"
            :route="{ path: '/authority/userauthority' }"
            v-if="permissions.indexOf('authority')>-1"
          >
            權限管理
          </el-menu-item>
          <el-menu-item
            index="usermanagement"
            :route="{ path: '/usermanagement' }"
            v-if="permissions.indexOf('usermanagement')>0"
          >
            使用者管理
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { userPermissionSingle } from '@/api/system'
export default {
  data () {
    return {
      permissions: [],
      userId: 0,
      userMap: {
        1: ['home', 'list_list', 'sshippingprice', 'scargo', 'smeasure', 'smcertificate', 'sclearanceIV', 'sclearancePL', 'shblso', 'spackinglist', 'shblcheck', 'quotationConfirmation', 'smblso', 'smblcheck', 'sdochbl', 'sdocbill', 'spouchdc', 'spouchmb', 'spouchma', 'spouchhb', 'spouchiv', 'spouchpl', 'customermanagement', 'suppliermanagement', 'authority', 'usermanagement'], // 系統管理員
        2: ['home', 'list_list', 'sshippingprice', 'scargo', 'smeasure', 'smcertificate', 'sclearanceIV', 'sclearancePL', 'shblso', 'spackinglist', 'shblcheck', 'quotationConfirmation', 'smblso', 'smblcheck', 'sdochbl', 'sdocbill', 'spouchdc', 'spouchmb', 'spouchma', 'spouchhb', 'spouchiv', 'spouchpl', 'customermanagement', 'suppliermanagement', 'authority', 'usermanagement'], // 艾旺管理員
        3: ['list_list'], // 國內客戶
        4: ['list_list', 'sshippingprice', 'scargo', 'smcertificate', 'sclearanceIV', 'sclearancePL', 'shblso', 'shblcheck', 'sdochbl', 'quotationConfirmation', 'sdocbill'], // 同行
        5: ['scargo', 'sclearanceIV', 'sclearancePL', 'shblso', 'sdochbl', 'shblcheck', 'sdocbill'], // 報關行
        6: ['scargo'], // 貨運公司
        7: ['smblso', 'smblcheck'], // 船公司
        8: ['spouchdc', 'spouchmb', 'spouchma', 'spouchhb', 'spouchiv', 'spouchpl'], // 國外代理商
        9: ['spackinglist'], // 貨櫃場
        10: ['smeasure'], // 公證行
        11: ['home', 'list_list', 'sshippingprice', 'scargo', 'smeasure', 'smcertificate', 'sclearanceIV', 'sclearancePL', 'shblso', 'spackinglist', 'shblcheck', 'quotationConfirmation', 'smblso', 'smblcheck', 'sdochbl', 'sdocbill', 'spouchdc', 'spouchmb', 'spouchma', 'spouchhb', 'spouchiv', 'spouchpl'], // 會計
        12: ['home', 'list_list', 'sshippingprice', 'scargo', 'smeasure', 'smcertificate', 'sclearanceIV', 'sclearancePL', 'shblso', 'spackinglist', 'shblcheck', 'quotationConfirmation', 'smblso', 'smblcheck', 'sdochbl', 'sdocbill', 'spouchdc', 'spouchmb', 'spouchma', 'spouchhb', 'spouchiv', 'spouchpl'], // 運務
        13: ['home', 'list_list'], // 業務
        14: ['home', 'list_list', 'sshippingprice', 'scargo', 'smeasure', 'smcertificate', 'sclearanceIV', 'sclearancePL', 'shblso', 'spackinglist', 'shblcheck', 'quotationConfirmation', 'smblso', 'smblcheck', 'sdochbl', 'sdocbill', 'spouchdc', 'spouchmb', 'spouchma', 'spouchhb', 'spouchiv', 'spouchpl', 'customermanagement', 'suppliermanagement', 'authority', 'usermanagement'], // 會計管理員
        15: ['home', 'list_list', 'sshippingprice', 'scargo', 'smeasure', 'smcertificate', 'sclearanceIV', 'sclearancePL', 'shblso', 'spackinglist', 'shblcheck', 'quotationConfirmation', 'smblso', 'smblcheck', 'sdochbl', 'sdocbill', 'spouchdc', 'spouchmb', 'spouchma', 'spouchhb', 'spouchiv', 'spouchpl', 'customermanagement', 'suppliermanagement', 'authority', 'usermanagement'], // 運務管理員
        16: ['home', 'list_list', 'sshippingprice', 'scargo', 'smeasure', 'smcertificate', 'sclearanceIV', 'sclearancePL', 'shblso', 'spackinglist', 'shblcheck', 'quotationConfirmation', 'smblso', 'smblcheck', 'sdochbl', 'sdocbill', 'spouchdc', 'spouchmb', 'spouchma', 'spouchhb', 'spouchiv', 'spouchpl', 'customermanagement', 'suppliermanagement', 'authority', 'usermanagement'],
        20: ['list_list'] // 業務管理員
      },
      groupUserIdMap: {
        form: [1, 2, 5, 6, 7, 8, 9, 10, 11, 12, 14],
        mainfile: [1, 2],
        system: [1, 2]
      }
    }
  },
  mounted: async function () {
    const res = await userPermissionSingle()
    this.userId = res.data.role[0].id
    this.permissions = this.userMap[this.userId]
  }
}
</script>

<style lang="scss" module>
.logo {
  width: 149px;
  height: 65px;
  align-self: center;
  margin: 16px 0;
  cursor: pointer;
}

.menu {
  min-height: calc(100% - 98px);

  &:global(.el-menu) {
    border: none;
  }
}
</style>
