// eslint-disable-next-line
import { invoicePackinglistSingle } from '@/api/invoicePackinglist'
import { hblsoSingle, hblsoList } from '@/api/hblso'

// eslint-disable-next-line
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
const soReleased = {
  state: {
    invoicePackinglistDetailTableLabel: [{
      label: 'version',
      prop: 'version',
      minWidth: 50
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    hblsoSingleTableLabel: [{
      label: 'version',
      prop: 'version',
      minWidth: 50
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'Edit',
      prop: 'Edit',
      icon: true,
      minWidth: 20,
      node: 'Edit',
      key: 'id'
    }, {
      label: '上傳SOA',
      btn: true,
      prop: 'button',
      minWidth: 20,
      node: 'SOA'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    soTab: 0,
    billEditData: {}
  },

  getters: {
    invoicePackinglistDetailTableLabel: (state) => state.invoicePackinglistDetailTableLabel,
    soTab: (state) => state.soTab,
    hblsoSingleTableLabel: (state) => state.hblsoSingleTableLabel,
    billEditData: (state) => state.billEditData
  },

  mutations: {
    invoicePackinglistDetailTableLabel: (state, payload) => { state.invoicePackinglistDetailTableLabel = payload },
    soTab: (state, payload) => { state.soTab = payload },
    hblsoSingleTableLabel: (state, payload) => { state.hblsoSingleTableLabel = payload },
    billEditData: (state, payload) => { state.billEditData = payload }
  },
  actions: {
    setinvoicePackinglistTableLabel: async (state) => {
      await state.commit('tableLabel', state.getters.invoicePackinglistDetailTableLabel)
    },
    sethblsoSingleTableLabel: async (state) => {
      await state.commit('tableLabel', state.getters.hblsoSingleTableLabel)
    },
    invoicePackinglistSearch: async (state, payload) => {
      await state.commit('tableLabel', state.getters.invoicePackinglistDetailTableLabel)
      const body = { offset: 0, limit: 10 }
      if (payload !== null) {
        body.pre_book_no = payload.orderId
        body.file_type = payload.file_type
      }
      console.log('invoicePackinglistSingle body', body)
      var result = await invoicePackinglistSingle(body)
      state.dispatch('singleFileInfoData', { rows: result.data, pre_book_no: '', pdf_serial: 0 })
      const orderDataInputList = []
      Object.values(state.getters.formlistTableLabel).forEach(async (item, idx) => {
        if (idx > 1) {
          orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
        }
      })
      console.log('invoicePackinglistSearch payload.file_type', payload.file_type)
      state.dispatch('getPdfEmailList', payload.file_type)
      // await state.commit('pdfUrl', result.data.data.ListData[0].file_path)
      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            item.View = faEye
            item.pre_book_no = state.getters.orderId
          })
          await state.commit('pageData', result.data.data.PageData)
          await state.commit('tableData', result.data.data.ListData)
          // await state.commit('orderDataInfo', orderDataInputList)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          state.commit('tableLabel', [])
      }
      console.log('tableData', state.getters.tableData)
      console.log('tableLabel', state.getters.tableLabel)
      console.log('invoicePackinglistSingle result', result)
    },
    hblsoSingleSearch: async (state, payload) => {
      await state.dispatch('clearData')
      await state.commit('tableLabel', state.getters.hblsoSingleTableLabel)
      console.log('hblsoSingleSearch payload', payload)
      const body = { offset: 0, limit: 10 }
      if (payload !== null) {
        body.messageid = payload.messageid === undefined ? payload.orderId : payload.messageid
        if (payload.version !== undefined) {
          body.version = payload.version
        }
      }
      console.log('hblsoSingleSearch body', body)
      var result = await hblsoSingle(body)
      state.dispatch('singleFileInfoData', { rows: result.data, pre_book_no: '', pdf_serial: 0 })
      // console.log('singleFileInfoData', state.dispatch('singleFileInfoData', { rows: result.data, pre_book_no: '', pdf_serial: 0 }))
      console.log('hblsoSingleSearch result', result)
      state.dispatch('getPdfEmailList', 'SO')
      // await state.commit('pdfUrl', result.data.data.ListData[0].file_path)
      switch (result.status) {
        case 200:
          var rsp = await hblsoList({ messageid: body.messageid })
          var maxVersion = rsp.data.data.ListData[0].version // Math.max(...result.data.data.ListData.map(item => item.version))
          // console.log('maxVersion', maxVersion)
          await Object.values(result.data.data.ListData).forEach(item => {
            if (Number(item.version) === maxVersion) {
              item.max = true
            } else {
              item.max = false
            }
            item.Edit = faEdit
            item.View = faEye
            // item.messageid = state.getters.orderId
            item.button = true
          })
          await state.commit('pageData', result.data.data.PageData)
          await state.commit('tableData', result.data.data.ListData)
          break
        default:
          await state.commit('pageData', [])
          await state.commit('tableData', [])
          await state.commit('tableLabel', [])
      }
      console.log('tableData', state.getters.tableData)
      console.log('tableLabel', state.getters.tableLabel)
    },
    hblsoSingleAndInvoicePackinglistCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      switch (Number(state.getters.soTab)) {
        case 0:
          body.pre_book_no = state.getters.orderId
          body.file_type = 'IV'
          result = await invoicePackinglistSingle(body)
          await Object.values(result.data.data.ListData).forEach(item => {
            item.View = faEye
            item.pre_book_no = state.getters.orderId
          })
          await state.commit('tableLabel', state.getters.invoicePackinglistDetailTableLabel)
          break
        case 1:
          body.pre_book_no = state.getters.orderId
          body.file_type = 'PL'
          result = await invoicePackinglistSingle(body)
          await Object.values(result.data.data.ListData).forEach(item => {
            item.View = faEye
            item.pre_book_no = state.getters.orderId
          })
          await state.commit('tableLabel', state.getters.invoicePackinglistDetailTableLabel)
          break
        case 2:
          body.messageid = state.getters.orderId
          result = await hblsoSingle(body)
          var rsp = await hblsoList({ messageid: body.messageid })
          var maxVersion = rsp.data.data.ListData[0].version // Math.max(...result.data.data.ListData.map(item => item.version))
          console.log('maxVersion', maxVersion)
          await Object.values(result.data.data.ListData).forEach(item => {
            if (Number(item.version) === maxVersion) {
              item.max = true
            } else {
              item.max = false
            }
            item.Edit = faEdit
            item.View = faEye
            item.messageid = state.getters.orderId
            item.button = true
          })
          await state.commit('tableLabel', state.getters.hblsoSingleTableLabel)
          break
      }
      // console.log('hblsoSingleAndInvoicePackinglistCurrentChange body', body)
      // console.log('hblsoSingleAndInvoicePackinglistCurrentChange result', result)
      // console.log('hblsoSingleAndInvoicePackinglistCurrentChange PageData', state.getters.pageData)
      result.data.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.data.PageData.currentPage = currentPage
      // console.log('hblsoSingleAndInvoicePackinglistCurrentChange PageData', state.getters.pageData)
      await state.commit('pageData', result.data.data.PageData)
      await state.commit('tableData', result.data.data.ListData)
    }
  }
}

export default soReleased
