<template>
  <div>
    <card>
      <template #title>
        {{ $route.meta.title }}
      </template>
      <template #titleBtn>
        <btnList
          @add="addfn('新增')"
          @del="delfn"
          :hasAddbtn="true"
        />
      </template>
      <template #manipulatlist>
        <inputlist/>
      </template>
      <template #table>
        <tableComponent @edit="editfn" :tableData="tableData" :tableLabel="tableLabel" />
      </template>
      <template #pagination>
        <pageination/>
      </template>
    </card>
    <popup v-if="datapopup" @close="datapopup = false" :titleBtnText="titleBtnText" :rowData="rowData" :action="action"/>
  </div>
</template>

<script>
import card from '@/components/card/card.vue'
import pageination from '@/components/plug/page/pageination.vue'
import inputlist from '@/views/mainfile/components/userinput.vue'
import btnList from '@/components/plug/button/button.vue'
import tableComponent from '@/views/mainfile/components/usertable.vue'
import popup from '@/views/mainfile/popup/datapopup.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  mounted: function () {
    console.log('F tableData', this.tableData)
    console.log('F tableLabel', this.tableLabel)
  },
  computed: {
    ...mapGetters(['tableData', 'tableLabel', 'customerResultList'])
  },
  components: {
    card,
    pageination,
    inputlist,
    btnList,
    tableComponent,
    popup
  },
  data () {
    return {
      rowData: [],
      datapopup: false,
      titleBtnText: '',
      action: ''
    }
  },
  methods: {
    ...mapActions(['delTableSelectData']),
    editfn (value, row) {
      console.log('editfn', value, row)
      this.action = 'mod'
      this.rowData = row
      this.datapopup = true
      this.titleBtnText = value
    },
    addfn (value) {
      console.log('addfn', value)
      this.action = 'add'
      this.datapopup = true
      this.titleBtnText = this.$route.name === 'customermanagement' ? value + '客戶' : value + '商業夥伴'
    },
    async delfn () {
      await this.delTableSelectData()
      console.log('@@@', this.customerResultList[0].status)
      switch (this.customerResultList[0].status) {
        case 200:
        case 201:
          this.$message({
            type: 'success',
            message: '成功'
          })
          Object.values(this.addInputlist).forEach(item => {
            item.msg = ''
          })
          break
        case 400:
          Object.keys(this.customerResultList[0].data).forEach(key => {
            Object.values(this.addInputlist).forEach(item => {
              if (item.key === key) {
                item.msg = this.customerResultList[0].data[key][0]
              }
            })
          })
          this.$message({
            type: 'info',
            message: '請檢查欄位'
          })
          break
        default:
          this.$message({
            type: 'info',
            message: '失敗'
          })
      }
    }
  }
}
</script>

<style lang="scss" module>
</style>
