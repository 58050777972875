<template>
  <div :class="$style.root">
    <p :class="$style.title">
      <slot name="title"/>
    </p>
    <div :class="$style.input">
      <slot name="input"/>
    </div>
    <div :class="$style.msg">
      <slot name="msg"/>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  }
}
</script>

<style lang="scss" module>
.root {
  width: 100%;
}

.title {
  font-size: 12px;
  font-weight: 500;
  color: #68686a;
  margin-bottom: 5px;
}

.msg {
  font-size: 12px;
  font-weight: 500;
  color: #ff0000;
  margin-bottom: 5px;
}

.input {
  :global(.el-select) {
    width: 100% !important;
  }

  :global(.el-date-editor.el-input) {
    width: 100% !important;
  }
}
</style>
