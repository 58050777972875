<template>
  <div>
    <div :class="$style.title">相關資訊</div>
    <el-row :class="$style.main" >
      <el-col :span="8" >
        <div class="infoTitle">
          發送方
        </div>
        <div class="senderRow">
          {{infoData.sender.name}}
          <span class="statusBlocked" v-if="infoData.sender_status === 1">已上鏈</span>
          <span class="statusVoid" v-else-if="infoData.sender_status === 2">作廢</span>
          <span class="statusUnblock" v-else>未上鏈</span>
          <span class="statusDate" v-if="infoData.sender_status" type="">{{infoData.sender_on}}</span>
        </div>
      </el-col>
      <el-col :span="16" style="border-left: 1px solid #ededed;padding-left: 20px;">
        <div class="infoTitle">
          接收方
        </div>
        <div class="receiverRow" v-for="(v, k) in receiverList" :key="k" >
          <div v-if="infoData['receiver_' + v].basic_type !== null && infoData['receiver_' + v].basic_code !== null && infoData['receiver_' + v].name" >
            <input :checked="infoData['receiver_' + v].permission === 1" @click="changeFunction(v, $event)" type="checkbox" >
            {{infoData['receiver_' + v].name}}
            <span class="statusBlocked" v-if="infoData['receiver_' + v + '_status'] === 1">已上鏈</span>
            <span class="statusVoid" v-else-if="infoData['receiver_' + v + '_status'] === 3">作廢</span>
            <span class="statusUnblock" v-else>未上鏈</span>
            <span class="statusDate" v-if="infoData.sender_status" type="">{{infoData['receiver_' + v + '_on']}}</span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['infoData'])
  },
  components: {
  },
  data () {
    return {
      receiverList: [1, 2, 3, 4],
      checkList: [true, true, true, true]
    }
  },
  methods: {
    async changeFunction (idx, e) {
      console.log(this.$router.currentRoute.name)
      if (!confirm(`是否${this.infoData['receiver_' + idx].permission ? '取消' : '開啟'}角色為接收方？`)) {
        e.preventDefault()
        return
      }
      var result = await this.$store.dispatch('updateReceiverStatus', {
        routeName: this.$router.currentRoute.name,
        receiverIdx: idx
      })
      console.log('update receiver status result:', result)
      if (result !== null && result.status === 200) {
        this.$message({
          type: 'success',
          message: '更新完畢!'
        })
      } else {
        this.$message({
          type: 'info',
          message: '已取消更新'
        })
      }
    }
  }
}
</script>

<style lang="scss" module>
.button{
  padding: 10px 15px !important;
  font-size:12px !important;
}
.chainDate{
  margin-top:10px;
  color:#409EFF;
}
.title {
  font-size: 16px;
  font-weight: 500;
  color: #68686a;
  margin-left: 20px;
  margin-top: 15px;
}

.outside {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  min-height:59px;
  margin-top:5px;
}
.text {
  font-size: 13px;
  font-weight: 500;
  color: #7e7e7e;
  text-align: center;
  min-height:59px;
  margin-top:5px;
  min-width: 50px;
  margin-left: 20px;
}
.datainput {
  display: grid;
  grid-template-columns: 1fr 50px 3.7fr;
  align-items: center;
  grid-column-gap: 20px;
  border: 1px solid #ededed;
}

.main {
  max-height:100%;
  margin: 20px;
  padding: 15px 20px 20px;
  border: 1px solid #ededed;
  border-radius: 5px;
}

.noline {
  border: none;
}
.sp1{
  margin-top:-2px;
}
</style>
<style scoped>
.infoTitle{
  height: 20px;
  font-size: 13px;
  color: #696c70;
}
.receiverRow, .senderRow{
  margin-top:10px;
}
.statusDate{
  margin-left: 10px;
  font-size: 13px;
  color: #87898C;
}
.statusBlocked {
  border: 1px solid #4BA676;
  color: #4BA676;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 12px;
}
.statusUnblock {
  background:#EDEDEE;
  color: #87898C;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 12px;
}
.statusVoid {
  border: 1px solid #aa0000;
  color: #aa0000;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 12px;
}
</style>
