<template>
  <popup :class="$style.outside" @close="$emit('close')">
    <template #title>
      {{ titleBtnText }}{{ $route.name === 'userauthority' ? '角色' : '群組'  }}
    </template>
    <template #content>
      <div :class="$style.out">
        <inputStyle
          v-for="(item, i) in list"
          :key="i"
          :fixWidth="30"
          :class="$style.heightfix"
        >
          <template #title>
            {{ item.label }}
          </template>
          <template #data>
            <input :class="$style.input" v-if="item.type === 'input'" v-model="item.input">
          </template>
        </inputStyle>
      </div>
      <el-button
        type="primary"
        :class="$style.btn"
        @click="addAtuthority"
      >
        {{ titleBtnText }}
      </el-button>
    </template>
  </popup>
</template>

<script>
import popup from '@/components/card/popup.vue'
import inputStyle from '@/components/card/datainput.vue'
import { userPermissionAction } from '@/api/system'
export default {
  props: {
    titleBtnText: String
  },
  components: {
    popup,
    inputStyle
  },
  data () {
    return {
      list: [{
        label: '名稱',
        input: '',
        type: 'input'
      }, {
        label: '備註',
        input: '',
        type: 'input'
      }],
      name: '',
      remark: ''
    }
  },
  methods: {
    async addAtuthority () {
      if (this.list[0].input.trim() === '' || this.list[1].input.trim() === '') {
        this.$message({
          type: 'error',
          message: '請輸入完整資料'
        })
        return
      }
      var body = {
        action: 'A',
        name: this.list[0].input,
        remark: this.list[1].input,
        basic_type: ''
      }
      const r = await userPermissionAction(body)
      switch (r.code) {
        case '200':
          this.$message({
            type: 'success',
            message: '新增成功'
          })
          await this.$store.dispatch('userPermissions')
          this.$emit('close')
          break
        case '400':
          this.$message({
            type: 'error',
            message: '新增失敗(' + r.detail + ')'
          })
          break
        default:
          this.$message({
            type: 'info',
            message: '儲存失敗(' + r.detail + ')'
          })
      }
    }
  },
  mounted () {
    this.name = ''
    this.remark = ''
  }
}
</script>

<style lang="scss" module>
.input {
  border: none;
  width: 100%;
  background: none;
  color: #7e7e7e;
}

.out {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 16px;
  margin-top: 30px;
  padding: 0 20px;
  box-sizing: border-box;
}

.btn {
  align-self: center;

  &:global(.el-button) {
    margin: 25px 0px;
  }
}

.outside {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select {
  :global(.el-select) {
    width: 100%;
  }

  :global(.el-input__inner) {
    height: 30px;
  }

  :global(.el-input__icon) {
    line-height: 30px;
  }
}

.radio {
  width: 100%;
}
</style>
