<template>
  <popup :class="$style.outside" @close="$emit('close')" :pageWidth="1200">
    <template #title>
      貨量資訊({{mapDetail.total_measure}})
    </template>
    <template #content>
      <div :class="$style.out">
        <div :class="$style.inside">
          <el-table
            :class="$style.insideTable"
            :data="mapDetail.sales[0]"
            width="90%"
            :row-class-name="tableRowClassName">
            empty-text="無資料"
            <el-table-column
              prop="sales_code"
              label="業務"
              width="250"
            >
            </el-table-column>
            <el-table-column
              prop="total_measure"
              :label="'貨量(' + salesMeasureCount + ')'"
            >
            </el-table-column>
          </el-table>
          <Bar
            :class="$style.insideChart"
            :chart-options="chartOptions"
            :chart-data="salesChartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="salesStyles"
          />
        </div>
        <div :class="$style.inside">
          <el-table
            :class="$style.insideTable"
            :data="mapDetail.carriers[0]"
            :row-class-name="tableRowClassName">
            empty-text="無資料"
            <el-table-column
              prop="carrier"
              label="船公司"
              width="250"
            >
            </el-table-column>
            <el-table-column
              prop="total_measure"
              :label="'貨量(' + carrierMeasureCount + ')'"
            >
            </el-table-column>
          </el-table>
          <Bar
            :class="$style.insideChart"
            :chart-options="carrierChartOptions"
            :chart-data="carrierChartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="carriersStyles"
          />
        </div>
        <div :class="$style.inside">
          <el-table
            :class="$style.insideTable"
            :data="mapDetail.customers[0]"
            :row-class-name="tableRowClassName">
            empty-text="無資料"
            <el-table-column
              prop="customer_code"
              label="客戶"
              width="250"
            >
            </el-table-column>
            <el-table-column
              prop="total_measure"
              :label="'貨量(' + customerMeasureCount + ')'"
            >
            </el-table-column>
          </el-table>
          <Bar
            :class="$style.insideChart"
            :chart-options="customerChartOptions"
            :chart-data="customerChartData"
            :chart-id="chartId"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="customersStyles"
          />
        </div>
      </div>
    </template>
  </popup>
</template>

<style>
  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>

<script>
import popup from '@/components/card/popup.vue'
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)
export default {
  props: {
    mapDetail: Object,
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 500
    },
    cssClasses: {
      default: '',
      type: String
    },
    plugins: {
      type: Array,
      default: () => [ChartDataLabels]
    }
  },
  computed: {
    salesStyles () {
      return {
        height: (this.mapDetail.sales[0].length * 30 + 100) + 'px'
      }
    },
    carriersStyles () {
      return {
        height: (this.mapDetail.carriers[0].length * 30 + 100) + 'px'
      }
    },
    customersStyles () {
      return {
        height: (this.mapDetail.customers[0].length * 30 + 100) + 'px'
      }
    },
    salesMeasureCount () {
      var sum = 0
      this.mapDetail.sales[0].forEach(function (item) {
        sum += item.total_measure
      })
      return sum
    },
    carrierMeasureCount () {
      var sum = 0
      this.mapDetail.carriers[0].forEach(function (item) {
        sum += item.total_measure
      })
      return sum
    },
    customerMeasureCount () {
      var sum = 0
      this.mapDetail.customers[0].forEach(function (item) {
        sum += item.total_measure
      })
      return sum
    },
    salesChartData () {
      return {
        labels: this.mapDetail.sales[0].map(item => item.sales_code),
        datasets: [
          {
            label: '業務貨量(' + this.salesMeasureCount + ')',
            backgroundColor: '#bbddff',
            data: this.mapDetail.sales[0].map(item => item.total_measure)
          }
        ]
      }
    },
    customerChartData () {
      return {
        labels: this.mapDetail.customers[0].map(item => item.customer_code),
        datasets: [
          {
            label: '客戶貨量(' + this.customerMeasureCount + ')',
            backgroundColor: '#bbffdd',
            data: this.mapDetail.customers[0].map(item => item.total_measure)
          }
        ]
      }
    },
    carrierChartData () {
      return {
        labels: this.mapDetail.carriers[0].map(item => item.carrier),
        datasets: [
          {
            label: '船公司貨量(' + this.carrierMeasureCount + ')',
            backgroundColor: '#ffbbdd',
            data: this.mapDetail.carriers[0].map(item => item.total_measure)
          }
        ]
      }
    }
  },
  components: {
    Bar,
    popup
  },
  methods: {
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'success-row'
      }
      return ''
    }
  },
  data () {
    return {
      chartOptions: {
        // scaleShowLabels: false,
        showTooltips: false,
        maintainAspectRatio: false,
        // height: this.mapDetail.sales[0].length * 50 + 100,
        responsive: true,
        indexAxis: 'y',
        scales: {
          x: {
            suggestedMin: 0,
            suggestedMax: 10
          },
          y: {
            ticks: {
              display: true
            }
          }
        }
      },
      carrierChartOptions: {
        // scaleShowLabels: false,
        maintainAspectRatio: false,
        height: this.mapDetail.carriers[0].length * 50 + 100,
        responsive: true,
        indexAxis: 'y',
        scales: {
          x: {
            suggestedMin: 0,
            suggestedMax: 10
          },
          y: {
            ticks: {
              display: true
            }
          }
        }
      },
      customerChartOptions: {
        // scaleShowLabels: false,
        maintainAspectRatio: false,
        height: this.mapDetail.customers[0].length * 50 + 100,
        // responsive: true,
        indexAxis: 'y',
        scales: {
          x: {
            suggestedMin: 0,
            suggestedMax: 10
          },
          y: {
            ticks: {
              display: true
            }
          }
        }
      }
    }
  },
  mounted () {
    console.log(this.mapDetail)
  }
}
</script>
<style lang="scss" module>
  .out {
    display: grid;
    grid-template-columns: 380px 380px 380px;
    grid-column-gap: 10px;
    margin-top: 30px;
    padding: 0 20px;
    box-sizing: border-box;
    height: 550px;
    overflow-y: auto;
  }
  .data1 {
    color: inherit;
    height: fit-content;
  }
  .data2 {
    color: inherit;
    height: fit-content;
  }
  .data3 {
    color: inherit;
    height: fit-content;
  }
  .outside {
    z-index: 8888;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inside {
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
  }
  .insideTable {
    height: 350px;
    background: #fff;
    box-sizing: border-box;
    overflow-y: auto;
  }
  .insideTable::-webkit-scrollbar {
    width: 10px;
  }
  .insideTable::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }
  .insideChart {
    margin-top: 10px;
    border-top: 1px solid #ccc;
    height: 350px;
    background: #fff;
    box-sizing: border-box;
  }
</style>
<style >
.el-table__header-wrapper .el-table th {
    padding: 6px 0;
}
</style>
