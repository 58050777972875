// eslint-disable-next-line
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { pouchReleaseSingle } from '@/api/pouch'
const spouch = {
  state: {
    spouchSearchInputList: [{
      label: '文件種類',
      input: 'DC',
      type: 'pouch',
      key: 'doc_type'
    }, {
      label: '船名Vessel',
      input: '',
      type: 'input',
      key: 'vsl_name'
    }, {
      label: '航次Voyage',
      input: '',
      type: 'input',
      key: 'voyage'
    }, {
      label: 'Agent Code',
      input: '',
      type: 'input',
      key: 'carrier_agent'
    }, {
      label: '國外帳單號碼',
      input: '',
      type: 'input',
      key: 'dc_no'
    }],
    spouchTableLabel: [{
      label: 'View',
      minWidth: 130,
      fixed: 'left',
      icon: true,
      prop: 'View',
      node: 'edit',
      url: 'pouchReceived',
      key: 'pre_book_no'
    }, {
      label: '船名Vessel',
      minWidth: 200,
      prop: 'vsl_name'
    }, {
      label: '航次Voyage',
      minWidth: 130,
      prop: 'voyage'
    }, {
      label: 'Agent Code',
      prop: 'carrier_agent'
    }, {
      label: '國外帳單號碼',
      minWidth: 180,
      prop: 'dc_no'
    }],
    spouchDetailBTableLabel: [{
      label: 'View',
      minWidth: 60,
      fixed: 'left',
      icon: true,
      prop: 'View',
      node: 'ViewPouchBDetail',
      url: 'pouchDetail',
      key: 'id'
    }, {
      label: '單據檔',
      minWidth: 130,
      fixed: 'left',
      link: true,
      prop: 'file_name',
      node: 'edit',
      url: 'quotation',
      key: 'pre_book_no',
      prop2: 'file_path'
    }, {
      label: '船名Vessel',
      minWidth: 100,
      prop: 'vsl_name'
    }, {
      label: '航次Voyage',
      minWidth: 80,
      prop: 'voyage'
    }, {
      label: 'Agent Code',
      prop: 'carrier_agent'
    }, {
      label: '國外帳單號碼',
      minWidth: 100,
      prop: 'dc_no'
    }, {
      label: 'MB/L No',
      minWidth: 100,
      prop: 'mbl_no'
    }],
    spouchDetailCTableLabel: [{
      label: 'View',
      minWidth: 60,
      fixed: 'left',
      icon: true,
      prop: 'View',
      node: 'ViewPouchBDetail',
      url: 'pouchDetail',
      key: 'id'
    }, {
      label: '單據檔',
      minWidth: 130,
      fixed: 'left',
      link: true,
      prop: 'file_name',
      node: 'edit',
      url: 'quotation',
      key: 'pre_book_no',
      prop2: 'file_path'
    }, {
      label: '船名Vessel',
      minWidth: 80,
      prop: 'vsl_name'
    }, {
      label: '航次Voyage',
      minWidth: 60,
      prop: 'voyage'
    }, {
      label: 'Agent Code',
      prop: 'carrier_agent'
    }, {
      label: '國外帳單號碼',
      minWidth: 100,
      prop: 'dc_no'
    }, {
      label: 'MB/L No',
      minWidth: 100,
      prop: 'mbl_no'
    }, {
      label: 'HB/L No',
      minWidth: 90,
      prop: 'o_bl_no'
    }],
    spouchDetailTableLabel: [{
      type: 'selection',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'version',
      prop: 'version',
      minWidth: 50
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'Edit',
      prop: 'Edit',
      icon: true,
      minWidth: 20,
      node: 'Edit',
      key: 'id'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    spouchDetailC1TableLabel: [{
      type: 'selection',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'version',
      prop: 'version',
      minWidth: 20
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: '上傳HBA',
      btn: true,
      prop: 'button',
      minWidth: 20,
      node: 'SOA'
    }, {
      label: 'MB/L No',
      minWidth: 50,
      prop: 'mbl_no'
    }, {
      label: 'HB/L No',
      minWidth: 50,
      prop: 'o_bl_no'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    spouchDetailC2TableLabel: [{
      type: 'selection',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'version',
      prop: 'version',
      minWidth: 20
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'MB/L No',
      minWidth: 50,
      prop: 'mbl_no'
    }, {
      label: 'HB/L No',
      minWidth: 50,
      prop: 'o_bl_no'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    spouchOrderid: '',
    spouchReleasePoint: ''
  },

  getters: {
    spouchReleasePoint: (state) => state.spouchReleasePoint,
    spouchTableLabel: (state) => state.spouchTableLabel,
    spouchOrderid: (state) => state.spouchOrderid,
    spouchDetailBTableLabel: (state) => state.spouchDetailBTableLabel,
    spouchDetailTableLabel: (state) => state.spouchDetailTableLabel,
    spouchDetailCTableLabel: (state) => state.spouchDetailCTableLabel,
    spouchDetailC1TableLabel: (state) => state.spouchDetailC1TableLabel,
    spouchDetailC2TableLabel: (state) => state.spouchDetailC2TableLabel,
    spouchMasterItem: (state) => state.spouchMasterItem,
    spouchSearchInputList: (state) => state.spouchSearchInputList
  },
  mutations: {
    spouchReleasePoint: (state, payload) => { state.spouchReleasePoint = payload },
    spouchTableLabel: (state, payload) => { state.spouchTableLabel = payload },
    spouchOrderid: (state, payload) => { state.spouchOrderid = payload },
    spouchDetailBTableLabel: (state, payload) => { state.spouchDetailBTableLabel = payload },
    spouchDetailTableLabel: (state, payload) => { state.spouchDetailTableLabel = payload },
    spouchDetailCTableLabel: (state, payload) => { state.spouchDetailCTableLabel = payload },
    spouchDetailC1TableLabel: (state, payload) => { state.spouchDetailC1TableLabel = payload },
    spouchDetailC2TableLabel: (state, payload) => { state.spouchDetailC2TableLabel = payload },
    spouchMasterItem: (state, payload) => { state.spouchMasterItem = payload }
  },
  actions: {
    spouchInputList: async (state) => {
      state.commit('searchInputList', state.getters.spouchSearchInputList)
    },
    spouchSearch: async (state, payload) => {
      state.commit('searchInputList', state.getters.spouchSearchInputList)
      state.commit('tableLabel', [])
      state.commit('orderDataInfo', [])
      state.commit('orderId', '')
      const body = { offset: 0, limit: 10 }
      body.doc_type = payload
      Object.values(state.getters.searchInputList).forEach(item => {
        // console.log('spouch search item', item)
        if ((item.type === 'input' || item.type === 'pouch') && item.input !== '') {
          body[item.key] = item.input
        }
      })
      // console.log('spouchSearch body', body)
      var result = await axios.post('api/pouch/list', body).then((response) => response)
      // console.log('spouchSearch result', result)

      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            // if (item) {
            //   item.file_name = item.file_name.length > 20 ? item.file_name.substr(0, 20) : item.file_name
            // }
            item.Edit = faEdit
            item.View = faEye
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          switch (payload) {
            case 'DC':
              state.getters.spouchTableLabel[0].path = 'spouchdc'
              // state.getters.spouchTableLabel[0].prop = 'QU_filename'
              // state.getters.spouchTableLabel[0].prop2 = 'QU_filepath'
              state.getters.spouchTableLabel[0].url = 'spouchdc2'
              state.getters.spouchTableLabel[1].path = 'spouchdc'
              state.getters.spouchTableLabel[1].showNode = 'none'
              state.commit('soTab', 0)
              break
            case 'MB':
              state.getters.spouchTableLabel[0].path = 'spouchmb'
              // state.getters.spouchTableLabel[0].prop = 'SA_filename'
              // state.getters.spouchTableLabel[0].prop2 = 'SA_filepath'
              state.getters.spouchTableLabel[0].url = 'spouchmb2'
              state.getters.spouchTableLabel[1].path = 'spouchmb'
              state.getters.spouchTableLabel[1].showNode = 'none'
              state.commit('soTab', 1)
              break
            case 'MA':
              state.getters.spouchTableLabel[0].path = 'spouchma'
              // state.getters.spouchTableLabel[0].prop = 'MD_filename'
              // state.getters.spouchTableLabel[0].prop2 = 'MD_filepath'
              state.getters.spouchTableLabel[0].url = 'spouchma2'
              state.getters.spouchTableLabel[1].path = 'spouchma'
              state.getters.spouchTableLabel[1].showNode = 'none'
              state.commit('soTab', 2)
              break
            case 'HB':
              state.getters.spouchTableLabel[0].path = 'spouchhb'
              // state.getters.spouchTableLabel[0].prop = 'IV_filename'
              // state.getters.spouchTableLabel[0].prop2 = 'IV_filepath'
              state.getters.spouchTableLabel[0].url = 'spouchhb2'
              state.getters.spouchTableLabel[1].path = 'spouchhb'
              state.getters.spouchTableLabel[1].showNode = 'none'
              state.commit('soTab', 3)
              // state.commit('soTab', 0)
              break
            case 'HBA':
              state.getters.spouchTableLabel[0].path = 'spouchhba'
              // state.getters.spouchTableLabel[0].prop = 'PL_filename'
              // state.getters.spouchTableLabel[0].prop2 = 'PL_filepath'
              state.getters.spouchTableLabel[0].url = 'spouchhba2'
              state.getters.spouchTableLabel[1].path = 'spouchhba'
              state.getters.spouchTableLabel[1].showNode = 'none'
              // state.commit('soTab', 1)
              break
            case 'IV':
              state.getters.spouchTableLabel[0].path = 'spouchiv'
              // state.getters.spouchTableLabel[0].prop = 'SO_filename'
              // state.getters.spouchTableLabel[0].prop2 = 'SO_filepath'
              state.getters.spouchTableLabel[0].url = 'spouchiv2'
              state.getters.spouchTableLabel[1].path = 'spouchiv'
              state.getters.spouchTableLabel[1].showNode = 'none'
              state.commit('soTab', 4)
              // state.commit('soTab', 2)
              break
            case 'PL':
              state.getters.spouchTableLabel[0].path = 'spouchpl'
              // state.getters.spouchTableLabel[0].prop = 'filename'
              // state.getters.spouchTableLabel[0].prop2 = 'HC_filepath'
              state.getters.spouchTableLabel[0].url = 'spouchpl2'
              state.getters.spouchTableLabel[1].path = 'spouchpl'
              state.getters.spouchTableLabel[1].showNode = 'none'
              state.commit('soTab', 5)
              break
          }
          state.commit('tableLabel', state.getters.spouchTableLabel)
          // await state.commit('orderDataInfo', orderDataInputList)
          // state.commit('tableLabel', state.getters.spouchDetailTableLabel)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          // await state.commit('orderDataInfo', [])
      }
      console.log('tableData', state.getters.tableData)
      console.log('spouchSearch result', result)
    },
    spouchDetailSearch: async (state, payload) => {
      state.commit('tableLabel', [])
      if (typeof payload.ViewPouchBDetail === 'undefined') {
        payload.ViewPouchBDetail = false
      }
      state.commit('routerParams', payload)
      state.commit('spouchReleasePoint', null)
      const body = {
        offset: 0,
        limit: 10,
        vsl_name: payload.vsl_name,
        voyage: payload.voyage,
        carrier_agent: payload.carrier_agent,
        dc_no: payload.dc_no
      }
      body.doc_type = payload.doc_type
      if (payload.pouchDetailType === 'B') {
        body.mbl_no = payload.mbl_no
      }
      if (payload.pouchDetailType === 'C') {
        body.o_bl_no = payload.o_bl_no
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      console.log('spouchDetailSearch body', body)
      var result = await axios.post('api/pouch/single', body).then((response) => response)
      console.log('spouchDetailSearch result', result)
      const pouchReleaseSingleRsp = await pouchReleaseSingle({
        vsl_name: payload.vsl_name,
        voyage: payload.voyage,
        carrier_agent: payload.carrier_agent,
        dc_no: payload.dc_no
      })
      console.log('pouchReleaseSingleRsp', pouchReleaseSingleRsp)
      var row = {}
      // if (pouchReleaseSingleRsp.data.data.ListData && pouchReleaseSingleRsp.data.data.ListData.length > 0) {
      if (result.data.data.ListData && result.data.data.ListData.length > 0) {
        row = result.data.data.ListData[0]
        state.commit('spouchReleasePoint', row.release_point_createon)
      } else {
        state.dispatch('EmptyInfoData')
      }
      const orderDataInputList = []
      if (!state.getters.editRowData.vsl_name) {
        state.commit('editRowData', payload)
      }
      Object.values(state.getters.spouchTableLabel).forEach(async (item, idx) => {
        if (idx > 0) {
          orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
        }
      })
      if (state.getters.editRowData.mbl_no) {
        orderDataInputList.push({ label: 'MB/L No', input: state.getters.editRowData.mbl_no, key: 'mbl_no' })
      }
      if (state.getters.editRowData.o_bl_no) {
        orderDataInputList.push({ label: 'HB/L NO', input: state.getters.editRowData.o_bl_no, key: 'o_bl_no' })
      }
      if (result.data.data.ListData && result.data.data.ListData.length > 0) {
        row = result.data.data.ListData[0]
        state.commit('infoData', row)
      } else {
        state.dispatch('EmptyInfoData')
      }
      // await state.commit('pdfUrl', result.data.data.ListData[0].file_path)
      switch (result.status) {
        case 200:
          if (result.data.data.length < 1) {
            state.commit('pageData', [])
            state.commit('tableData', [])
            return
          }
          var maxVersion = 0
          maxVersion = result.data.data.ListData.length > 0 ? result.data.data.ListData[0].version : 0
          await Object.values(result.data.data.ListData).forEach(item => {
            if (Number(item.version) === maxVersion) {
              item.max = true
            } else {
              item.max = false
            }
            item.Edit = faEdit
            item.View = faEye
            // item.messageid = state.getters.orderId
            item.button = true
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          if (state.getters.spouchLevel === 'B') {
            state.commit('tableLabel', state.getters.spouchDetailBTableLabel)
          } else if (state.getters.spouchLevel === 'C') {
            state.commit('tableLabel', state.getters.spouchDetailCTableLabel)
          } else {
            if (payload.pouchDetailType === 'C') {
              if (payload.doc_type === 'IV' || payload.doc_type === 'PL') {
                state.commit('tableLabel', state.getters.spouchDetailC2TableLabel)
              } else {
                state.commit('tableLabel', state.getters.spouchDetailC1TableLabel)
              }
            } else {
              state.commit('tableLabel', state.getters.spouchDetailTableLabel)
            }
          }
          // console.log('orderDataInfo', orderDataInputList)
          await state.commit('orderDataInfo', orderDataInputList)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
      console.log('tableData', state.getters.tableData)
    },
    spouchDetaileHandleCurrentChange: async (state, payload) => {
      var params = state.getters.routerParams
      const currentPage = payload.currentPage
      // var result = {}
      // const body = {
      //   offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
      state.commit('tableLabel', [])
      // {"offset": 0,"limit": 20,"vsl_name":"WANHAI161","voyage":"V-S329","carrier_agent":"HKG-LLG","dc_no":"KML-KEL-ES18110299"}
      // const body = { offset: 0, limit: 10 }
      if (typeof params.ViewPouchBDetail === 'undefined') {
        params.ViewPouchBDetail = false
      }
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10,
        vsl_name: params.vsl_name,
        voyage: params.voyage,
        carrier_agent: params.carrier_agent,
        dc_no: params.dc_no
      }
      body.doc_type = params.doc_type
      if (params.pouchDetailType === 'B') {
        body.mbl_no = params.mbl_no
      }
      if (params.pouchDetailType === 'C') {
        body.o_bl_no = params.o_bl_no
      }

      Object.values(state.getters.searchInputList).forEach(item => {
        // console.log('item', item)
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      console.log('spouchDetailSearch body', body)
      var result = await axios.post('api/pouch/single', body).then((response) => response)
      console.log('spouchDetailSearch result', result)
      const orderDataInputList = []
      Object.values(state.getters.spouchTableLabel).forEach(async (item, idx) => {
        if (idx > 1) {
          console.log('result.data.data.ListData[0]', result.data.data.ListData[0])
          orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
          // orderDataInputList.push({ label: item.label, input: result.data.data.ListData[0][item.prop], key: item.prop })
        }
      })
      // orderDataInputList.push({ label: 'MB/L No', input: result.data.data.ListData[0].mbl_no, key: 'mbl_no' })
      // orderDataInputList.push({ label: 'HB/L NO', input: result.data.data.ListData[0].o_bl_no, key: 'o_bl_no' })
      orderDataInputList.push({ label: 'MB/L No', input: state.getters.editRowData.mbl_no, key: 'mbl_no' })
      orderDataInputList.push({ label: 'HB/L NO', input: state.getters.editRowData.o_bl_no, key: 'o_bl_no' })
      if (result.data.data.ListData && result.data.data.ListData.length > 0) {
        var row = result.data.data.ListData[0]
        state.commit('infoData', row)
      } else {
        state.dispatch('EmptyInfoData')
      }
      // await state.commit('pdfUrl', result.data.data.ListData[0].file_path)
      switch (result.status) {
        case 200:
          if (result.data.data.length < 1) {
            state.commit('pageData', [])
            state.commit('tableData', [])
            return
          }
          var maxVersion = 0
          maxVersion = result.data.data.ListData.length > 0 ? result.data.data.ListData[0].version : 0
          console.log('maxVersion', maxVersion)
          await Object.values(result.data.data.ListData).forEach(item => {
            if (Number(item.version) === maxVersion) {
              item.max = true
            } else {
              item.max = false
            }
            item.Edit = faEdit
            item.View = faEye
            // item.messageid = state.getters.orderId
            item.button = true
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          if (state.getters.spouchLevel === 'B') {
            state.commit('tableLabel', state.getters.spouchDetailBTableLabel)
          } else if (state.getters.spouchLevel === 'C') {
            state.commit('tableLabel', state.getters.spouchDetailCTableLabel)
          } else {
            if (payload.pouchDetailType === 'C') {
              if (payload.doc_type === 'IV' || payload.doc_type === 'PL') {
                state.commit('tableLabel', state.getters.spouchDetailC2TableLabel)
              } else {
                state.commit('tableLabel', state.getters.spouchDetailC1TableLabel)
              }
            } else {
              state.commit('tableLabel', state.getters.spouchDetailTableLabel)
            }
          }
          await state.commit('orderDataInfo', orderDataInputList)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
      console.log('tableData', state.getters.tableData)
      console.log('spouchSearch result', result)
    },
    spouchHandleCurrentChange: async (state, payload) => {
      console.log('spouchHandleCurrentChange payload', payload)
      const currentPage = payload.currentPage
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10,
        doc_type: payload.doc_type
      }
      // if (JSON.stringify(state.getters.spouchQuery) !== '{}') {
      //   body.track_point = state.getters.spouchQuery.track_point
      // }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      result = await axios.post('api/pouch/list', body).then((response) => response)
      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            item.Edit = faEdit
            item.View = faEye
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          switch (payload) {
            case 'DC':
              state.getters.spouchTableLabel[0].path = 'spouchdc'
              // state.getters.spouchTableLabel[0].prop = 'QU_filename'
              // state.getters.spouchTableLabel[0].prop2 = 'QU_filepath'
              state.getters.spouchTableLabel[0].url = 'spouchdc2'
              state.getters.spouchTableLabel[1].path = 'spouchdc'
              state.getters.spouchTableLabel[1].showNode = 'none'
              state.commit('soTab', 0)
              break
            case 'MB':
              state.getters.spouchTableLabel[0].path = 'spouchmb'
              // state.getters.spouchTableLabel[0].prop = 'SA_filename'
              // state.getters.spouchTableLabel[0].prop2 = 'SA_filepath'
              state.getters.spouchTableLabel[0].url = 'spouchmb2'
              state.getters.spouchTableLabel[1].path = 'spouchmb'
              state.getters.spouchTableLabel[1].showNode = 'none'
              state.commit('soTab', 1)
              break
            case 'MA':
              state.getters.spouchTableLabel[0].path = 'spouchma'
              // state.getters.spouchTableLabel[0].prop = 'MD_filename'
              // state.getters.spouchTableLabel[0].prop2 = 'MD_filepath'
              state.getters.spouchTableLabel[0].url = 'spouchma2'
              state.getters.spouchTableLabel[1].path = 'spouchma'
              state.getters.spouchTableLabel[1].showNode = 'none'
              state.commit('soTab', 2)
              break
            case 'HB':
              state.getters.spouchTableLabel[0].path = 'spouchhb'
              // state.getters.spouchTableLabel[0].prop = 'IV_filename'
              // state.getters.spouchTableLabel[0].prop2 = 'IV_filepath'
              state.getters.spouchTableLabel[0].url = 'spouchhb2'
              state.getters.spouchTableLabel[1].path = 'spouchhb'
              state.getters.spouchTableLabel[1].showNode = 'none'
              state.commit('soTab', 3)
              // state.commit('soTab', 0)
              break
            case 'HBA':
              state.getters.spouchTableLabel[0].path = 'spouchhba'
              // state.getters.spouchTableLabel[0].prop = 'PL_filename'
              // state.getters.spouchTableLabel[0].prop2 = 'PL_filepath'
              state.getters.spouchTableLabel[0].url = 'spouchhba2'
              state.getters.spouchTableLabel[1].path = 'spouchhba'
              state.getters.spouchTableLabel[1].showNode = 'none'
              // state.commit('soTab', 1)
              break
            case 'IV':
              state.getters.spouchTableLabel[0].path = 'spouchiv'
              // state.getters.spouchTableLabel[0].prop = 'SO_filename'
              // state.getters.spouchTableLabel[0].prop2 = 'SO_filepath'
              state.getters.spouchTableLabel[0].url = 'spouchiv2'
              state.getters.spouchTableLabel[1].path = 'spouchiv'
              state.getters.spouchTableLabel[1].showNode = 'none'
              state.commit('soTab', 4)
              // state.commit('soTab', 2)
              break
            case 'PL':
              state.getters.spouchTableLabel[0].path = 'spouchpl'
              // state.getters.spouchTableLabel[0].prop = 'filename'
              // state.getters.spouchTableLabel[0].prop2 = 'HC_filepath'
              state.getters.spouchTableLabel[0].url = 'spouchpl2'
              state.getters.spouchTableLabel[1].path = 'spouchpl'
              state.getters.spouchTableLabel[1].showNode = 'none'
              state.commit('soTab', 5)
              break
          }
          state.commit('tableLabel', state.getters.spouchTableLabel)
          // await state.commit('orderDataInfo', orderDataInputList)
          // state.commit('tableLabel', state.getters.spouchDetailTableLabel)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          // await state.commit('orderDataInfo', [])
      }
      console.log('spouchHandleCurrentChange result', result)
      result.data.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.data.PageData.currentPage = currentPage
      state.commit('pageData', result.data.data.PageData)
      state.commit('tableData', result.data.data.ListData)
      // console.log('handleCurrentChange body', body)
      // console.log('handleCurrentChange result', result)
    },
    saveReleasePoint: async (state, payload) => {
      const pouchReleaseSingleRsp = await pouchReleaseSingle({
        vsl_name: state.getters.routerParams.vsl_name,
        voyage: state.getters.routerParams.voyage,
        carrier_agent: state.getters.routerParams.carrier_agent,
        dc_no: state.getters.routerParams.dc_no,
        release_point: payload.releasePoint,
        pre_book_no: payload.pre_book_no
      })
      // console.log(pouchReleaseSingleRsp)
      if (pouchReleaseSingleRsp.status === 200) {
        state.commit('spouchReleasePoint', payload.releasePoint)
        return true
      } else {
        return false
      }
    }
  }
}

export default spouch
