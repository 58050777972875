<template>
  <div :class="$style.root">
    <el-button
      :type="typeBtn ? typeBtn : 'info'"
      icon="el-icon-delete"
      :class="$style.btn"
      v-show="$route.name !== 'cargoMeasure'"
      v-if="hasDelbtn"
      @click="del"
    >
      {{ $t('Delete') }}
    </el-button>
    <el-button
      v-show="$route.name !== 'cargoMeasure'"
      v-if="hasAddbtn"
      type="primary"
      icon="el-icon-edit"
      :class="$style.btn"
      @click="add"
    >
      {{ $t('Add') }}
    </el-button>
    <updateComponent v-on="$listeners" v-if="hasPdfbtn"/>
  </div>
</template>

<script>
import updateComponent from '@/components/update.vue'
export default {
  mounted: function () {
    console.log('hasAddbtn', this.hasAddbtn, 'hasDelbtn', this.hasDelbtn, 'hasPdfbtn', this.hasPdfbtn)
  },
  components: {
    updateComponent
  },
  props: {
    typeBtn: null,
    hasAddbtn: null,
    hasDelbtn: null,
    hasPdfbtn: null
  },
  methods: {
    add () {
      console.log('this.$route.name', this.$route.name)
      switch (this.$route.name) {
        case ('customermanagement'):
        case ('suppliermanagement'):
        case ('userauthority'):
        case ('usermanagement'):
          this.$emit('add')
          break
      }
    },
    del () {
      switch (this.$route.name) {
        case ('customermanagement'):
        case ('suppliermanagement'):
          this.$emit('del')
          break
      }
    }
  }
}
</script>

<style lang="scss" module>
.btn {

  &:global(.el-button) {
    padding: 8px 12px;
    font-size: 12px;
  }
}

.root {
  display: flex;
}
</style>
