<template>
  <div :class="$style.root">
    <div :class="$style.bg" v-if="!flagForgetPassword">
      <p :class="$style.title">
        {{ $t('sentence.sentence_001') }}
      </p>
      <span v-if="message !== ''" :class="$style.danger" style="margin-bottom:15px">{{message}}</span>
      <inputComponent
        :class="[$style.inputComponent, { [$style.margin]: i === 2 }]"
        v-for="(item, i) in inputList"
        :key="i"
      >
        <template #title>
          {{ $t(item.label) }}
        </template>
        <template #input>
          <el-input
            v-if="item.label !== 'Password'"
            v-model="item.value"
            :class="$style.input"
            :placeholder="$t(item.label)"
          />
          <el-input
            v-else
            v-model="item.value"
            :class="$style.input"
            :placeholder="$t(item.label)"
            type="password"
          />
        </template>
      </inputComponent>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-button type="primary" @click="login">
            {{ $t('Login') }}
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="info" @click="switchForgetPassword">
            {{ $t('ForgetPassword') }}?
          </el-button>
        </el-col>
      </el-row>
    </div>
    <div :class="$style.bg" v-else>
      <p :class="$style.title">
        {{ $t('sentence.sentence_001') }}
      </p>
      <span v-if="message !== ''" :class="$style.danger">{{message}}</span>
      <el-row :gutter="20" :class="$style.blockForgetPassword">
        <el-col :span="24">
          <el-input v-model="userEmail" placeholder="請輸入Email"></el-input>
        <br>
        <br>
        </el-col>
        <el-col :span="12">
          <el-button type="warning" @click="forgetPassword">
            重設密碼
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="info" @click="switchForgetPassword(false)">
            返回
          </el-button>
        </el-col>
      </el-row>
    </div>
     <!--<div :class="$style.footer">版權所有：© 艾旺科技(股)公司<br>
 I-ONE Information Tech. Co., Ltd.  All Rights Reserved.</div>-->
  </div>
</template>

<script>
import inputComponent from '@/components/card/input.vue'
// import { userRolos } from '@/api/system'
// import { refresh } from '@/api/login'
export default {
  components: {
    inputComponent
  },
  data () {
    return {
      flagForgetPassword: false,
      testStr: 'test',
      userEmail: '',
      langSelect: 'tw',
      message: '',
      options: [{
        value: 'tw',
        label: '繁體中文'
      }, {
        value: 'en',
        label: 'English'
      }],
      inputList: [{
        label: 'Account',
        value: '',
        placeholder: '帳號'
      }, {
        label: 'Password',
        value: '',
        placeholder: '密碼'
      }]
    }
  },
  watch: {
    langSelect () {
      console.log('login', this.langSelect)
      this.$i18n.locale = this.langSelect
      window.localStorage.setItem('lang', this.langSelect)
    }
  },
  created () {
    this.$i18n.locale = 'tw'
    // console.log(JSON.parse(sessionStorage.getItem('referrer')))
  },
  methods: {
    async login () {
      const referrer = JSON.parse(sessionStorage.getItem('referrer'))
      console.log('referrer', referrer)
      const data = await this.$store.dispatch('Login', {
        id: this.inputList[0].value,
        password: this.inputList[1].value
      })
      console.log('login data', data.data)
      if (data.code === '200') {
        localStorage.setItem('accessToken', data.data.accessToken)
        localStorage.setItem('userid', data.data.userid)
        localStorage.setItem('name', data.data.name)
        localStorage.setItem('basic_code', data.data.basic_code)
        console.log(data.data.roleData[0].basic_type)
        localStorage.setItem('basic_type', data.data.roleData[0].basic_type)
        this.$store.dispatch('userData', data.data)
        if (referrer) {
          this.$router.push({
            name: referrer.name,
            params: referrer.params
          })
        } else {
          this.$router.push('/home')
        }
      } else {
        this.message = data.data.detail
      }
    },
    validateEmail () {
      return String(this.userEmail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    async forgetPassword () {
      if (!this.validateEmail()) {
        this.message = 'Email 格式錯誤'
        return false
      }
      const data = await this.$store.dispatch('ForgetPassword', { email: this.userEmail })
      if (data.code) {
        this.message = '如果Email存在,重設密碼連結已寄到該信箱'
      } else {
        this.message = '系統錯誤'
      }
    },
    switchForgetPassword (stat = true) {
      this.flagForgetPassword = stat
      this.message = ''
    }
  }
}
</script>

<style lang="scss" module>
.root {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  background: url('/KF_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.selectout {
  align-self: flex-end;
}

.bg {
  width: 350px;
  height: 360px;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 8%;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: #68686a;
  margin: 30px 0 31px 0;
}

.input {
  :global(.el-input__inner) {
    height: 30px;
  }
}

.inputComponent {
  width: 250px;
  margin-bottom: 12px;

  &.margin {
    margin-bottom: 30px;
  }
}
.blockForgetPassword{
  width:80%;
  align-items: center;
  text-align: center;
}
.footer {
  position: relative;
  bottom: 10%;
  font-size: 13pt;
  width: 100%;
  align-items: center;
  text-align: center;
}
.danger {
  background-color: #F56C6C;
  color: #ffffff;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
}
</style>
