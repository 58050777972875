<template>
  <popup :class="$style.outside" @close="$emit('close')">
    <template #title>
      {{ $t('Neworder') }}
    </template>
    <template #content>
      <div :class="$style.out">
        <inputStyle
          v-for="(item, i) in addInputlist"
          :key="i"
          :fixWidth="130"
        >
          <template #title>
            {{ $t(item.label) }}
          </template>
          <template #data>
            <input :class="$style.input">
          </template>
          <template #msg>
             {{ item.msg }}
          </template>
        </inputStyle>
      </div>
      <el-button
        type="primary"
        :class="$style.btn"
        @click="addData"
      >
        {{ $t('Add') }}
      </el-button>
    </template>
  </popup>
</template>

<script>
import popup from '@/components/card/popup.vue'
import inputStyle from '@/components/card/datainput.vue'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['addInputlist'])
  },
  components: {
    popup,
    inputStyle
  },
  data () {
    return {}
  },
  methods: {
    addData () {
      this.$store.dispatch('addDatahandler')
    }
  }
}
</script>

<style lang="scss" module>
.msg {
  font-size: 12px;
  font-weight: 500;
  color: #ff0000;
  margin-bottom: 5px;
}

.input {
  border: none;
  width: 100%;
  background: none;
  color: #7e7e7e;
}

.out {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 16px;
  margin-top: 30px;
  padding: 0 20px;
  box-sizing: border-box;
}

.btn {
  align-self: center;

  &:global(.el-button) {
    margin: 25px 0px;
  }
}

.outside {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
