import axios from 'axios'

export const deliveryPurchaseNo = async (body) => await axios.post('api/delivery/purchase_no', body).then((response) => response)// 查詢訂單編號

export const deliveryMasterList = async (body) => await axios.post('api/delivery/master/list', body).then((response) => response)// 報價單列表

export const deliveryMasterSingle = async (body) => await axios.post('api/delivery/master/single', body).then((response) => response.data)// 報價單單一資訊

export const deliveryMasterCreate = async (body) => await axios.post('api/delivery/master/create', body).then((response) => response)// 報價單新增

export const deliveryMasterUpdate = async (body) => await axios.put('api/delivery/master/update', body).then((response) => response.data)// 報價單編輯

export const deliveryMasterDelete = async (body) => await axios.delete('api/delivery/master/delete', { data: body }).then((response) => response)// 報價單刪除

export const deliveryDetailList = async (body) => await axios.post('api/delivery/detail/list', body).then((response) => response.data)// 報價單詳細列表

export const deliveryDetailSingle = async (body) => await axios.post('api/delivery/detail/single', body).then((response) => response.data)// 報價單詳細單一資訊

export const deliveryDetailCreate = async (body) => await axios.post('api/delivery/detail/create', body).then((response) => response.data)// 報價單詳細內容新增

export const deliveryDetailUpdate = async (body) => await axios.put('api/delivery/detail/update', body).then((response) => response.data)// 報價單詳細內容編輯

export const deliveryDetailDelete = async (body) => await axios.delete('api/delivery/detail/delete', body).then((response) => response.data)// 報價單詳細內容刪除

export const deliveryConfirmedSingle = async (body) => await axios.post('api/delivery_confirmed/single', body).then((response) => response)

export const deliveryConfirmedList = async (body) => await axios.post('api/delivery_confirmed/list', body).then((response) => response)

export const deliveryUpdateReferenceNo = async (body) => await axios.post('api/purchase/action', body).then((response) => response.data)// 報價單詳細內容編輯
