<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="$style.left">
        <div :class="$style.border">JobNo</div>
        <div :class="$style.border">船名</div>
        <div :class="$style.border">航次</div>
        <div :class="$style.border">SONo</div>
        <div :class="$style.border">HB/LNo</div>
        <div :class="$style.border">客戶代碼</div>
        <div :class="$style.border">結關日</div>
        <div :class="$style.border">裝船日</div>
        <div :class="$style.border">收貨港</div>
        <div :class="$style.border">卸貨港</div>
        <div :class="$style.border">裝貨港</div>
        <div :class="$style.border">目的地</div>
        <div :class="$style.border">船期編號</div>
        <div :class="$style.border">業務代碼</div>
        <div :class="$style.border">OP代碼</div>
        <div :class="$style.border">CFS交貨處</div>
        <div :class="$style.border">報關行代碼</div>
        <div :class="$style.border">貨運行代碼</div>
        <div :class="$style.border">船公司代碼</div>
        <div :class="$style.border">國外代理商代碼</div>
        <div :class="$style.border">預計起航日</div>
        <div :class="$style.border">預計抵達日</div>
      </div>
      <div :class="$style.right">
        <input
          :class="$style.input"
          :value="editRowData.pre_book_no"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.vsl_name"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.voyage"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.so_no"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.o_bl_no"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.customer_code"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.closing_date"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.onboard_date"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.receipt_place"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.port_discharge"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.port_load"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.delivery_place"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.ship_num"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.sales_code"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.entry_user_code"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.cfs_delivery_place"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.broker_code"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.truck_company"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.carrier"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.self_pod_agent"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.pre_depart_date"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.pre_arr_date"
          :disabled="true"
        />
      </div>
      <div :class="$style.left">
        <div :class="$style.border">運輸條款_1</div>
        <div :class="$style.border">運輸條款_2</div>
        <div :class="$style.border">CY貨量</div>
        <div :class="$style.border">CY領櫃處</div>
        <div :class="$style.border">CY領櫃代碼</div>
        <div :class="$style.border">CY交櫃處</div>
        <div :class="$style.border">CY交櫃號碼</div>
        <div :class="$style.border">CFS貨量</div>
        <div :class="$style.border">出貨型態</div>
        <div :class="$style.border">同行代碼</div>
        <div :class="$style.border">業務型態</div>
        <div :class="$style.border">航線別</div>
        <div :class="$style.border">總件數</div>
        <div :class="$style.border">件數單位</div>
        <div :class="$style.border">總重量</div>
        <div :class="$style.border">重量單位</div>
        <div :class="$style.border">總材積</div>
        <div :class="$style.border">材積單位</div>
        <div :class="$style.border">20’貨櫃數量</div>
        <div :class="$style.border">40’貨櫃數量</div>
        <div :class="$style.border">SPEC貨櫃數量</div>
        <div :class="$style.border">MB/LNo</div>
      </div>
      <div :class="$style.right">
        <input :class="$style.input" :value="editRowData.loading_term" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.discharge_term" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.cy_number_desc" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.cy_receipt_place" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.cy_receipt_code" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.cy_delivery_place" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.cy_delivery_code" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.cfs_number_desc" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.business_type" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.coload_agent" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.job_type" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.line" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.ttl_package_no" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.ttl_package_uint" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.cargo_gross_wt" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.weight_unit" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.cargo_measure" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.measure_unit" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.ttl_20_van" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.ttl_40_van" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.ttl_other_van" :disabled="true"/>
        <input :class="$style.input" :value="editRowData.mbl_no" :disabled="true"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['editRowData'])
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" module>
.root {
  align-self: center;
  width: 95%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: #fff;
  margin: 20px 0;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.rootinner {
  width: 100%;
  display: grid;
  grid-template-columns: 150px 1fr 150px 1fr;
  grid-auto-rows: auto;
  border: 1px solid #eaeaea;
}

.border {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
  color: #6d6b6b;
  box-sizing: border-box;
  border-bottom: 1px solid #eaeaea;

  &.borderconter {
    justify-content: center;
  }
}

.left {
  display: grid;
  grid-auto-rows: 35px;
}

.right {
  display: grid;
  border-left: 1px solid #eaeaea;
  grid-auto-rows: 35px;
  border-right: 1px solid #eaeaea;
}

.bottom {
  grid-area: bottom;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-bottom: 1px solid #eaeaea;
}

.input {
  background: #fff;
  border: none;
  border-bottom: 1px solid #eaeaea;
  box-shadow: none;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  color: #6d6b6b;

  &.inputborder {
    border-right: 1px solid #eaeaea;
  }
}

.title {
  display: grid;
  grid-template-columns: repeat(7, 1fr) 120px;
  height: 35px;
}

.btn {
  align-self: flex-end;
  margin: 20px 10px;
  font-size: 12px;
  color: #6d6b6b;
  cursor: pointer;
}

.bottomtitle {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 35px 35px;
  margin: 10px;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.text {
  width: 100px;
}

.textarea {
  border: 1px solid #eaeaea;
  width: 100%;
  height: 100px;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
  color: #6d6b6b;
}

.title {
  font-size: 20px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: #6d6b6b;
}
</style>
