import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
import Home from '@/views/home/index.vue'
import PrivateKey from '@/views/privateKey/index.vue'
import ChangePassword from '@/views/systemfile/changePassword.vue'
import List from '@/router/list/index.js'
import Spouch from '@/router/spouch/index.js'
import Login from '@/login.vue'
import ResetPassword from '@/views/resetPassword.vue'
// import { refresh } from '@/api/login'
// import { checkToken } from '@/api/system'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: Login,
    name: 'login'
  },
  {
    path: '/reset_password/:uuid/',
    component: ResetPassword,
    name: 'resetPassword'
  },
  {
    path: '/',
    component: Layout,
    redirect: { name: 'home' },
    children: [
      {
        path: 'home',
        name: 'home',
        component: Home,
        meta: { title: 'Home', class: 'home' }
      },
      {
        path: 'privateKey',
        name: 'privateKey',
        component: PrivateKey,
        meta: { title: '私鑰', class: 'privateKey' }
      },
      {
        path: 'changePassword',
        name: 'changePassword',
        component: ChangePassword,
        meta: { title: '變更密碼', class: 'changePassword' }
      },
      ...List,
      ...Spouch
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const isLogin = localStorage.getItem('accessToken') !== null && localStorage.getItem('userid')
  if (isLogin) {
    if (Object.keys(router.app.$options.store.getters.pageData).length > 0) {
      router.app.$options.store.commit('currentPagePath', to.name)
    }
    router.app.$options.store.dispatch('preProcessinghandler', to)
    sessionStorage.removeItem('referrer')
    next()
  } else {
    if (to.path !== '/login' && to.name !== 'resetPassword') {
      sessionStorage.setItem('referrer', JSON.stringify(to))
      next('/login')
    } else {
      next()
    }
  }
})

export default router
