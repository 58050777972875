
// eslint-disable-next-line
import { measureCertificateSingle, measureCertificateList } from '@/api/measureCertificate'
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
const measureCertificate = {
  state: {
    measureCertificateAddInputlist: [{
      label: 'Job No',
      input: 'JobNo31',
      key: 'pre_book_no',
      msg: ''
    }, {
      label: '船名Vessel',
      input: 'vslName',
      key: 'vsl_name',
      msg: ''
    }, {
      label: '航次Voyage',
      input: 'Voyage',
      key: 'voyage',
      msg: ''
    }, {
      label: 'SO No',
      input: 'soNo',
      key: 'so_no',
      msg: ''
    }, {
      label: 'HB/L No',
      input: 'soNo',
      key: 'o_bl_no',
      msg: ''
    }, {
      label: '客戶名稱',
      input: 'customerCode',
      key: 'customer_code',
      msg: ''
    }, {
      label: '結關日期',
      input: 'closingDate',
      key: 'closing_date',
      msg: ''
    }, {
      label: '裝船日期',
      input: 'onboardDate',
      key: 'onboard_date',
      msg: ''
    }, {
      label: '收貨港PLR',
      input: 'PLR',
      key: 'receipt_place',
      msg: ''
    }, {
      label: '卸貨港POD',
      input: 'POD',
      key: 'port_discharge',
      msg: ''
    }, {
      label: 'POL',
      input: 'POL',
      key: 'port_load',
      msg: ''
    }, {
      label: 'PLD',
      input: 'PLD',
      key: 'delivery_place',
      msg: ''
    }, {
      label: 'VoyID',
      input: 'VoyID',
      key: 'ship_num',
      msg: ''
    }, {
      label: 'salesCode',
      input: 'salesCode',
      key: 'sales_code',
      msg: ''
    }, {
      label: 'entryUserCode',
      input: 'test',
      key: 'entry_user_code',
      msg: ''
    }, {
      label: 'cfsDeliveryPlace',
      input: 'cfsDeliveryPlace',
      key: 'cfs_delivery_place',
      msg: ''
    }, {
      label: 'brokerCode',
      input: 'brokerCode',
      key: 'broker_code',
      msg: ''
    }, {
      label: 'truckCompany',
      input: 'truckCompany',
      key: 'truck_company',
      msg: ''
    }, {
      label: 'carrier',
      input: 'carrier',
      key: 'carrier',
      msg: ''
    }, {
      label: 'selfPodAgent',
      input: 'selfPodAgent',
      key: 'self_pod_agent',
      msg: ''
    }, {
      label: 'ediType',
      input: 'aa',
      key: 'edi_type',
      msg: ''
    }, {
      label: 'transferBy',
      input: 'transferBy',
      key: 'transfer_by',
      msg: ''
    }, {
      label: 'transfer_dt',
      input: '',
      key: 'transferDt',
      msg: ''
    }],
    measureCertificateTableLabel: [{
      label: 'Job No',
      minWidth: 130,
      fixed: 'left',
      link: true,
      prop: 'pre_book_no',
      node: 'edit',
      url: 'measureCertificate'
    }, {
      label: '船名Vessel',
      minWidth: 200,
      prop: 'vsl_name'
    }, {
      label: '航次Voyage',
      minWidth: 130,
      prop: 'voyage'
    }, {
      label: 'SO No',
      prop: 'so_no'
    }, {
      label: 'HB/L No',
      minWidth: 180,
      prop: 'o_bl_no'
    }, {
      label: '客戶中文名稱',
      minWidth: 200,
      prop: 'customer_code'
    }, {
      label: '結關日期',
      minWidth: 120,
      prop: 'closing_date'
    }, {
      label: '裝船日期',
      minWidth: 120,
      prop: 'onboard_date'
    }, {
      label: '收貨港PLR',
      minWidth: 150,
      prop: 'receipt_place'
    }, {
      label: '卸貨港POD',
      minWidth: 150,
      prop: 'port_discharge'
    }],
    measureCertificateDetailTableLabel: [{
      label: 'version',
      prop: 'version',
      minWidth: 50
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    measureCertificateOrderid: ''
  },

  getters: {
    measureCertificateAddInputlist: (state) => state.measureCertificateAddInputlist,
    measureCertificateTableLabel: (state) => state.measureCertificateTableLabel,
    measureCertificateOrderid: (state) => state.measureCertificateOrderid,
    measureCertificateDetailTableLabel: (state) => state.measureCertificateDetailTableLabel,
    measureCertificateMasterItem: (state) => state.measureCertificateMasterItem
  },

  mutations: {
    measureCertificateAddInputlist: (state, payload) => { state.measureCertificateAddInputlist = payload },
    measureCertificateTableLabel: (state, payload) => { state.measureCertificateTableLabel = payload },
    measureCertificateOrderid: (state, payload) => { state.measureCertificateOrderid = payload },
    measureCertificateDetailTableLabel: (state, payload) => { state.measureCertificateDetailTableLabel = payload },
    measureCertificateMasterItem: (state, payload) => { state.measureCertificateMasterItem = payload }
  },
  actions: {
    setmeasureCertificateInputList: async (state) => {
      state.commit('searchInputList', state.getters.measureCertificateSearchInputList)
      state.commit('', state.getters.measureCertificateAddInputlist)
      state.commit('tableLabel', state.getters.measureCertificateTableLabel)
    },
    measureCertificateSearch: async (state, payload) => {
      let body = {}
      if (payload === null && JSON.stringify(state.getters.measureCertificateQuery) === '{}') {
        body.offset = 0
        body.limit = 10
      } else {
        if (payload !== null) {
          body.pre_book_no = payload.orderId
        } else {
          body = state.getters.measureCertificateQuery
        }
      }
      if (payload.version !== undefined) {
        body.version = payload.version
      }
      if (body === undefined) {
        body = {}
      }
      console.log('beforEditByShipment body', body)
      // Object.values(state.getters.searchInputList).forEach(item => {
      //   if (item.type === 'input' && item.input !== '') {
      //     body[item.key] = item.input
      //   }
      // })
      console.log('measureCertificateSearch body', body)
      var result = await measureCertificateSingle(body)
      state.dispatch('singleFileInfoData', { rows: result.data, pre_book_no: '', pdf_serial: 0 })
      const orderDataInputList = []
      Object.values(state.getters.measureCertificateTableLabel).forEach(async (item, idx) => {
        if (idx > 1) {
          orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
        }
      })
      state.dispatch('getPdfEmailList', 'MD')
      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            item.Edit = faEdit
            item.View = faEye
            item.pre_book_no = state.getters.orderId
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          state.commit('tableLabel', state.getters.measureCertificateDetailTableLabel)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
      }
      console.log('tableData', state.getters.tableData)
      console.log('measureCertificateSearch result', result)
    },
    beforEditBymeasureCertificate: async (state, payload) => {
      var result = await measureCertificateSingle({ pre_book_no: payload.orderId })
      console.log('beforEditBymeasureCertificate', result)
      state.commit('measureCertificateMasterItem', result.data.data.ListData[0])
    },
    measureCertificateHandleCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      if (JSON.stringify(state.getters.measureCertificateQuery) !== '{}') {
        body.track_point = state.getters.measureCertificateQuery.track_point
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      result = await measureCertificateList(body)
      result.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.PageData.currentPage = currentPage
      state.commit('pageData', result.data.PageData)
      state.commit('tableData', result.data.ListData)
      // console.log('handleCurrentChange body', body)
      // console.log('handleCurrentChange result', result)
    }
  }
}

export default measureCertificate
