// eslint-disable-next-line
import { mblsiSingle, mblsiList } from '@/api/mblsi'
// eslint-disable-next-line
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
const mblsi = {
  state: {
    mblsiSearchInputList: [{
      label: '船名Vessel',
      input: '',
      type: 'input',
      key: 'vsl_name'
    }, {
      label: '航次Voyage',
      input: '',
      type: 'input',
      key: 'voyage'
    }, {
      label: 'Agent Code',
      input: '',
      type: 'input',
      key: 'carrier_agent'
    }, {
      label: 'SO No',
      input: '',
      type: 'input',
      key: 'so_no'
    }],
    mblsiTableLabel: [{
      label: 'View',
      minWidth: 130,
      fixed: 'left',
      icon: true,
      prop: 'View',
      node: 'edit',
      url: 'mblsi'
    }, {
      label: '船名Vessel',
      minWidth: 200,
      prop: 'vsl_name'
    }, {
      label: '航次Voyage',
      minWidth: 130,
      prop: 'voyage'
    }, {
      label: 'Agent Code',
      prop: 'carrier_agent'
    }, {
      label: 'SO No',
      minWidth: 100,
      prop: 'so_no'
    }, {
      label: 'Carrier',
      minWidth: 180,
      prop: 'carrier'
    }],
    mblsiDetailTableLabel: [{
      label: 'version',
      prop: 'version',
      minWidth: 50
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: '上傳SIA',
      btn: true,
      prop: 'button',
      minWidth: 20
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    mblsiOrderid: ''
  },

  getters: {
    mblsiTableLabel: (state) => state.mblsiTableLabel,
    mblsiOrderid: (state) => state.mblsiOrderid,
    mblsiDetailTableLabel: (state) => state.mblsiDetailTableLabel,
    mblsiMasterItem: (state) => state.mblsiMasterItem,
    mblsiSearchInputList: (state) => state.mblsiSearchInputList
  },

  mutations: {
    mblsiTableLabel: (state, payload) => { state.mblsiTableLabel = payload },
    mblsiOrderid: (state, payload) => { state.mblsiOrderid = payload },
    mblsiDetailTableLabel: (state, payload) => { state.mblsiDetailTableLabel = payload },
    mblsiMasterItem: (state, payload) => { state.mblsiMasterItem = payload }
  },
  actions: {
    mblsiInputList: async (state) => {
      state.commit('searchInputList', state.getters.mblsiSearchInputList)
    },
    mblsiSearch: async (state, payload) => {
      state.commit('tableLabel', [])
      // console.log('mblsiSearch', payload)
      const body = { offset: 0, limit: 10 }
      Object.values(state.getters.searchInputList).forEach(item => {
        // console.log('item', item)
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      console.log('mblsiSearch body', body)
      var result = await mblsiList(body)
      console.log('mblsiSearch result', result)
      // const orderDataInputList = []
      // Object.values(state.getters.mblsiTableLabel).forEach(async (item, idx) => {
      //   if (idx > 1) {
      //     orderDataInputList.push({ label: item.label, input: result.data.data.ListData[2][item.prop], key: item.prop })
      //   }
      // })

      // await state.commit('pdfUrl', result.data.data.ListData[0].file_path.si)
      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            // item.Edit = faEdit
            item.View = faEye
            // item.pre_book_no = state.getters.orderId
            item.file_name = item.file_name.si
            state.getters.mblsiTableLabel[1].path = 'mblsi'
            state.getters.mblsiTableLabel[1].prop2 = 'file_path'
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          state.commit('tableLabel', state.getters.mblsiTableLabel)
          // await state.commit('orderDataInfo', orderDataInputList)
          // state.commit('tableLabel', state.getters.mblsiDetailTableLabel)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
    },
    mblsiDetailSearch: async (state, payload) => {
      state.commit('tableLabel', [])
      console.log('mblsiSearch123', payload)
      const body = { offset: 0, limit: 10, vsl_name: payload.vsl_name, voyage: payload.voyage, carrier_agent: payload.carrier_agent, so_no: payload.so_no }
      // const body = { offset: 0, limit: 10, ship_num: payload.ship_num}
      Object.values(state.getters.searchInputList).forEach(item => {
        // console.log('item', item)
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      console.log('mblsiSearch body', body)
      var result = await mblsiSingle(body)
      console.log('mblsiDetailSearch result', result)
      const orderDataInputList = []
      Object.values(state.getters.mblsiTableLabel).forEach(async (item, idx) => {
        if (idx > 0) {
          // orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
          orderDataInputList.push({ label: item.label, input: result.data.data.ListData[0][item.prop], key: item.prop })
        }
      })
      // await state.commit('pdfUrl', result.data.data.ListData[0].file_path)
      switch (result.status) {
        case 200:
          var maxVersion = result.data.data.ListData.length > 0 ? result.data.data.ListData[0].version : 0 // Math.max(...result.data.data.ListData.map(item => item.version))
          // console.log('maxVersion', maxVersion)
          await Object.values(result.data.data.ListData).forEach(item => {
            if (Number(item.version) === maxVersion) {
              item.max = true
            } else {
              item.max = false
            }
            item.Edit = faEdit
            item.View = faEye
            // item.messageid = state.getters.orderId
            item.button = true
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          state.commit('tableLabel', state.getters.mblsiDetailTableLabel)
          if (result.data.data.ListData.length > 0) {
            state.commit('infoData', result.data.data.ListData[0])
          }
          await state.commit('orderDataInfo', orderDataInputList)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
    },
    beforEditBymblsi: async (state, payload) => {
      var result = await mblsiSingle({ pre_book_no: payload.orderId })
      console.log('beforEditBymblsi', result)
      state.commit('mblsiMasterItem', result.data.data.ListData[0])
    },
    mblsiHandleCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      if (JSON.stringify(state.getters.mblsiQuery) !== '{}' && state.getters.mblsiQuery !== undefined) {
        body.track_point = state.getters.mblsiQuery.track_point
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      result = await mblsiList(body)
      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            // item.Edit = faEdit
            item.View = faEye
            // item.pre_book_no = state.getters.orderId
            item.file_name = item.file_name.si
            state.getters.mblsiTableLabel[1].path = 'mblsi'
            state.getters.mblsiTableLabel[1].prop2 = 'file_path'
          })
          result.data.data.PageData.totalPage = state.getters.pageData.totalPage
          result.data.data.PageData.currentPage = currentPage
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          // await state.commit('orderDataInfo', orderDataInputList)
          // state.commit('tableLabel', state.getters.mblsiDetailTableLabel)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
      // console.log('handleCurrentChange body', body)
      // console.log('handleCurrentChange result', result)
    }
  }
}

export default mblsi
