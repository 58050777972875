<template>
  <popup :class="$style.outside" @close="$emit('close')">
    <template #title>
      {{ titleBtnText }}
    </template>
    <template #content>
      <div :class="$style.out">
        <inputStyle
          v-for="(item, i) in inputlist"
          :key="i"
          :fixWidth="130"
          :class="$style.heightfix"
        >
          <template #title>
            {{ item.label }}
          </template>
          <template #data>
            <input :class="$style.input" v-if="item.type === 'input'" v-model="item.input">
            <el-select
              :class="$style.select"
              v-model="item.input"
              placeholder="請選擇"
              v-else
            >
              <el-option
                v-for="(itemOption, i) in $route.name === 'customermanagement' ? item.clientOptions : item.businessOptions"
                :key="i"
                :label="itemOption.label"
                :value="itemOption.val"
              />
            </el-select>
            <p v-if="item.msg !== ''">{{ item.msg }}</p>
          </template>
        </inputStyle>
      </div>
      <el-button
        type="primary"
        :class="$style.btn"
        @click="mod"
      >
        {{ titleBtnText }}
      </el-button>
    </template>
  </popup>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import popup from '@/components/card/popup.vue'
import inputStyle from '@/components/card/datainput.vue'
export default {
  mounted: function () {
  },
  computed: {
    ...mapGetters(['addInputlist', 'customerResult']),
    inputlist () {
      console.log('action', this.action)
      if (this.action === 'mod') {
        Object.values(this.addInputlist).forEach(item => {
          item.input = this.rowData[item.key]
        })
        console.log('datapopup mounted rowData', this.rowData)
      } else {
        Object.values(this.addInputlist).forEach(item => {
          item.input = ''
        })
      }
      return this.addInputlist
    }

  },
  props: {
    action: String,
    titleBtnText: String,
    rowData: Object
  },
  components: {
    popup,
    inputStyle
  },
  data () {
    return {}
  },
  methods: {
    ...mapActions(['modAddInputlist', 'addDatahandler']),
    async mod () {
      if (this.action === 'mod') {
        console.log('datapopup mod addInputlist', this.addInputlist)
        this.$store.commit('addInputlist', this.addInputlist)
        await this.modAddInputlist()
      } else {
        await this.addDatahandler()
      }
      console.log('this.customerResult.status', this.customerResult.status)
      switch (this.customerResult.status) {
        case 200:
        case 201:
          this.$message({
            type: 'success',
            message: '成功'
          })
          Object.values(this.addInputlist).forEach(item => {
            item.msg = ''
          })
          break
        case 400:
          Object.keys(this.customerResult.data).forEach(key => {
            Object.values(this.addInputlist).forEach(item => {
              if (item.key === key) {
                item.msg = this.customerResult.data[key][0]
              }
            })
          })
          this.$message({
            type: 'info',
            message: '請檢查欄位'
          })
          break
        default:
          this.$message({
            type: 'info',
            message: '失敗'
          })
      }
    }
  }
}
</script>

<style lang="scss" module>
.input {
  border: none;
  width: 100%;
  background: none;
  color: #7e7e7e;
}

.out {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 16px;
  margin-top: 30px;
  padding: 0 20px;
  box-sizing: border-box;
}

.btn {
  align-self: center;

  &:global(.el-button) {
    margin: 25px 0px;
  }
}

.outside {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select {
  :global(.el-select) {
    width: 100%;
  }

  :global(.el-input__inner) {
    height: 30px;
  }

  :global(.el-input__icon) {
    line-height: 30px;
  }
}

.heightfix {
  height: 30px;
}
</style>
