<template>
  <div :class="$style.root">
    <div v-if="$route.params.titleA">
        {{ $route.params.titleA }}
    </div>
    <div v-else>{{ $t($route.meta.title) }}</div>
    <div :class="$style.img">
      <el-avatar icon="el-icon-user-solid" :size="30" :class="$style.icon"/>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            @click.native="routerFn('changePassword')"
          >
            {{ $t('ChangePassword') }}
          </el-dropdown-item>
          <el-dropdown-item
            divided
            :class="$style.out"
            @click.native="routerFn('privateKey')"
          >
            {{ $t('PrivateKey') }}
          </el-dropdown-item>
          <el-dropdown-item
            divided
            :class="$style.out"
            @click.native="routerFn('logout')"
          >
            {{ $t('Signout') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      username: ''
    }
  },
  methods: {
    routerFn (location) {
      if (location === 'logout') {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('pkCode')
        localStorage.removeItem('userid')
        localStorage.removeItem('lang')
        this.$router.push({ name: 'login' })
      } else {
        this.$router.push({ name: location })
      }
    }
  },
  mounted: function () {
    this.username = window.localStorage.getItem('name')
  }
}
</script>

<style lang="scss" module>
.root {
  display: flex;
  justify-content: space-between;
}

.icon {
  margin-right: 10px;
  cursor: pointer;
}

.img {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.out {
  text-align: center;
}
</style>
