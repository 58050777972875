<template>
  <el-table
    :data="tableData"
    tooltip-effect="dark"
    :class="$style.table"
    empty-text="無數據資料"
    :border="true"
  >
    <el-table-column
      label="群組ID"
      align="center"
      prop="vesselName"
    />
    <el-table-column
      label="群組名稱"
      prop="voyage"
      align="center"
    />
    <el-table-column
      label="狀態"
      prop="end"
      align="center"
      width="120"
    >
      <template slot-scope="scope">
        <el-switch v-model="scope.row.status"/>
      </template>
    </el-table-column>
    <el-table-column
      label="操作"
      align="center"
      width="60"
    >
      <i
        class="el-icon-edit"
        :class="$style.icon"
        @click="$emit('edit')"
      />
      </el-table-column>
  </el-table>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        index: 'E21030004',
        vesselName: 'OSG BOSSTEC',
        voyage: 'V-2107W',
        sono: '9396',
        HBLNo: 'MPKESTI2120010',
        client: 'FKOL0001/歳運物流股',
        end: '-',
        up: '-',
        startship: 'CUL',
        endship: 'FPM',
        status: false
      }, {
        index: 'E21024738',
        vesselName: 'FORMOSA CONTAINER',
        voyage: 'V-4579W',
        sono: '0455',
        HBLNo: 'PTXTAP2120003',
        client: 'FKOL0001/歳運物流股',
        end: '-',
        up: '-',
        startship: 'FPM',
        endship: 'TSS',
        status: false
      }, {
        index: 'E21024156',
        vesselName: 'YM INCEPTION',
        voyage: 'V-175S',
        sono: '0213',
        HBLNo: 'PKETAP2120010',
        client: 'FKOL0001/歳運物流股',
        end: '-',
        up: '-',
        startship: 'CUL',
        endship: 'FPM',
        status: false
      }, {
        index: 'E21023586',
        vesselName: 'KANWAY GLOBAL',
        voyage: 'V-2108S',
        sono: '0255',
        HBLNo: 'PKETAP2120009',
        client: 'FKOL0001/歳運物流股',
        end: '-',
        up: '-',
        startship: 'CUL',
        endship: 'WHL',
        status: false
      }, {
        index: 'E21022407',
        vesselName: 'TS HONGKONG',
        voyage: 'V-21008S',
        sono: '9176',
        HBLNo: 'MPTNHUP2120001',
        client: 'FKOL0001/歳運物流股',
        end: '-',
        up: '-',
        startship: 'FPM',
        endship: 'WHL',
        status: false
      }]
    }
  }
}
</script>

<style lang="scss" module>
.table {
  :global(.el-table__body-wrapper) {
    min-height: 475px;
  }
}

.icon {
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
  color: #39A85D;
}
</style>
