<template>
  <div :class="$style.root">
    <div :class="$style.bg" >
      <p :class="$style.title">
        重設你的密碼
      </p>
      <span v-if="message !== ''" :class="$style.danger" style="margin-bottom:15px">{{message}}</span>
      <inputComponent
        :class="[$style.inputComponent]"
      >
        <template #title>
          新密碼
        </template>
        <template #input>
          <el-input
            v-model="password"
            :class="$style.input"
            placeholder="新密碼"
            type="password"
          />
        </template>
      </inputComponent>
      <inputComponent
        :class="[$style.inputComponent]"
      >
        <template #title>
          確認密碼
        </template>
        <template #input>
          <el-input
            v-model="secondPassword"
            :class="$style.input"
            placeholder="確認密碼"
            type="password"
          />
        </template>
      </inputComponent>
      <el-row :gutter="20">
        <el-col :span="12">
          <el-button type="primary" @click="resetPassword">
            確定修改
          </el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="info" @click="returnHome">
            回首頁
          </el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import inputComponent from '@/components/card/input.vue'
export default {
  components: {
    inputComponent
  },
  data () {
    return {
      uuid: '',
      password: '',
      secondPassword: '',
      message: ''
    }
  },
  methods: {
    returnLogin () {
      setTimeout(() => {
        this.$router.push('/login')
      }, 3000)
    },
    async checkUuid () {
      console.log(this.uuid)
      var body = {
        uuid: this.uuid
      }
      const data = await this.$store.dispatch('CheckResetPassword', body)
      if (data.code === '400') {
        this.message = data.message + ',即將回登入頁'
        this.returnLogin()
        return
      }
      if (!data.data.user_id) {
        this.message = '連結失效,即將回登入頁'
        this.returnLogin()
      }
    },
    checkPassword () {
      return this.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)
    },
    async resetPassword () {
      if (!this.checkPassword()) {
        this.message = '至少1.八位數 2.大小寫字母、數字組成'
        return
      }
      if (this.password !== this.secondPassword) {
        this.message = '兩組密碼不同'
        return
      }
      var body = {
        uuid: this.uuid,
        password: this.password,
        second_password: this.secondPassword
      }
      const data = await this.$store.dispatch('CheckResetPassword', body)
      console.log('reset data', data)
      // console.log('data', data.code)
      if (data.code === '200') {
        this.message = '修改完成'
        setTimeout(() => {
          this.$router.push('/login')
        }, 3000)
      } else {
        this.message = data.data.detail
      }
    },
    returnHome () {
      this.$router.push({
        name: 'login'
      })
    }
  },
  mounted () {
    this.uuid = this.$router.currentRoute.params.uuid
    this.checkUuid()
  }
}
</script>

<style lang="scss" module>
.root {
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  background: url('/KF_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.selectout {
  align-self: flex-end;
}

.bg {
  width: 350px;
  height: 360px;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-top: 8%;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: #68686a;
  margin: 30px 0 31px 0;
}

.input {
  :global(.el-input__inner) {
    height: 30px;
  }
}

.inputComponent {
  width: 250px;
  margin-bottom: 12px;

  &.margin {
    margin-bottom: 30px;
  }
}
.blockForgetPassword{
  width:80%;
  align-items: center;
  text-align: center;
}
.footer {
  position: relative;
  bottom: 10%;
  font-size: 13pt;
  width: 100%;
  align-items: center;
  text-align: center;
}
.danger {
  background-color: #F56C6C;
  color: #ffffff;
  padding: 10px;
}
</style>
