
// eslint-disable-next-line
import { hblChecklistSingle } from '@/api/hblso'

// eslint-disable-next-line
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
const checkReleased = {
  state: {
    hblChecklistSingleTableLabel: [{
      label: 'version',
      prop: 'version',
      minWidth: 50
    }, {
      label: 'update_time',
      prop: 'transfer_dt',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'transfer_by',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: '上傳HCA',
      btn: true,
      prop: 'button',
      minWidth: 20,
      node: 'SOA'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }]
  },

  getters: {
    hblChecklistSingleTableLabel: (state) => state.hblChecklistSingleTableLabel
  },

  mutations: {
    hblChecklistSingleTableLabel: (state, payload) => { state.hblChecklistSingleTableLabel = payload }
  },
  actions: {
    setHblChecklistSingleTableLabel: async (state) => {
      await state.commit('tableLabel', state.getters.hblChecklistSingleTableLabel)
    },
    hblChecklistSingleSearch: async (state, payload) => {
      await state.commit('tableLabel', state.getters.hblChecklistSingleTableLabel)
      await state.commit('pdfEmailList', [])
      state.dispatch('getPdfEmailList', 'HC')
      console.log('hblChecklistSingleSearch payload', payload)
      var body = { offset: 0, limit: 10 }
      if (payload !== null) {
        body.pre_book_no = payload.orderId
        if (payload.version !== undefined) {
          body.version = payload.version
        }
      }
      console.log('hblChecklistSingleSearch body', body)
      var result = await hblChecklistSingle(body)
      console.log('hblChecklistSingleSearch result', result)
      state.dispatch('singleFileInfoData', { rows: result.data, pre_book_no: '', pdf_serial: 0 })
      const orderDataInputList = []
      Object.values(state.getters.formlistTableLabel).forEach(async (item, idx) => {
        if (idx > 1) {
          orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
        }
      })
      switch (result.status) {
        case 200:
          // var rsp = await hblChecklistList({ pre_book_no: body.pre_book_no })
          var maxVersion = result.data.data.ListData[0].version // Math.max(...result.data.data.ListData.map(item => item.version))
          console.log('maxVersion', maxVersion)
          await Object.values(result.data.data.ListData).forEach(item => {
            if (Number(item.version) === maxVersion) {
              item.max = true
            } else {
              item.max = false
            }
            item.Edit = faEdit
            item.View = faEye
            item.messageid = state.getters.orderId
            item.button = true
          })
          await state.commit('pageData', result.data.data.PageData)
          await state.commit('tableData', result.data.data.ListData)
          // await state.commit('orderDataInfo', orderDataInputList)
          break
        default:
          await state.commit('pageData', [])
          await state.commit('tableData', [])
          await state.commit('tableLabel', [])
      }
      // console.log('tableData', state.getters.tableData)
    },
    hblChecklistSingleCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      body.pre_book_no = state.getters.orderId
      result = await hblChecklistSingle(body)
      // var rsp = await hblChecklistList({ pre_book_no: body.pre_book_no })
      var maxVersion = result.data.data.ListData[0].version // Math.max(...result.data.data.ListData.map(item => item.version))
      console.log('maxVersion', maxVersion)
      await Object.values(result.data.data.ListData).forEach(item => {
        if (Number(item.version) === maxVersion) {
          item.max = true
        } else {
          item.max = false
        }
        item.Edit = faEdit
        item.View = faEye
        item.messageid = state.getters.orderId
        item.button = true
      })
      await state.commit('tableLabel', state.getters.hblsoSingleTableLabel)

      // console.log('hblsoSingleAndInvoicePackinglistCurrentChange body', body)
      // console.log('hblsoSingleAndInvoicePackinglistCurrentChange result', result)
      // console.log('hblsoSingleAndInvoicePackinglistCurrentChange PageData', state.getters.pageData)
      result.data.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.data.PageData.currentPage = currentPage
      // console.log('hblsoSingleAndInvoicePackinglistCurrentChange PageData', state.getters.pageData)
      await state.commit('pageData', result.data.data.PageData)
      await state.commit('tableData', result.data.data.ListData)
    }
  }
}

export default checkReleased
