import { userList, userDelete, companyList } from '@/api/user'
import { userPermissionList, userPermissionSingle } from '@/api/system'
// import { purchaseSingle } from '@/api/purchase'
// import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
const usermanagement = {
  state: {
    userManagementTableData: [],
    userManagementSearchInputList: [{
      label: '使用者帳號',
      input: '',
      type: 'input',
      key: 'account'
    }, {
      label: '使用者名稱',
      input: '',
      type: 'input',
      key: 'name'
    }, {
      label: '角色',
      input: '',
      type: 'input',
      key: 'userrole'
    }, {
      label: '公司',
      input: '',
      type: 'input',
      key: 'companyname'
    }, {
      label: 'status',
      input: '',
      type: 'input',
      key: 'status'
    }],
    userManagementAddInputlist: [
      {
        label: '公司名稱',
        input: [],
        type: 'select',
        key: 'companyid',
        hidden: false,
        required: true,
        msg: ''
      }, {
        label: '角色名稱',
        input: '',
        type: 'input',
        key: 'userrole',
        hidden: false,
        required: false,
        msg: ''
      }, {
        label: '部門代號',
        input: '',
        type: 'select',
        key: 'departmentId',
        hidden: true,
        required: false,
        msg: ''
      }, {
        label: '部門名稱',
        input: '',
        type: 'input',
        key: 'departmentname',
        hidden: false,
        required: true,
        msg: ''
      }, {
        label: '使用者代號',
        input: '',
        type: 'select',
        key: 'nameId',
        hidden: true,
        required: false,
        msg: ''
      }, {
        label: '使用者名稱',
        input: '',
        type: 'input',
        key: 'name',
        hidden: false,
        required: true,
        msg: ''
      }, {
        label: '使用者帳號',
        input: '',
        type: 'input',
        key: 'account',
        hidden: false,
        required: true,
        msg: ''
      }, {
        label: '密碼',
        input: '',
        type: 'input',
        key: 'passwd',
        hidden: false,
        required: true,
        msg: ''
      }, {
        label: '英文名稱',
        input: '',
        type: 'input',
        key: 'englishname',
        hidden: false,
        required: true,
        msg: ''
      }, {
        label: '電話',
        input: '',
        type: 'input',
        key: 'phone',
        hidden: false,
        required: true,
        msg: ''
      }, {
        label: 'Fax',
        input: '',
        type: 'input',
        key: 'fax',
        hidden: false,
        required: true,
        msg: ''
      }, {
        label: 'Email',
        input: '',
        type: 'input',
        key: 'email',
        hidden: false,
        required: true,
        msg: ''
      }, {
        label: '啟用',
        input: '',
        type: 'radio',
        key: 'status',
        hidden: false,
        required: true,
        msg: ''
      }, {
        label: 'Email CC',
        input: '',
        type: 'input',
        key: 'email_cc',
        hidden: false,
        required: false,
        msg: '',
        placehodler: '多筆Email請用逗號分隔'
      }, {
        label: '管理者',
        input: '',
        type: 'radio',
        key: 'isAdmin',
        hidden: false,
        required: false,
        msg: ''
      }
    ]
  },

  getters: {
    userManagementTableData: (state) => state.userManagementTableData,
    userManagementSearchInputList: (state) => state.userManagementSearchInputList,
    userManagementAddInputlist: (state) => state.userManagementAddInputlist,
    userManagementCompanyList: (state) => state.userManagementCompanyList
  },

  mutations: {
    userManagementTableData: (state, payload) => { state.userManagementTableData = payload },
    userManagementSearchInputList: (state, payload) => { state.userManagementSearchInputList = payload },
    userManagementAddInputlist: (state, payload) => { state.userManagementAddInputlist = payload },
    userManagementCompanyList: (state, payload) => { state.userManagementCompanyList = payload }
  },
  actions: {
    userManagementInputList: async (state) => {
      state.commit('searchInputList', state.getters.userManagementSearchInputList)
      state.commit('addInputlist', state.getters.userManagementAddInputlist)
      // state.commit('tableLabel', state.getters.deliveryMasterTableLabel)
      // console.log('setDeliveryMasterInputList')
    },
    userManagementSearch: async (state, payload) => {
      const body = {
        offset: 0,
        limit: 10
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        // console.log('userManagementSearch item', item)
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      // console.log('userManagementSearch body', body)
      var result = await userList(body)
      var resultCompanyList = await companyList({})
      console.log('userManagementSearch result', result)
      state.commit('pageData', result.data.PageData)
      state.commit('tableData', result.data.ListData)
      var convertCompanyList = {}
      if (resultCompanyList.data.length > 0) {
        resultCompanyList.data.forEach(item => {
          convertCompanyList[item.id] = item
        })
      }
      state.commit('userManagementCompanyList', convertCompanyList)
      console.log('api userManagementCompanyList', convertCompanyList)
    },
    userManagementHandleCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input') {
          body[item.key] = item.input
        }
      })
      result = await userList(body)
      result.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.PageData.currentPage = currentPage
      state.commit('pageData', result.data.PageData)
      state.commit('tableData', result.data.ListData)
      // console.log('handleCurrentChange body', body)
      // console.log('handleCurrentChange result', result)
    },
    delTableSelectDataByDeliveryMaster: async (state, payload) => {
      try {
        var rsp
        // console.log('delTableSelectData', state.getters.tableSelectData)
        await Object.values(state.getters.tableSelectData).forEach(async item => {
          // console.log('item', item.pre_book_no)
          const body = { pre_book_no: item.pre_book_no }
          // console.log('body', body)
          rsp = await userDelete(body)
          console.log('result', rsp)
        })
      } catch (e) {
        console.log(e)
      }
      // console.log('rsp.status', rsp.status)
      // if (rsp.status === 200) {
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input') {
          state.getters.pageData[item.key] = item.input
        }
      })
      const result = await userList(state.getters.pageData)
      result.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.PageData.currentPage = state.getters.pageData.currentPage
      state.commit('pageData', result.data.PageData)
      state.commit('tableData', result.data.ListData)
    },
    userPermissions: async (state, payload) => {
      var result = await userPermissionList()
      result.data.ListData.map(function (v, k) {
        result.data.ListData[k].enable = v.enable === 1
      })
      state.commit('tableData', result.data.ListData)
      state.commit('pageData', [])
    },
    userauthorityHandleCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input') {
          body[item.key] = item.input
        }
      })
      result = await userPermissionList(body)
      result.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.PageData.currentPage = currentPage
      state.commit('pageData', result.data.PageData)
      state.commit('tableData', result.data.ListData)
      // console.log('handleCurrentChange body', body)
      // console.log('handleCurrentChange result', result)
    },
    singleUserPermission: async (state, payload) => {
      var result = await userPermissionSingle()
      console.log(result)
      state.commit('userPermission', result)
      return result
    }
  }
}

export default usermanagement
