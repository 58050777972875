import axios from 'axios'
import { Loading } from 'element-ui'
export default () => {
  axios.defaults.baseURL = '/'
  var loadingInstance = null
  // axios.defaults.baseURL = 'http://kfrd.ioneit.com/'
  // axios.defaults.baseURL = '/kfapi'
  // axios.defaults.headers.post['Content-Type'] = 'charset=utf-8; application/pdf'
  axios.defaults.headers.common.Pragma = 'no-cache'
  axios.defaults.withCredentials = true
  axios.interceptors.request.use(
    config => {
      if (config.url !== 'api/login') {
        config.headers.accessToken = localStorage.getItem('accessToken')
      }
      loadingInstance = Loading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0.6)' })
      window.localStorage.setItem('onlineSeconds', 0)
      // if (config.url.startsWith('api/delivery/pdfuploader')) {
      //   console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@')
      //   // config.headers['Content-Type'] = 'multipart/form-data;application/pdf'
      //   // config.headers.Accept = '*/*'
      //   console.log('config.headers', config.headers)
      //   // config.headers.common.Accept = '*/*'
      //   config.headers.post['Content-Type'] = 'multipart/form-data;application/pdf'
      //   console.log('config.headers', config.headers)
      // }
      return config
    },
    error => {
      return Promise.reject(error.response)
    }
  )
  axios.interceptors.response.use(
    function (response) {
      loadingInstance.close()
      return response
    },
    function (error) {
      // console.log('error', error.response.status)
      // console.log('error', error.response)
      loadingInstance.close()
      switch (error.response.status) {
        case 401:
          localStorage.removeItem('accessToken')
          localStorage.removeItem('userid')
          localStorage.removeItem('name')
          localStorage.removeItem('basic_code')
          localStorage.removeItem('lang')
          if (error.response.config.url !== 'api/login') {
            window.location.href = '/login'
          }
          break
        case 400:
        case 404:
        case 500:
          return error.response
      }
      return Promise.reject(error.response)
    }
  )
  return axios
}
