// eslint-disable-next-line
import { customerCreate, customerList, customerDelete, supplierlist, customerUpdate, pdfEmails } from '@/api/customer'
const delivery = {
  state: {
    customerSearchInputList: [{
      label: '基本資料代碼',
      input: '',
      type: 'input',
      key: 'basic_code'
    }, {
      label: '英文名稱',
      input: '',
      type: 'input',
      key: 'english_name'
    }, {
      label: '中文名稱',
      input: '',
      type: 'input',
      key: 'local_name'
    }, {
      label: '資料類別',
      input: '',
      type: 'select',
      key: 'basic_type',
      clientOptions: [{
        label: 'LC-國內客戶',
        val: 'LC'
      }, {
        label: 'FW-同行(承攬公司)',
        val: 'FW'
      }]
    }],
    supplierSearchInputList: [{
      label: '基本資料代碼',
      input: '',
      type: 'input',
      key: 'basic_code'
    }, {
      label: '英文名稱',
      input: '',
      type: 'input',
      key: 'english_name'
    }, {
      label: '中文名稱',
      input: '',
      type: 'input',
      key: 'local_name'
    }, {
      label: '資料類別',
      input: '',
      type: 'select',
      key: 'basic_type',
      businessOptions: [{
        label: 'BR-報關行',
        val: 'BR'
      }, {
        label: 'TR-貨運公司',
        val: 'TR'
      }, {
        label: 'CA-船司',
        val: 'CA'
      }, {
        label: 'AG-國外代理商(艾旺國外分公司)',
        val: 'AG'
      }, {
        label: 'CY-貨櫃場',
        val: 'CY'
      }, {
        label: 'SA-業務人員',
        val: 'SA'
      }, {
        label: 'OP-OP人員',
        val: 'OP'
      }, {
        label: 'LN-航線',
        val: 'LN'
      }, {
        label: 'MS-公證行',
        val: 'MS'
      }]
    }],
    customerAddInputlist: [{
      label: '基本資料代碼',
      input: '',
      msg: '',
      key: 'basic_code',
      type: 'input'
    }, {
      label: '英文名稱',
      input: '',
      msg: '',
      key: 'english_name',
      type: 'input'
    }, {
      label: '中文名稱',
      input: '',
      msg: '',
      key: 'local_name',
      type: 'input'
    }, {
      label: '資料類別',
      input: '',
      msg: '',
      key: 'basic_type',
      type: 'select',
      clientOptions: [{
        label: 'LC-國內客戶',
        val: 'LC'
      }, {
        label: 'FW-同行(承攬公司)',
        val: 'FW'
      }]
    }],
    supplierAddInputlist: [{
      label: '基本資料代碼',
      input: '',
      msg: '',
      key: 'basic_code',
      type: 'input'
    }, {
      label: '英文名稱',
      input: '',
      msg: '',
      key: 'english_name',
      type: 'input'
    }, {
      label: '中文名稱',
      input: '',
      msg: '',
      key: 'local_name',
      type: 'input'
    }, {
      label: '資料類別',
      input: '',
      msg: '',
      key: 'basic_type',
      type: 'select',
      businessOptions: [{
        label: 'BR-報關行',
        val: 'BR'
      }, {
        label: 'TR-貨運公司',
        val: 'TR'
      }, {
        label: 'CA-船司',
        val: 'CA'
      }, {
        label: 'AG-國外代理商(艾旺國外分公司)',
        val: 'AG'
      }, {
        label: 'CY-貨櫃場',
        val: 'CY'
      }, {
        label: 'SA-業務人員',
        val: 'SA'
      }, {
        label: 'OP-OP人員',
        val: 'OP'
      }, {
        label: 'LN-航線',
        val: 'LN'
      }, {
        label: 'MS-公證行',
        val: 'MS'
      }]
    }],
    customerAndSupplierTableLabel: [{
      type: 'selection',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: '基本資料代碼',
      minWidth: 200,
      prop: 'basic_code'
    }, {
      label: '英文名稱',
      minWidth: 130,
      prop: 'english_name'
    }, {
      label: '中文名稱',
      prop: 'local_name'
    }, {
      label: '資料類別',
      minWidth: 180,
      prop: 'basic_type'
    }, {
      label: '傳送者',
      minWidth: 200,
      prop: 'transfer_by'
    }, {
      label: '建立日期',
      minWidth: 120,
      prop: 'transfer_dt'
    }, {
      label: '編輯',
      prop: 'Edit',
      icon: true,
      minWidth: 20,
      node: 'edit',
      key: 'id'
    }],
    customerResult: {},
    customerResultList: []
  },

  getters: {
    customerSearchInputList: (state) => state.customerSearchInputList,
    supplierSearchInputList: (state) => state.supplierSearchInputList,
    customerAddInputlist: (state) => state.customerAddInputlist,
    supplierAddInputlist: (state) => state.supplierAddInputlist,
    customerAndSupplierTableLabel: (state) => state.customerAndSupplierTableLabel,
    customerResult: (state) => state.customerResult,
    customerResultList: (state) => state.customerResultList
  },

  mutations: {
    customerSearchInputList: (state, payload) => { state.customerSearchInputList = payload },
    supplierSearchInputList: (state, payload) => { state.supplierSearchInputList = payload },
    customerAddInputlist: (state, payload) => { state.customerAddInputlist = payload },
    supplierAddInputlist: (state, payload) => { state.supplierAddInputlist = payload },
    customerAndSupplierTableLabel: (state, payload) => { state.customerAndSupplierTableLabel = payload },
    customerResult: (state, payload) => { state.customerResult = payload },
    customerResultList: (state, payload) => { state.customerResultList = payload }
  },
  actions: {
    setCustomerInputList: async (state) => {
      state.commit('searchInputList', state.getters.customerSearchInputList)
      state.commit('addInputlist', state.getters.customerAddInputlist)
      state.commit('tableLabel', state.getters.customerAndSupplierTableLabel)
      // console.log('setpurchaseInputList')
    },
    setSupplierInputList: async (state) => {
      state.commit('searchInputList', state.getters.supplierSearchInputList)
      state.commit('addInputlist', state.getters.supplierAddInputlist)
      state.commit('tableLabel', state.getters.customerAndSupplierTableLabel)
      // console.log('setpurchaseInputList')
    },
    clearSearch: async (state) => {
      Object.values(state.getters.searchInputList).forEach(item => {
        item.input = ''
      })
    },
    customerSearch: async (state, payload) => {
      const body = {
        offset: 0,
        limit: 10
      }
      console.log('searchInputList', state.getters.searchInputList)
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input' || item.type === 'select') {
          body[item.key] = item.input === '' ? 'None' : item.input
        }
      })
      console.log('handleSearch body', body)
      var result = await customerList(body)
      switch (result.status) {
        case 200:
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
      }
      console.log('handleSearch result', result)
    },
    supplierSearch: async (state, payload) => {
      const body = {
        offset: 0,
        limit: 10
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input' || item.type === 'select') {
          body[item.key] = item.input === '' ? 'None' : item.input
        }
      })
      console.log('handleSearch body', body)
      var result = await supplierlist(body)
      switch (result.status) {
        case 200:
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
      }
      console.log('handleSearch result', result)
    },
    modInputlistByCustomer: async (state, payload) => {
      const body = {}
      console.log('modInputlistByCustomer addInputList', state.getters.addInputlist)
      console.log('modInputlistByCustomer addInputList', payload)
      Object.values(state.getters.addInputlist).forEach(item => {
        if (item.type === 'input' || item.type === 'select') {
          body[item.key] = item.input === '' ? 'None' : item.input
        }
      })
      console.log('modInputlistByCustomer body', body)
      const result = await customerUpdate(body)
      await state.commit('customerResult', result)
      // console.log('modInputlistByCustomer result', result)
      await state.dispatch('customerSearch')
    },
    modInputlistBySupplier: async (state, payload) => {
      console.log('modInputlistBySupplier addInputList', state.getters.addInputlist)
      const body = {}
      Object.values(state.getters.addInputlist).forEach(item => {
        if (item.type === 'input' || item.type === 'select') {
          body[item.key] = item.input === '' ? 'None' : item.input
        }
      })
      console.log('modInputlistByCustomer body', body)
      var result = await customerUpdate(body)
      await state.commit('customerResult', result)
      console.log('modInputlistByCustomer result', result)
      await state.dispatch('supplierSearch')
    },
    customerHandleCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      console.log('customerHandleCurrentChange')
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input' || item.type === 'select') {
          body[item.key] = item.input === '' ? 'None' : item.input
        }
      })
      result = await customerList(body)
      result.data.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.data.PageData.currentPage = currentPage
      state.commit('pageData', result.data.data.PageData)
      state.commit('tableData', result.data.data.ListData)
      // console.log('handleCurrentChange body', body)
      // console.log('handleCurrentChange result', result)
    },
    supplierHandleCurrentChange: async (state, payload) => {
      const currentPage = payload
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input' || item.type === 'select') {
          body[item.key] = item.input === '' ? 'None' : item.input
        }
      })
      result = await supplierlist(body)
      console.log('handleCurrentChange body', body)
      console.log('handleCurrentChange result', result)
      result.data.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.data.PageData.currentPage = currentPage
      state.commit('pageData', result.data.data.PageData)
      state.commit('tableData', result.data.data.ListData)
    },
    delTableSelectDataByCustomer: async (state, payload) => {
      const rsp = []
      const items = []
      try {
        // console.log('delTableSelectData', state.getters.tableSelectData)
        await Object.values(state.getters.tableSelectData).forEach(async item => {
          // console.log('item', item.pre_book_no)
          items.push(item.basic_code)
        })
      } catch (e) {
        console.log(e)
      }
      const body = { basic_code: items }
      console.log('body', body)
      await rsp.push(await customerDelete(body))
      console.log('result', rsp)
      await state.commit('customerResultList', rsp)
      await state.dispatch('customerSearch')
    },
    delTableSelectDataBySupplier: async (state, payload) => {
      const rsp = []
      const items = []
      try {
        // console.log('delTableSelectData', state.getters.tableSelectData)
        await Object.values(state.getters.tableSelectData).forEach(async item => {
          // console.log('item', item.pre_book_no)
          items.push(item.basic_code)
        })
      } catch (e) {
        console.log(e)
      }
      const body = { basic_code: items }
      console.log('body', body)
      await rsp.push(await customerDelete(body))
      console.log('result', rsp)
      await state.commit('customerResultList', rsp)
      await state.dispatch('supplierSearch')
    },
    addDataByCustomer: async (state, payload) => {
      const body = {}
      Object.values(state.getters.addInputlist).forEach(item => {
        body[item.key] = item.input
      })
      console.log(body)
      const rsp = await customerCreate(body)
      await state.commit('customerResult', rsp)
      await state.dispatch('customerSearch')
      console.log('rsp', rsp)
    },
    addDataBySupplier: async (state, payload) => {
      const body = {}
      Object.values(state.getters.addInputlist).forEach(item => {
        body[item.key] = item.input
      })
      // console.log(body)
      const rsp = await customerCreate(body)
      await state.commit('customerResult', rsp)
      await state.dispatch('supplierSearch')
    },
    getPdfEmailList: async (state, payload) => {
      // #region 呼叫pdf email list api
      var resultEmails = await pdfEmails({
        file_id: state.getters.orderId,
        file_type: payload
      })
      if (resultEmails.data.code === '200') {
        var emailList = []
        resultEmails.data.data.forEach(item => {
          emailList.push({
            id: item.id,
            label: item.name,
            value: item.email,
            companyName: item.companyname
          })
        })
        state.commit('pdfEmailList', emailList)
      }
      // #endregion
    }
  }
}

export default delivery
