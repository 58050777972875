import List from '@/views/list/list/list.vue'
import Listorderid from '@/views/list/list/listorderid.vue'
import Ordercomponent from '@/views/list/list/router/ordercomponent.vue'
import Subcontent from '@/views/list/list/router/subcontent.vue'
import Quotation from '@/views/list/list/router/quotation.vue'

export default [
  {
    path: 'list/list',
    name: 'list',
    component: List,
    meta: { title: 'BookingMgmt', class: 'list', btnList: true, inputType: 'common' }
  }, {
    path: 'list/:orderId/quotation/quotation/:fileType/:operating',
    name: 'quotation',
    component: Quotation,
    meta: { title: '海運承攬管理-訂單-orderid-訂單內容', subtitle: '出貨通知單', class: 'list' }
  }, {
    path: 'list/:orderId/quotation/notice/:fileType/:operating',
    name: 'notice',
    component: Quotation,
    meta: { title: '海運承攬管理-訂單-orderid-訂單內容', subtitle: '裝船通知書', class: 'list' }
  }, {
    path: 'list/:orderId/quotation/cargo/:fileType/:operating',
    name: 'cargo',
    component: Quotation,
    meta: { title: '海運承攬管理-訂單-orderid-訂單內容', subtitle: '丈量證明文件', class: 'list' }
  }, {
    path: 'list/:orderId/quotation/invoice/:fileType/:operating',
    name: 'invoice',
    component: Quotation,
    meta: { title: '海運承攬管理-訂單-orderid-訂單內容', subtitle: 'Invoice', class: 'list' }
  }, {
    path: 'list/:orderId/quotation/packingList/:fileType/:operating',
    name: 'packingList',
    component: Quotation,
    meta: { title: '海運承攬管理-訂單-orderid-訂單內容', subtitle: 'PackingList', class: 'list' }
  }, {
    path: 'list/:orderId/quotation/bill/:fileType/:operating',
    name: 'bill',
    component: Quotation,
    meta: { title: '海運承攬管理-訂單-orderid-訂單內容', subtitle: 'HB/L 提單作法 (S/O)', class: 'list' }
  }, {
    path: 'list/:orderId/quotation/hblcheck/:fileType/:operating',
    name: 'hblcheck',
    component: Quotation,
    meta: { title: '海運承攬管理-訂單-orderid-訂單內容', subtitle: 'HB/L 核對單', class: 'list' }
  }, {
    path: 'list/:orderId/quotation/lading/:fileType/:operating',
    name: 'lading',
    component: Quotation,
    meta: { title: '海運承攬管理-訂單-orderid-訂單內容', subtitle: 'HB/L 提單', class: 'list' }
  }, {
    path: 'list/:orderId/quotation/customer/:fileType/:operating',
    name: 'customer',
    component: Quotation,
    meta: { title: '海運承攬管理-訂單-orderid-訂單內容', subtitle: '客戶帳單', class: 'list' }
  }, {
    path: 'list/:orderId/quotation/confirmed/:fileType/:operating',
    name: 'confirmed',
    component: Quotation,
    meta: { title: '海運承攬管理-訂單-orderid-訂單內容', subtitle: '報價確認', class: 'list' }
  }, {
    path: 'list/:orderId',
    component: Listorderid,
    redirect: { name: 'shipment' },
    children: [
      {
        path: 'shipment',
        name: 'shipment',
        component: Ordercomponent,
        meta: { title: 'delivery', subtitle: '出貨通知', class: 'list' }
      }, {
        path: 'completeBooking',
        name: 'completeBooking',
        component: Ordercomponent,
        meta: { title: '海運承攬管理-訂單-完成訂艙', subtitle: '完成訂艙', class: 'list' }
      }, {
        path: 'delivery',
        name: 'delivery',
        component: Subcontent,
        meta: { title: '海運承攬管理-訂單-貨進櫃場', subtitle: '貨進櫃場', class: 'list' }
      }, {
        path: 'cargoMeasure',
        name: 'cargoMeasure',
        component: Ordercomponent,
        meta: { title: '海運承攬管理-訂單-貨物丈量', subtitle: '貨物丈量', class: 'list' }
      }, {
        path: 'soReleased',
        name: 'soReleased',
        component: Ordercomponent,
        meta: { title: '海運承攬管理-訂單-SO提供', subtitle: 'SO提供', class: 'list' }
      }, {
        path: 'ivReleased',
        name: 'ivReleased',
        component: Ordercomponent,
        meta: { title: '海運承攬管理-訂單-Invoice', subtitle: 'Invoice', class: 'list' }
      }, {
        path: 'plReleased',
        name: 'plReleased',
        component: Ordercomponent,
        meta: { title: '海運承攬管理-訂單-Packing List', subtitle: 'Packing List', class: 'list' }
      }, {
        path: 'customsReleased',
        name: 'customsReleased',
        component: Subcontent,
        meta: { title: '海運承攬管理-訂單-出口放行', subtitle: '出口放行', class: 'list' }
      }, {
        path: 'checkReleased',
        name: 'checkReleased',
        component: Ordercomponent,
        meta: { title: '海運承攬管理-訂單-提單核對', subtitle: '提單核對', class: 'list' }
      }, {
        path: 'quotationConfirmed',
        name: 'quotationConfirmed',
        component: Ordercomponent,
        meta: { title: '海運承攬管理-訂單-報價確認', subtitle: '報價確認', class: 'list' }
      }, {
        path: 'documentReleased',
        name: 'documentReleased',
        component: Ordercomponent,
        meta: { title: '海運承攬管理-訂單-發單通知', subtitle: '發單通知', class: 'list' }
      }, {
        path: 'departure',
        name: 'departure',
        component: Subcontent,
        meta: { title: '海運承攬管理-訂單-啟航', subtitle: '啟航', class: 'list' }
      }, {
        path: 'pouchReceived',
        name: 'pouchReceived',
        component: Subcontent,
        meta: { title: '海運承攬管理-訂單-POUCH 文件接收', subtitle: 'POUCH 文件接收', class: 'list' }
      }, {
        path: 'arrival',
        name: 'arrival',
        component: Subcontent,
        meta: { title: '海運承攬管理-訂單-抵達', subtitle: '抵達', class: 'list' }
      }, {
        path: 'doRelease',
        name: 'doRelease',
        component: Subcontent,
        meta: { title: '海運承攬管理-訂單-DO Release', subtitle: 'DO Release', class: 'list' }
      }
    ]
  }
]
