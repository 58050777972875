import axios from 'axios'

export const userRolos = async (body) => await axios.post('api/user/roles', body).then((response) => response.data)

export const checkToken = async (body) => await axios.post('api/user/roles', body).then((response) => response.data)

export const userRoleCreate = async (body) => await axios.post('api/user/role/create', body).then((response) => response.data)

export const userPrograms = async (body) => await axios.post('api/user/programs', body).then((response) => response.data)

export const userProgramsSingle = async (body) => await axios.post('api/user/programs/single', body).then((response) => response.data)

export const userProgramCreate = async (body) => await axios.post('api/user/program/create', body).then((response) => response.data)

export const userProgramrole = async (body) => await axios.post('api/user/programrole', body).then((response) => response.data)

export const userProgramroleUpdate = async (body) => await axios.post('api/user/programrole/update', body).then((response) => response.data)

export const userPermissionList = async (body) => await axios.post('/api/user/permissions/list', body).then((response) => response.data)

export const userPermissionSingle = async (body) => await axios.post('/api/user/permissions/single', body).then((response) => response.data)

export const userPermissionAction = async (body) => await axios.post('/api/user/permissions/action', body).then((response) => response.data)
