<template>
  <div v-if="checkIfShowMap">
    <div class="dateArea" style="display:flex">
      <div class="dateStart">
        <el-date-picker
          v-model="dateStart"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="選擇日期">
        </el-date-picker>
      </div>
      <div class="dateAreaMiddle">
        至
      </div>
      <div class="dateEnd">
        <el-date-picker
          v-model="dateEnd"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="選擇日期">
        </el-date-picker>
      </div>
      <el-button type="success" style="margin-left:10px;" @click="searchMap">篩選</el-button>
    </div>
    <l-map
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
    >
      <l-tile-layer :url="url"/>
      <l-marker :lat-lng="tranLatLng(v.warp, v.weft)" :icon="icon" v-for="(v, k) in mapRows" :key="k">
        <l-popup :class="$style.popup">
          <div :class="$style.title">
            <span v-if="v.addressname === '台灣'">台灣出口總量</span>
            <span v-else>
              {{v.addressname}}
            </span>
          </div>
          <div :class="$style.text">CY櫃： {{v.cy_van}}</div>
          <div :class="$style.text">CFS櫃： {{v.cfs_van}}</div>
          <div :class="$style.text">CBM(CFS)： {{v.cbm}}</div>
          <div :class="$style.text">完成貨櫃：{{v.total_van}}</div>
          <div :class="$style.text">完成文件：{{v.total_completed_file}}</div>
          <div :class="$style.text">完成貨量：{{Math.round(v.total_measure)}}</div>
          <el-button
            type="primary"
            :class="$style.btn"
            @click="loadCompleteQuantity(v.code, v.total_measure)"
          >
            顯示貨量資訊
          </el-button>
        </l-popup>
      </l-marker>
    </l-map>
    <CompleteQuantity v-if="displayCompleteQuantity" @close="displayCompleteQuantity =false" :mapDetail="mapDetail" ></CompleteQuantity>
  </div>
  <div :class="$style.welcome" v-else ></div>
</template>

<script>
import { mapList, mapDetail } from '@/api/map'
import { latLng, icon } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
import CompleteQuantity from './completeQuantity.vue'
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    CompleteQuantity
  },
  data () {
    return {
      basicType: null,
      displayCompleteQuantity: false,
      icon: icon({
        iconUrl: require('@/assets/image/placeholder.svg'),
        iconSize: [20, 20]
      }),
      zoom: 2,
      center: [0, 0],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      one: latLng(25.0236097, 55.0227682),
      two: latLng(25.1388804, 121.7421144),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5,
        scrollWheelZoom: false,
        dragging: false,
        zoomControl: false
      },
      showMap: true,
      mapRows: [],
      mapDetail: {},
      dateStart: '',
      dateEnd: ''
    }
  },
  computed: {
    checkIfShowMap () {
      console.log('mapshow this.basicType', this.basicType)
      if (this.basicType !== 'IO' && this.basicType !== 'KF') {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    async loadCompleteQuantity (code, total) {
      var body = {
        code: code,
        date_Start: this.dateStart,
        date_End: this.dateEnd
      }
      var rows = await mapDetail(body)
      this.mapDetail = rows.data
      this.mapDetail.total_measure = total
      this.displayCompleteQuantity = true
    },
    zoomUpdate (zoom) {
      this.currentZoom = zoom
    },
    centerUpdate (center) {
      this.currentCenter = center
    },
    showLongText () {
      this.showParagraph = !this.showParagraph
    },
    innerClick () {
      window.alert('Click!')
    },
    tranLatLng (lat, lng) {
      return latLng(lat, lng)
    },
    async searchMap () {
      var body = {
        date_Start: this.dateStart,
        date_End: this.dateEnd
      }
      var res = await mapList(body)
      if (res.code === '200') {
        this.mapRows = res.data.ListData
      }
      this.$store.commit('tableData', this.mapRows)
    }
  },
  mounted: async function () {
    var tmpDate = new Date()
    tmpDate.setDate(tmpDate.getDate() - 90)
    this.dateStart = tmpDate.toISOString().slice(0, 10)
    this.dateEnd = new Date().toISOString().slice(0, 10)
    this.searchMap()
    // this.dateStart = current month
  },
  created () {
    const type = localStorage.getItem('basic_type')
    this.basicType = type
  },
  watch: {
    dateStart (val) {
      var d = new Date(val)
      d.setDate(d.getDate() + 90)
      this.dateEnd = d.toISOString().slice(0, 10)
      console.log(d)
    }
  }
}
</script>

<style lang="scss" module>
.popup {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 14px;

  .title {
    font-size: 18px;
    color: #8a8a8a;
    margin-bottom: 5px;
    border-bottom: 2px solid #e6e6e6;
    padding-bottom: 5px
  }
}

.text {
  color: #adadad;
  font-size: 14px;
  padding: 3px 0;
}
.welcome{
  background: url(/KF_bg.png) ;
  display: block;
  width:100%;
  height:100%;
  padding:0;
  margin:0;
}
</style>
<style scoped>
.dateArea{
  display:flex;
  margin-bottom: 15px;
}
.dateStart{
  margin-right:10px;
  margin-left:0px;
}
.dateAreaTitle{
  margin-right:10px;
}
.dateAreaMiddle{
  line-height: 2.5;
  vertical-align: middle;
  margin-right: 10px;
}
</style>
<style>
.el-input__prefix{
  color: #4BA676;
  border-right: 1px solid #DCDFE6;
  margin-right: 5px;
}
.el-input--prefix .el-input__inner {
    padding-left: 35px !important;
}
</style>
