<template>
  <div :class="$style.root">
    <navComponent :class="$style.left"/>
    <topComponent :class="$style.top"/>
    <router-view :class="$style.main"/>
    <!-- <div :class="$style.footer">版權所有：© 艾旺科技(股)公司<br>
 I-ONE Information Tech. Co., Ltd.  All Rights Reserved.</div> -->
  </div>
</template>

<script>
import navComponent from '@/nav/index.vue'
import topComponent from '@/top/index.vue'
export default {
  components: {
    navComponent,
    topComponent
  }
}
</script>

<style lang="scss" module>
.root {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: "nav top"
                      "nav content"
                      "footer footer";
  grid-template-columns: 250px 1fr;
  grid-template-rows: 45px 1fr;
}

.left {
  width: 100%;
  height: 100%;
  grid-area: nav;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.top {
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  grid-area: top;
  display: flex;
  align-items: center;
  padding: 0 23px;
  box-sizing: border-box;
}

.main {
  width: 100%;
  height: 100%;
  grid-area: content;
  background: #f8f8f8;
  padding: 32px 25px;
  box-sizing: border-box;
  overflow: auto;
  position:relative;
}
.footer {
  width: 100%;
  height: 100%;
  background: white;
  grid-area: footer;
  align-items: center;
  text-align: center;
  font-size: 13pt;
}
/*.footer {
  position:fixed;
  background-color: white;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 3;
}*/
</style>
