<template>
  <el-tabs
    v-model="active"
    @tab-click="$emit('tabRouter', active)"
  >
    <template v-for="(item, i) in menu">
      <el-tab-pane
        :label="item.label"
        :name="item.linkName"
        :key="i"
        :class="$style.menu"
      />
    </template>
  </el-tabs>
</template>

<script>
export default {
  data () {
    return {
      active: null,
      menu: [{
        label: '角色管理',
        linkName: 'userauthority'
      // }, {
        // label: '群組管理',
        // linkName: 'groupauthority'
      }]
    }
  },
  created () {
    this.active = this.$route.name
  }
}
</script>

<style lang="scss" module>
.menu {
  margin-bottom: 15px;
}
</style>
