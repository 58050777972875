<template>
  <div :class="$style.manipulate">
    <slot name="manipulate"></slot>
    <slot name="button"></slot>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" module>
.manipulate {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
