<template>
  <div :class="$style.root">
    <div :class="$style.left">
      <div :class="$style.border">
        Job No
      </div>
      <div :class="$style.border">
        船名 Vessel
      </div>
      <div :class="$style.border">
        航次 Voyage
      </div>
      <div :class="$style.border">
        SO No
      </div>
      <div :class="$style.border">
        HB/L No
      </div>
      <div :class="$style.border">
        客戶代碼
      </div>
      <div :class="$style.border">
        結關日 Closing Date
      </div>
      <div :class="$style.border">
        裝船日 (OnBoard)
      </div>
      <div :class="$style.border">
        收貨港 PLR
      </div>
      <div :class="$style.border">
        卸貨港 POD
      </div>
      <div :class="$style.border">
        目的地 PLD
      </div>
      <div :class="$style.border">
        裝貨港 POL
      </div>
      <div :class="$style.border">
        船期編號 VoyID
      </div>
      <div :class="$style.border">
       業務代碼Sale
      </div>
      <div :class="$style.border">
        OP代碼
      </div>
      <div :class="$style.border">
        CFS交貨處(貨櫃場)
      </div>
      <div :class="$style.border">
        報關行代碼
      </div>
      <div :class="$style.border">
        貨運行代碼
      </div>
      <div :class="$style.border">
        船公司代碼
      </div>
      <div :class="$style.border">
        國外代理商代碼
      </div>
      <div :class="$style.border">
        分合旗標
      </div>
      <div :class="$style.border">
        狀態旗標
      </div>
    </div>
    <div :class="$style.right">
      <el-tooltip
        class="item"
        effect="dark"
        :content="editRowData.pre_book_no"
        placement="top"
      >
        <p :class="$style.text">
          {{ editRowData.pre_book_no }}
        </p>
      </el-tooltip>
      <input :class="$style.input" v-model="editRowData.vsl_name" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.voyage" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.so_no" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.o_bl_no" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.customer_code" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.closing_date" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.onboard_date" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.receipt_place" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.port_discharge" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.port_load" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.delivery_place" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.ship_num" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.sales_code" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.entry_user_code" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.cfs_delivery_place" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.broker_code" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.truck_company" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.carrier" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.self_pod_agent" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.cd_flag" :disabled="editRowData.status_code === 3"/>
      <input :class="$style.input" v-model="editRowData.job_flag" :disabled="editRowData.status_code === 3"/>
    </div>
    <div :class="$style.bottom">
      <div :class="$style.title">
        <div :class="[$style.border, $style.borderconter]">
          序號
        </div>
        <div :class="[$style.border, $style.borderconter]">
          收費代碼
        </div>
        <div :class="[$style.border, $style.borderconter]">
          收費名稱
        </div>
        <div :class="[$style.border, $style.borderconter]">
          收費幣別
        </div>
        <div :class="[$style.border, $style.borderconter]">
          單價
        </div>
        <div :class="[$style.border, $style.borderconter]">
          數量
        </div>
        <div :class="[$style.border, $style.borderconter]">
          數量單位
        </div>
        <div :class="[$style.border, $style.borderconter]">
          應收金額
        </div>
        <div :class="[$style.border, $style.borderconter]">
          匯率
        </div>
        <div :class="[$style.border, $style.borderconter]">
          帳單金額
        </div>
        <div :class="[$style.border, $style.borderconter]">
          是否上稅
        </div>
        <div :class="[$style.border, $style.borderconter]">
          收款對象
        </div>
      </div>
      <template v-for="(item, i) in editRowData.detail">
        <div :class="$style.title" :key="i">
          <input :class="[$style.input, $style.inputborder]" v-model="item.sequence_no" :disabled="editRowData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.charge_code" :disabled="editRowData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.charge_name" :disabled="editRowData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.org_currency" :disabled="editRowData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.chg_rate" :disabled="editRowData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.chg_qty" :disabled="editRowData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.chg_unit" :disabled="editRowData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.ttl_org_amount" :disabled="editRowData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.tax_rate" :disabled="editRowData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.ttl_debit_amount" :disabled="editRowData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.tax_type" :disabled="editRowData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.pay_name" :disabled="editRowData.status_code === 3"/>
        </div>
      </template>
      <div
        :class="$style.btn"
        @click="addList"
        v-if="editRowData.status_code === 0"
      >
        <i class="el-icon-circle-plus-outline"/>新增欄位
      </div>
      <div :class="$style.bottomtitle">
        <div :class="[$style.border, $style.noneline]">
          <span :class="$style.text">零稅總額</span>
          <input :class="$style.input" :value="editRowData.total_zero_tax" :disabled="editRowData.status_code === 3"/>
        </div>
        <div :class="[$style.border, $style.noneline]">
          <span :class="$style.text">應稅金額</span>
          <input :class="$style.input" :value="editRowData.taxable_amount" :disabled="editRowData.status_code === 3"/>
        </div>
        <div :class="[$style.border, $style.noneline]">
          <span :class="$style.text">TAX (5%)</span>
          <input :class="$style.input" :value="editRowData.tax" :disabled="editRowData.status_code === 3"/>
        </div>
        <div :class="[$style.border, $style.noneline]">
          <span :class="$style.text">應稅總額</span>
          <input :class="$style.input" :value="editRowData.total_taxable" :disabled="editRowData.status_code === 3"/>
        </div>
        <div :class="[$style.border, $style.noneline]">
          <span :class="$style.text">應收總金額</span>
          <input :class="$style.input" :value="editRowData.total_amount_receivable" :disabled="editRowData.status_code === 3"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['taxInfo', 'deliveryDetailItems', 'editRowData'])
  },
  data () {
    return {
      listList: [{
        sequence_no: '',
        charge_code: '',
        charge_name: '',
        org_currency: '',
        chg_rate: '',
        chg_qty: '',
        chg_unit: '',
        ttl_org_amount: '',
        tax_rate: '',
        ttl_debit_amount: '',
        tax_type: ''
      }]
    }
  },
  methods: {
    addList () {
      this.deliveryDetailItems.push({
        sequence_no: '',
        charge_code: '',
        charge_name: '',
        org_currency: '',
        chg_rate: '',
        chg_qty: '',
        chg_unit: '',
        ttl_org_amount: '',
        tax_rate: '',
        ttl_debit_amount: '',
        tax_type: ''
      })
      // this.$store.commit('deliveryDetailItems', this.deliveryDetailItems)
      // console.log(this.deliveryDetailItems)
    },
    saveFile () {
    }
  }
}
</script>

<style lang="scss" module>
.root {
  align-self: center;
  width: 95%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: #fff;
  margin: 20px 0;
  display: grid;
  grid-auto-flow: column;
  grid-template-areas: 'nav input'
                      'bottom bottom';
  grid-template-columns: 150px 1fr;
  grid-auto-rows: minmax(350px, auto);
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.border {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding-left: 10px;
  font-size: 14px;
  color: #6d6b6b;
  box-sizing: border-box;

  &.borderconter {
    justify-content: center;
    padding: 0;
    text-align: center;
  }

  &.noneline {
    border-bottom: 0 solid #eaeaea;
  }
}

.left {
  display: grid;
  grid-area: nav;
}

.right {
  display: grid;
  border-left: 1px solid #eaeaea;
  grid-area: input;
}

.bottom {
  grid-area: bottom;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.input {
  background: #fff;
  border: none;
  border-bottom: 1px solid #eaeaea;
  box-shadow: none;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  color: #6d6b6b;

  &.inputborder {
    border-right: 1px solid #eaeaea;
  }
}

.title {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 35px;
}

.btn {
  align-self: flex-end;
  margin: 20px 10px;
  font-size: 12px;
  color: #6d6b6b;
  cursor: pointer;
}

.bottomtitle {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 35px;
  margin: 10px;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.text {
  width: 100px;
}
</style>
