// eslint-disable-next-line
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
const formlist = {
  state: {
    formlistSearchInputList: [{
      label: 'Job No',
      input: '',
      type: 'input',
      key: 'pre_book_no'
    }, {
      label: '船名Vessel',
      input: '',
      type: 'input',
      key: 'vsl_name'
    }, {
      label: '航次Voyage',
      input: '',
      type: 'input',
      key: 'voyage'
    }, {
      label: 'SO No',
      input: '',
      type: 'input',
      key: 'so_no'
    }, {
      label: 'HB/L No',
      input: '',
      type: 'input',
      key: 'o_bl_no'
    }, {
      label: '客戶名稱',
      input: '',
      type: 'input',
      key: 'customer_code'
    }, {
      label: 'Refrence No',
      input: '',
      type: 'input',
      key: 'reference_no'
    }, {
      label: '收貨港PLR',
      input: 'PLR',
      key: 'receipt_place',
      msg: ''
    }, {
      label: '卸貨港POD',
      input: 'POD',
      key: 'port_discharge',
      msg: ''
    }],
    formlistTableLabel: [{
      label: '單據檔',
      minWidth: 200,
      fixed: 'left',
      link: true,
      prop: 'file_name',
      node: 'edit',
      url: 'quotation',
      key: 'pre_book_no'
    }, {
      label: 'Job No',
      minWidth: 130,
      fixed: 'left',
      link: true,
      prop: 'pre_book_no',
      node: 'edit',
      url: 'shipment',
      key: 'pre_book_no'
    }, {
      label: '船名Vessel',
      minWidth: 200,
      prop: 'vsl_name'
    }, {
      label: '航次Voyage',
      minWidth: 130,
      prop: 'voyage'
    }, {
      label: '備註',
      minWidth: 120,
      prop: 'reference_no'
    }, {
      label: 'SO No',
      prop: 'so_no'
    }, {
      label: 'HB/L No',
      minWidth: 180,
      prop: 'o_bl_no'
    }, {
      label: '客戶中文名稱',
      minWidth: 200,
      prop: 'customer_code'
    }, {
      label: '結關日期',
      minWidth: 120,
      prop: 'closing_date'
    }, {
      label: '裝船日期',
      minWidth: 120,
      prop: 'onboard_date'
    }, {
      label: '卸貨港POD',
      minWidth: 150,
      prop: 'port_discharge'
    }, {
      label: '收貨港PLR',
      minWidth: 150,
      prop: 'receipt_place'
    }],
    formlistDetailTableLabel: [{
      label: 'version',
      prop: 'version',
      minWidth: 50
    }, {
      label: 'update_time',
      prop: 'update_time',
      minWidth: 50
    }, {
      label: 'uploader',
      prop: 'uploader',
      minWidth: 50
    }, {
      label: 'status',
      prop: 'status',
      minWidth: 50,
      fixed: 'left'
    }, {
      label: 'Edit',
      prop: 'Edit',
      icon: true,
      minWidth: 20,
      node: 'Edit',
      key: 'id'
    }, {
      label: 'View',
      prop: 'View',
      icon: true,
      minWidth: 20,
      node: 'View',
      key: 'id'
    }],
    formlistOrderid: ''
  },

  getters: {
    formlistTableLabel: (state) => state.formlistTableLabel,
    formlistOrderid: (state) => state.formlistOrderid,
    formlistDetailTableLabel: (state) => state.formlistDetailTableLabel,
    formlistMasterItem: (state) => state.formlistMasterItem,
    formlistSearchInputList: (state) => state.formlistSearchInputList
  },

  mutations: {
    formlistTableLabel: (state, payload) => { state.formlistTableLabel = payload },
    formlistOrderid: (state, payload) => { state.formlistOrderid = payload },
    formlistDetailTableLabel: (state, payload) => { state.formlistDetailTableLabel = payload },
    formlistMasterItem: (state, payload) => { state.formlistMasterItem = payload }
  },
  actions: {
    formlistInputList: async (state) => {
      state.commit('searchInputList', state.getters.formlistSearchInputList)
    },
    formlistSearch: async (state, payload) => {
      state.commit('searchInputList', state.getters.formlistSearchInputList)
      state.commit('tableLabel', [])
      state.commit('orderDataInfo', [])
      state.commit('orderId', '')
      console.log('formlistSearch', payload)
      const body = { offset: 0, limit: 10 }
      Object.values(state.getters.searchInputList).forEach(item => {
        // console.log('item', item)
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      console.log('formlistSearch body', body)
      var result = await axios.post('api/purchase/formlist/' + payload, body).then((response) => response)
      console.log('formlistSearch result', result)
      switch (result.status) {
        case 200:
          await Object.values(result.data.data.ListData).forEach(item => {
            item.Edit = faEdit
            item.View = faEye
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          switch (payload) {
            case 'QU':
              state.getters.formlistTableLabel[0].path = 'shipment'
              state.getters.formlistTableLabel[0].prop = 'QU_filename'
              state.getters.formlistTableLabel[0].prop2 = 'QU_filepath'
              state.getters.formlistTableLabel[1].url = 'shipment'
              state.getters.formlistTableLabel[1].showNode = 'none'
              break
            case 'SA':
              state.getters.formlistTableLabel[0].path = 'completeBooking'
              state.getters.formlistTableLabel[0].prop = 'SA_filename'
              state.getters.formlistTableLabel[0].prop2 = 'SA_filepath'
              state.getters.formlistTableLabel[1].url = 'completeBooking'
              state.getters.formlistTableLabel[1].showNode = 'none'
              break
            case 'MD':
              state.getters.formlistTableLabel[0].path = 'cargoMeasure'
              state.getters.formlistTableLabel[0].prop = 'MD_filename'
              state.getters.formlistTableLabel[0].prop2 = 'MD_filepath'
              state.getters.formlistTableLabel[1].url = 'cargoMeasure'
              state.getters.formlistTableLabel[1].showNode = 'none'
              break
            case 'SO':
              state.getters.formlistTableLabel[0].path = 'soReleased2'
              state.getters.formlistTableLabel[0].prop = 'SO_filename'
              state.getters.formlistTableLabel[0].prop2 = 'SO_filepath'
              state.getters.formlistTableLabel[1].url = 'soReleased'
              state.getters.formlistTableLabel[1].showNode = 'none'
              state.commit('soTab', 0)
              break
            case 'IV':
              state.getters.formlistTableLabel[0].path = 'soReleased0'
              state.getters.formlistTableLabel[0].prop = 'IV_filename'
              state.getters.formlistTableLabel[0].prop2 = 'IV_filepath'
              state.getters.formlistTableLabel[1].url = 'ivReleased'
              state.getters.formlistTableLabel[1].showNode = 'none'
              state.commit('soTab', 1)
              break
            case 'PL':
              state.getters.formlistTableLabel[0].path = 'soReleased1'
              state.getters.formlistTableLabel[0].prop = 'PL_filename'
              state.getters.formlistTableLabel[0].prop2 = 'PL_filepath'
              state.getters.formlistTableLabel[1].url = 'plReleased'
              state.getters.formlistTableLabel[1].showNode = 'none'
              state.commit('soTab', 2)
              break
            case 'HC':
              state.getters.formlistTableLabel[0].path = 'checkReleased'
              state.getters.formlistTableLabel[0].prop = 'HC_filename'
              state.getters.formlistTableLabel[0].prop2 = 'HC_filepath'
              state.getters.formlistTableLabel[1].url = 'checkReleased'
              state.getters.formlistTableLabel[1].showNode = 'none'
              break
            case 'HB':
              state.getters.formlistTableLabel[0].path = 'documentReleased0'
              state.getters.formlistTableLabel[0].prop = 'HB_filename'
              state.getters.formlistTableLabel[0].prop2 = 'HB_filepath'
              state.getters.formlistTableLabel[1].url = 'documentReleased'
              state.getters.formlistTableLabel[1].showNode = 'none'
              state.commit('soTab', 0)
              break
            case 'QC':
              state.getters.formlistTableLabel[0].path = 'quotationConfirmed'
              state.getters.formlistTableLabel[0].prop = 'QC_filename'
              state.getters.formlistTableLabel[0].prop2 = 'QC_filepath'
              state.getters.formlistTableLabel[1].url = 'quotationConfirmed'
              state.getters.formlistTableLabel[1].showNode = 'none'
              break
            case 'DN':
              state.getters.formlistTableLabel[0].path = 'documentReleased1'
              state.getters.formlistTableLabel[0].prop = 'DN_filename'
              state.getters.formlistTableLabel[0].prop2 = 'DN_filepath'
              state.getters.formlistTableLabel[1].url = 'documentReleased'
              state.getters.formlistTableLabel[1].showNode = 'none'
              state.commit('soTab', 1)
              break
          }
          state.commit('tableLabel', state.getters.formlistTableLabel)
          // await state.commit('orderDataInfo', orderDataInputList)
          // state.commit('tableLabel', state.getters.formlistDetailTableLabel)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
      console.log('tableData', state.getters.tableData)
      console.log('formlistSearch result', result)
    },
    formlistDetailSearch: async (state, payload) => {
      state.commit('tableLabel', [])
      // console.log('formlistSearch', payload)
      const body = { offset: 0, limit: 10 }
      Object.values(state.getters.searchInputList).forEach(item => {
        // console.log('item', item)
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      console.log('formlistSearch body', body)
      var result = await axios.post('api/purchase/formlist/' + payload.fileTpye, body).then((response) => response)
      console.log('formlistSearch result', result)
      const orderDataInputList = []
      Object.values(state.getters.formlistTableLabel).forEach(async (item, idx) => {
        if (idx > 1) {
          orderDataInputList.push({ label: item.label, input: state.getters.editRowData[item.prop], key: item.prop })
        }
      })

      switch (result.status) {
        case 200:
          var maxVersion = result.data.data.ListData.length > 0 ? result.data.data.ListData[0].version : 0 // Math.max(...result.data.data.ListData.map(item => item.version))
          console.log('maxVersion', maxVersion)
          await Object.values(result.data.data.ListData).forEach(item => {
            if (Number(item.version) === maxVersion) {
              item.max = true
            } else {
              item.max = false
            }
            item.Edit = faEdit
            item.View = faEye
            // item.messageid = state.getters.orderId
            item.button = true
          })
          state.commit('pageData', result.data.data.PageData)
          state.commit('tableData', result.data.data.ListData)
          state.commit('tableLabel', state.getters.formlistDetailTableLabel)
          // await state.commit('orderDataInfo', orderDataInputList)
          break
        default:
          state.commit('pageData', [])
          state.commit('tableData', [])
          await state.commit('orderDataInfo', [])
      }
      console.log('tableData', state.getters.tableData)
      console.log('formlistSearch result', result)
    },
    // beforEditByformlist: async (state, payload) => {
    //   var result = await formlistSingle({ pre_book_no: payload.orderId })
    //   console.log('beforEditByformlist', result)
    //   state.commit('formlistMasterItem', result.data.data.ListData[0])
    // },
    formlistHandleCurrentChange: async (state, payload) => {
      const currentPage = payload.currentPage
      var result = {}
      const body = {
        offset: (parseInt(currentPage) - 1) * parseInt(state.getters.pageData.limit),
        limit: 10
      }
      // if (JSON.stringify(state.getters.formlistQuery) !== '{}') {
      //   body.track_point = state.getters.formlistQuery.track_point
      // }
      Object.values(state.getters.searchInputList).forEach(item => {
        if (item.type === 'input' && item.input !== '') {
          body[item.key] = item.input
        }
      })
      result = await axios.post('api/purchase/formlist/' + payload.file_type, body).then((response) => response)
      result.data.data.PageData.totalPage = state.getters.pageData.totalPage
      result.data.data.PageData.currentPage = currentPage
      state.commit('pageData', result.data.data.PageData)
      state.commit('tableData', result.data.data.ListData)
      console.log('formlistHandleCurrentChange body', body)
      console.log('formlistHandleCurrentChange result', result)
    }
  }
}

export default formlist
