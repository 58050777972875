<template>
  <router-view :class="$style.app"/>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['tableData', 'pageData', 'loadingInstance'])
  },
  data () {
    return {
      lang: null
    }
  },
  mounted () {
    var self = this
    setInterval(() => {
      var currentOnlineSeconds = window.localStorage.getItem('onlineSeconds')
      if (currentOnlineSeconds === null) {
        window.localStorage.setItem('onlineSeconds', 60)
      } else {
        window.localStorage.setItem('onlineSeconds', parseInt(currentOnlineSeconds) + 60)
      }
      currentOnlineSeconds = window.localStorage.getItem('onlineSeconds')
      if (currentOnlineSeconds > 1800) {
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('pkCode')
        window.localStorage.removeItem('userid')
        window.localStorage.removeItem('lang')
        self.$router.push({ name: 'login' })
      }
    }, 60000)
    const storeLang = window.localStorage.getItem('lang')
    if (!storeLang) {
      const language = window.navigator.language.toLowerCase()
      switch (language) {
        case 'zh':
        case 'zh-TW':
        case 'zh-tw':
          this.lang = 'tw'
          break
        case 'zh-CN':
        case 'zh-cn':
          this.lang = 'cn'
          break
        default:
          this.lang = 'tw'
          break
      }
      window.localStorage.setItem('lang', this.lang)
      this.$i18n.locale = this.lang
    } else {
      this.$i18n.locale = storeLang
    }
  // },
  // watch: {
  //   tableData (val) {
  //     if (val !== [] && val.length > 0 && this.loadingInstance !== null) {
  //       this.$store.dispatch('loadingClose')
  //     } else {
  //       if (val.length < 1 && this.loadingInstance !== null) {
  //         this.$store.dispatch('loadingClose')
  //       }
  //     }
  //   }
  }
}
</script>

<style lang="scss" module>
.app {
  width: 100vw;
  height: 100vh;
  min-width: 1366px;
  overflow: auto;
}
</style>
<style>
.el-table th{
  background: #F7F7F9 !important;
}
</style>
