<template>
  <div :class="$style.root">
    <el-pagination
      @current-change="this.handleCurrentChange"
      :current-page="this.pageData.currentPage"
      :page-size="this.pageData.limit"
      layout="total, prev, pager, next"
      :total="this.pageData.count"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters(['pageData'])
  },
  data () {
    return {
    }
  },
  methods: {
    ...mapActions([
      'handleCurrentChange'
    ])
  }
}
</script>

<style lang="scss" module>
.root {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
