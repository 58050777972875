<template>
  <el-tabs v-model="active" @tab-click="switchData">
    <template v-for="(item, i) in menu">
      <el-tab-pane
        :label="$t(item.label)"
        :name="item.key"
        :key="i"
        :class="$style.menu"
      />
    </template>
  </el-tabs>
</template>

<script>
export default {
  data () {
    return {
      active: 0,
      menu: [{
        label: 'All'
      }, {
        label: 'Shipment'
      }, {
        label: 'CompleteBooking'
      }, {
        label: 'Delivery'
      }, {
        label: 'CargoMeasure'
      }, {
        label: 'SoReleased'
      }, {
        label: 'CustomsReleased'
      }, {
        label: 'CheckReleased'
      }, {
        label: 'QuotationConfirmed'
      }, {
        label: 'DocumentReleased'
      }, {
        label: 'Departure'
      }, {
        label: 'PouchReceived'
      }, {
        label: 'Arrival'
      }, {
        label: 'DoRelease'
      }]
    }
  },
  methods: {
    async switchData (item) {
      const body = { offset: 0, limit: 10 }
      if (item.index > 0) {
        body.track_point = item.index
        this.$store.commit('shipmentQuery', body)
        this.$store.dispatch('purchaseSearch', body)
      } else {
        this.$store.commit('shipmentQuery', {})
        this.$store.dispatch('purchaseSearch')
      }
    }
  }
}
</script>

<style lang="scss" module>
.menu {
  margin-bottom: 15px;
}
</style>
