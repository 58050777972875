<template>
  <div :class="$style.root">
    <manipulate :class="$style.maipulate">
      <template #manipulate>
        <inputComponent v-for="(item, i) in filterInputList" :key="i" >
          <template #title v-if="item.type==='input' || item.type==='pouch' || item.type==='date' || item.type === 'filter'">
            <div :class="$style.searchTitle">{{ $t(item.label) }}</div>
          </template>
          <template #input v-if="(item.type==='input' || item.type==='pouch' || item.type==='date' || item.type === 'filter')">
            <el-input v-if="item.type === 'input'"
              :class="$style.input"
              v-model="item.input"
            />
            <el-select :class="$style.input" v-if="item.type === 'filter'" v-model="item.input" @change="changeDateFilter">
              <el-option
                v-for="(opt, optKey) in item.options"
                :key="optKey"
                :label="opt.name"
                :value="opt.code"
              />
            </el-select>
            <el-select v-model="item.input" placeholder="請選擇" v-if="item.type === 'pouch'">
              <el-option
                v-for="opt in options"
                :key="opt.value"
                :label="opt.label"
                :value="opt.value">
              </el-option>
            </el-select>
            <el-date-picker
              v-if="item.type === 'date'"
              :class="$style.input"
              v-model="item.input"
              value-format="yyyy-MM-dd"
              :default-value="defaultDateValue()"
              type="date"
              :picker-options="pickerOptions(item.key)"
              placeholder="選擇日期">
            </el-date-picker>
          </template>
          <template #msg v-if="item.type==='input'">
            {{ $t(item.msg) }}
          </template>
        </inputComponent>
      </template>
    </manipulate>
    <div :class="$style.queryBtnArea">
      <div :class="$style.historyCheckbox">
        <el-checkbox v-model="queryHistory" v-if="$route.path === '/list/list'">查詢歷史數據</el-checkbox>
        <div :class="$style.historyAdditional" v-if="$route.path === '/list/list'">「本查詢依據「結關日」，顯示近3個月的資料（不含當月）顯示4個月資料；如要查詢3個月前資料，請點擊「歷史資料查詢」按鈕，進入歷史資料查詢」</div>
      </div>
      <el-button @click="qry"  style="margin-left:20px">查詢</el-button>
    </div>
  </div>
</template>

<script>
import manipulate from '@/components/card/manipulate.vue'
import inputComponent from '@/components/card/input.vue'
export default {
  props: {
    inputList: Array
  },
  components: {
    manipulate,
    inputComponent
  },
  computed: {
    queryHistory: {
      get () {
        return this.$store.state.flagQueryHistory
      },
      set (val) {
        this.$store.commit('flagQueryHistory', val)
      }
    },
    pouchInput: function () {
      var isPouch = false
      switch (this.$router.currentRoute.name) {
        case 'spouchdc':
        case 'spouchmb':
        case 'spouchma':
        case 'spouchhb':
        case 'spouchiv':
        case 'spouchpl':
          isPouch = true
          break
      }
      return isPouch
    },
    filterInputList: function () {
      return this.inputList.filter(item => ['input', 'pouch', 'date', 'filter'].indexOf(item.type) > -1 && !item.hidden)
    }
  },
  data () {
    return {
      options: [
        {
          value: 'DC',
          label: '國外代理帳單'
        }, {
          value: 'MB',
          label: 'MB/L(主提單)'
        }, {
          value: 'MA',
          label: 'Manifest(艙單)'
        }, {
          value: 'HB',
          label: '(分提單) HB/L'
        }, {
          value: 'IV',
          label: 'Invoice'
        }, {
          value: 'PL',
          label: 'Packing List'
        }
      ],
      value: 'DC'
    }
  },
  methods: {
    defaultDateValue () {
      var d = null
      var dstr = ''
      if (this.queryHistory) {
        d = new Date((Date.now() - 86400000 * 122))
      } else {
        d = new Date()
      }
      dstr = d.toISOString().slice(0, 10)
      return dstr
    },
    pickerOptions () {
      var self = this
      return {
        disabledDate (time) {
          if (self.queryHistory) {
            return time.getTime() > (Date.now() - 86400000 * 122)
          } else {
            return time.getTime() < (Date.now() - 86400000 * 122)
          }
        }
      }
    },
    changeDateFilter (e) {
      var filterVal = ''
      var filterOpts = []
      this.inputList.forEach(item => {
        if (item.key === 'date_filter') {
          filterVal = item.input
          filterOpts = item.options
        }
        filterOpts.forEach(opt => {
          if (opt.code + '_start' === item.key || opt.code + '_end' === item.key) {
            if (item.key === filterVal + '_start' || item.key === filterVal + '_end') {
              item.hidden = false
            } else {
              item.hidden = true
              item.input = ''
            }
          }
        })
      })
    },
    qry () {
      // console.log('!!!')
      this.$emit('qry')
    }
  },
  mounted () {
    console.log(this.$route)
  }
}
</script>
<style lang="scss" module>
.root {
  margin-bottom: 10px;
}
.titleText{
  font-size:12px;
}
.maipulate {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 12px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.input {
  :global(.el-input__inner) {
    height: 30px !important;
    line-height: 30px;
  }
  :global(.el-input__icon) {
    height: 30px;
    line-height: 30px;
  }
}
.btn {

  &:global(.el-button) {
    padding: 8px 12px;
    font-size: 12px;
  }
}
.searchTitle{
  color: #25282b;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 10px;
}
.queryBtnArea{
  text-align: right;
}
.historyCheckbox{
  text-align: left;
  float: left
}
.historyAdditional{
  color: #999999;
  font-size: 12px;
  line-height: 1.5;
  margin-left: 0px;
}
</style>
