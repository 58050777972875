<template>
  <div :class="$style.root">
    <datainput v-for="(item, i) in orderDataInfo" :key="i" :fixWidth="64">
      <template #title>
        {{ $t(item.label) }}
      </template>
      <template #data>
       {{ item.input }}
      </template>
    </datainput>
  </div>
</template>

<script>
import datainput from '@/components/card/datainput.vue'
export default {
  props: {
    orderDataInfo: Array
  },
  components: {
    datainput
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" module>
.root {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 22.5px;
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
