import axios from 'axios'

export const customerCreate = async (body) => await axios.post('api/customer/create', body).then((response) => response)// 客戶商業夥伴新增

export const customerList = async (body) => await axios.post('api/customer/customerlist', body).then((response) => response)// 客戶列表

export const customerDelete = async (body) => await axios.delete('api/customer/delete', body).then((response) => response)// 客戶商業夥伴刪除

export const customerPartnerlist = async (body) => await axios.post('api/customer/list', body).then((response) => response)// 客戶商業夥伴列表

export const supplierlist = async (body) => await axios.post('api/customer/supplierlist', body).then((response) => response)// 商業夥伴列表

export const customerUpdate = async (body) => await axios.put('api/customer/update', body).then((response) => response)// 訂單修改

export const pdfEmails = async (body) => await axios.post('api/user/customer_email_list', body).then((response) => response)
