<template>
  <div :class="$style.root">
    <label
      tag="div"
      for="updata"
      :class="[$style.btn, 'el-button', 'el-button--default',$style.addPdfBtn]"
    >
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.94444 0.694444C6.94444 0.310913 6.63353 0 6.25 0C5.86647 0 5.55556 0.310913 5.55556 0.694444V5.55556H0.694444C0.310913 5.55556 0 5.86647 0 6.25C0 6.63353 0.310913 6.94444 0.694444 6.94444H5.55556V11.8056C5.55556 12.1891 5.86647 12.5 6.25 12.5C6.63353 12.5 6.94444 12.1891 6.94444 11.8056V6.94444H11.8056C12.1891 6.94444 12.5 6.63353 12.5 6.25C12.5 5.86647 12.1891 5.55556 11.8056 5.55556H6.94444V0.694444Z" fill="white"/>
    </svg>
      新增
    </label>
    <input
      ref="updata"
      id="updata"
      type="file"
      multiple
      accept="application/pdf"
      @change="onChange"
      v-if="showUpload"
    />
    <!-- <el-select v-model="emails" class="pdfEmailList" multiple placeholder="請選擇" v-if="pdfEmailList.length > 0">
      <el-option
        v-for="(item, k) in pdfEmailList"
        :key="k"
        :label="item.label"
        :value="item.id">
        <span>{{ item.label }}</span>
        <span style="float: right; color: #8492a6; font-size: auto">{{ item.companyName }}</span>
      </el-option>
    </el-select> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters([
      'showUpload',
      'pdfEmailList'
    ])
  },
  data () {
    return {
      emails: []
    }
  },
  methods: {
    onChange () {
      console.log(this.$refs, '!!!!!!!!!!!!!!!!!!!!!!', this.$refs.updata.files)
      const files = [...this.$refs.updata.files]
      this.$store.commit('pafupdata', files[0])
      this.$store.commit('showUpload', false)
      this.$emit('updataPdf', this.emails)
      this.emails = []
      // this.$refs.updata.files = ''
      this.$refs.updata.values = ''
    }
  }
}
</script>

<style lang="scss" module>
.addPdfBtn{
  height:30px;
}
.pdfEmailList{
  width:auto;
}
input[type="file"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.root {
  display: flex;
  margin-left: 10px;
}

.btn {
  background-color: #4BA676 !important;
  color: #ffffff !important;
  height: 34px;
  vertical-align: middle;
  &:global(.el-button) {
    padding: 8px 12px;
    font-size: 12px;
  }
}
</style>
