<template>
  <div>
    <el-dialog title="私鑰密碼" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form >
        <el-alert
          v-if="success"
          :title="message"
          type="success"
          show-icon>
        </el-alert>
        <el-alert
          v-if="error"
          :title="message"
          type="error"
          show-icon>
        </el-alert>
        <el-form-item label="密碼" :label-width="formLabelWidth">
          <el-input type="password" v-model="password" placeholder='至少1.八位數 2.大小寫字母、數字、特殊符號組成的私鑰密碼' ></el-input>
          <label class="text-reader">
            <input type="file" accept=".json" ref="keyFile" v-if="checkFlag" @change="handlePreview" :class="$style.uploadKeyFile">
          </label>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="display: grid; grid-template-columns: 1fr max-content max-content; align-items: center">
        <el-button style="width: 150px;" @click="$emit('close')">取 消</el-button>
        <el-button type="primary" @click="checkKey">匯入私鑰</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { checkKeyAddress } from '@/api/privateKey'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['privateKeyCode'])
  },
  props: {
    dialogFormVisible: Boolean
  },
  data () {
    return {
      checkFlag: true,
      keyCode: '',
      keyFileData: {},
      password: '',
      uploadFile: {
        name: ''
      },
      fileFlag: true,
      mapRows: [],
      fileList: [],
      formLabelWidth: '120px',
      error: false,
      message: '',
      success: false,
      checkSuccess: false,
      checked: true
    }
  },
  methods: {
    checkPassword () {
      return this.password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)
    },
    async checkKey (keyData) {
      if (!this.uploadFile.name.match('json')) {
        this.error = true
        this.message = '請選擇正確的私鑰檔'
        return
      }
      if (!this.checkPassword()) {
        this.error = true
        this.message = '至少1.八位數 2.大小寫字母、數字組成的私鑰密碼'
        return
      }
      const rsp = await checkKeyAddress({
        address: this.password,
        jsonData: this.keyFileData,
        auto_chain_flag: this.checked
      })
      console.log(rsp)
      if (typeof rsp.error !== 'undefined') {
        this.error = true
        this.message = rsp.error
      } else {
        this.keyCode = rsp.success
        this.$store.commit('privateKeyCode', rsp.success)
        window.localStorage.setItem('pkCode', rsp.success)
        this.error = false
        this.dialogFormVisible = false
        this.success = true
        this.message = '私鑰正確'
        this.$refs.keyFile.value = ''
        this.password = ''
        this.$message({
          type: 'success',
          message: '私鑰正確'
        })
      }
      console.log(rsp)
    },
    handlePreview (ev) {
      const self = this
      const file = ev.target.files[0]
      console.log('handlePreview', file)
      this.uploadFile = file
      const reader = new FileReader()
      reader.onload = function (event) {
        try {
          var parseRow = JSON.parse(event.target.result)
          self.keyFileData = parseRow
          self.keyFileData.userid = window.localStorage.getItem('userid')
          self.keyFileData.account = window.localStorage.getItem('name')
          console.log(parseRow)
        } catch (e) {
          self.keyFileData = {}
          this.error = true
          this.message = '請選擇正確的私鑰檔案'
        }
      }
      reader.onerror = error => {
        this.error = true
        this.message = error
      }
      reader.readAsText(file) // you could also read images and other binaries
    }
  },
  mounted: function () {
    this.$store.commit('privateKeyCode', window.localStorage.getItem('pkCode'))
    this.uploadFile = {
      name: ''
    }
    this.error = false
    this.success = false
    this.checkSuccess = false
  }
}
</script>
<style lang="scss" module>

.popup {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 14px;

  .title {
    font-size: 18px;
    color: #8a8a8a;
    margin-bottom: 5px;
    border-bottom: 2px solid #e6e6e6;
    padding-bottom: 5px
  }
}
.text {
  color: #adadad;
  font-size: 14px;
  padding: 3px 0;
}
.uploadKeyFile{
  margin-left: 15px !important;
  width: auto !important;
  height: 30px !important;
  position: relative !important;
}
</style>
