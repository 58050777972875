<template>
  <div :class="$style.root">
    <manipulate :class="$style.maipulate">
      <template #manipulate>
        <inputComponent
          v-for="(item, i) in searchInputList"
          :key="i"
        >
          <template #title>
            {{ item.label }}
          </template>
          <template #input>
            <el-input
              v-if="item.type === 'input'"
              :class="$style.input"
              v-model="item.input"
              @change="searchHandler"
            />
            <el-select
              :class="$style.select"
              v-model="item.input"
              placeholder="請選擇"
              v-else
              @change="searchHandler"
            >
              <el-option
                v-for="(itemOption, i) in $route.name === 'customermanagement' ? item.clientOptions : item.businessOptions"
                :key="i"
                :label="itemOption.label"
                :value="itemOption.val"
              />
            </el-select>
          </template>
        </inputComponent>
      </template>
    </manipulate>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import manipulate from '@/components/card/manipulate.vue'
import inputComponent from '@/components/card/input.vue'
export default {
  computed: {
    ...mapGetters(['searchInputList'])
  },
  components: {
    manipulate,
    inputComponent
  },
  methods: {
    ...mapActions(['searchHandler']),
    onChange (item) {
      console.log('item', item)
    }
  },
  data () {
    return {
      inputList: []
    }
  }
}
</script>

<style lang="scss" module>
.root {
  margin-bottom: 10px;
}

.maipulate {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 12px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.input {
  :global(.el-input__inner) {
    height: 30px;
  }
}

.select {
  :global(.el-input__inner) {
    height: 30px;
  }

  :global(.el-input__icon) {
    line-height: 30px;
  }
}
</style>
