<template>
  <div>
    <orderdata
    :class="$style.margin"
    :orderId="orderId"
    :orderDataInfo="orderDataInfo"/>
    <router-view/>
  </div>
</template>

<script>
import orderdata from '@/views/list/list/components/orderdata.vue'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['orderId', 'orderDataInfo'])
  },
  components: {
    orderdata
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" module>
.margin {
  margin-bottom: 14px;
}
</style>
