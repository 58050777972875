<template>
  <div>
    <quotationComponent/>
  </div>
</template>

<script>
// import orderdata from '@/views/list/list/components/orderdata.vue'
import quotationComponent from '@/components/order/quotationComponent.vue'

export default {
  props: {
    orderId: String,
    orderDataInfo: Array
  },
  components: {
    quotationComponent
  }
}
</script>

<style lang="scss" module>
.margin {
  margin-bottom: 14px;
}
</style>
