<template>
  <div :class="$style.root">
    <div :class="$style.left">
      <div :class="$style.border">
        JobNo
      </div>
      <div :class="$style.border">
        帳單號碼
      </div>
      <div :class="$style.border">
        客戶代碼
      </div>
      <div :class="$style.border">
        零稅總額
      </div>
      <div :class="$style.border">
        應稅金額
      </div>
      <div :class="$style.border">
        稅金
      </div>
      <div :class="$style.border">
        應稅總額
      </div>
      <div :class="$style.border">
        應收總金額
      </div>
    </div>
    <div :class="$style.right">
      <input :class="$style.input" v-model="editRowData.pre_book_no" :disabled="true"/>
      <input :class="$style.input" v-model="editRowData.debit_no" :disabled="true"/>
      <input :class="$style.input" v-model="editRowData.company_code" :disabled="true"/>
      <input :class="$style.input" v-model="editRowData.zero_tax_amt" :disabled="true"/>
      <input :class="$style.input" v-model="editRowData.duty_tax_amt" :disabled="true"/>
      <input :class="$style.input" v-model="editRowData.tax_amt" :disabled="true"/>
      <input :class="$style.input" v-model="editRowData.ttl_duty_amt" :disabled="true"/>
      <input :class="$style.input" v-model="editRowData.debit_amt" :disabled="true"/>
    </div>
    <div :class="$style.bottom">
      <div :class="$style.title">
        <div :class="[$style.border, $style.borderconter]">
          序號
        </div>
        <div :class="[$style.border, $style.borderconter]">
          收費代碼
        </div>
        <div :class="[$style.border, $style.borderconter]">
          收費名稱
        </div>
        <div :class="[$style.border, $style.borderconter]">
          報價幣別
        </div>
        <div :class="[$style.border, $style.borderconter]">
          單價
        </div>
        <div :class="[$style.border, $style.borderconter]">
          數量
        </div>
        <div :class="[$style.border, $style.borderconter]">
          單位
        </div>
        <div :class="[$style.border, $style.borderconter]">
          應收金額
        </div>
        <div :class="[$style.border, $style.borderconter]">
          匯率
        </div>
        <div :class="[$style.border, $style.borderconter]">
          帳單金額
        </div>
        <div :class="[$style.border, $style.borderconter]">
          是否上稅
        </div>
        <div :class="[$style.border, $style.borderconter]">
          發票號碼
        </div>
      </div>
      <template v-for="(item, i) in editRowData.detail">
        <div :class="$style.title" :key="i">
          <input :class="[$style.input, $style.inputborder]" v-model="item.sequence_no" :disabled="true"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.charge_code" :disabled="true"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.charge_name" :disabled="true"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.org_currency" :disabled="true"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.chg_rate" :disabled="true"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.chg_qty" :disabled="true"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.chg_unit" :disabled="true"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.ttl_ar_amt" :disabled="true"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.tax_rate" :disabled="true"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.ttl_debit_amt" :disabled="true"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.tax_type" :disabled="true"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.invoice_no" :disabled="true"/>
        </div>
      </template>
     </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['editRowData'])
  },
  data () {
    return {
      listList: [{
      }]
    }
  },
  methods: {
    addList () {
      this.listList.push({})
    }
  }
}
</script>

<style lang="scss" module>
.root {
  align-self: center;
  width: 95%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: #fff;
  margin: 20px 0;
  display: grid;
  grid-auto-flow: column;
  grid-template-areas: 'nav input'
                      'bottom bottom';
  grid-template-columns: 150px 1fr;
  grid-auto-rows: minmax(350px, auto);
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.border {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding-left: 10px;
  font-size: 14px;
  color: #6d6b6b;
  box-sizing: border-box;

  &.borderconter {
    justify-content: center;
    padding: 0;
    text-align: center;
  }

  &.noneline {
    border-bottom: 0 solid #eaeaea;
  }
}

.left {
  display: grid;
  grid-area: nav;
  grid-auto-rows: 35px;
}

.right {
  display: grid;
  border-left: 1px solid #eaeaea;
  grid-area: input;
  grid-auto-rows: 35px;
}

.bottom {
  grid-area: bottom;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.input {
  background: #fff;
  border: none;
  border-bottom: 1px solid #eaeaea;
  box-shadow: none;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  color: #6d6b6b;

  &.inputborder {
    border-right: 1px solid #eaeaea;
  }
}

.title {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 35px;
}

.btn {
  align-self: flex-end;
  margin: 20px 10px;
  font-size: 12px;
  color: #6d6b6b;
  cursor: pointer;
}

.bottomtitle {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 35px;
  margin: 10px;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.text {
  width: 100px;
}
</style>
