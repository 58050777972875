<template>
  <div>
    <orderdata
    :class="$style.margin"
    :orderId="orderId"
    :orderDataInfo="orderDataInfo"/>
    <orderstatus :class="$style.margin" v-if="showNode === 'display' && (userRole === 'IO' || userRole === 'OP' || userRole === 'KF' || userRole === 'SA' || userRole === 'LC' || userRole === 'FW' || userRole === 'AC')"/>
    <router-view/>
  </div>
</template>

<script>
import orderdata from '@/views/list/list/components/orderdata.vue'
import orderstatus from '@/views/list/list/components/orderstatus.vue'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['orderId', 'orderDataInfo', 'showNode', 'userData'])
  },
  components: {
    orderdata,
    orderstatus
  },
  data () {
    return {
      userRole: null
    }
  },
  created () {
    this.userRole = localStorage.getItem('basic_type')
    console.log('nice', this.userRole)
  }
}
</script>

<style lang="scss" module>
.margin {
  margin-bottom: 14px;
}
</style>
