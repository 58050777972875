<template>
  <inputlist
  :inputList="searchInputList"
  @qry="search"/>
</template>

<script>
import inputlist from '@/components/plug/inputlist.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['searchInputList'])
  },
  components: {
    inputlist
  },
  data () {
    return {}
  },
  methods: {
    ...mapActions([
      // 'setSearchCriteria',
      'searchHandler'
    ]),
    search (val) {
      // console.log(val)
      console.log(this.searchInputList)
      // this.setSearchCriteria(this.searchInputList)
      this.searchHandler()
    }
  }
}
</script>

<style lang="scss" module>
.card {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
</style>
