import Authoritylayout from '@/views/systemfile/authoritylayout.vue'
import Usertable from '@/views/systemfile/authority/usertable.vue'
import Grouptable from '@/views/systemfile/authority/grouptable.vue'
import Usermanagement from '@/views/systemfile/usermanagement.vue'

export default [{
  path: 'authority',
  component: Authoritylayout,
  redirect: { name: 'userauthority' },
  children: [{
    path: 'userauthority',
    name: 'userauthority',
    component: Usertable,
    meta: { title: '系統管理-權限管理', class: 'authority' }
  }, {
    path: 'groupauthority',
    name: 'groupauthority',
    component: Grouptable,
    meta: { title: '系統管理-權限管理', class: 'authority' }
  }]
}, {
  path: 'usermanagement',
  name: 'usermanagement',
  component: Usermanagement,
  meta: { title: '系統管理-使用者管理', class: 'usermanagement' }
}]
