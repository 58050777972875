<template>
  <el-table
    :data="tableData"
    tooltip-effect="dark"
    :class="$style.table"
    empty-text="無數據資料"
    :border="true"
  >
    <el-table-column
      type="selection"
      width="50"
      align="center"
      fixed="left"
    />
    <el-table-column
      label="Vessel Name (船名)"
      align="center"
      prop="vesselName"
      min-width="200"
    />
    <el-table-column
      label="Voyage (航次)"
      prop="voyage"
      align="center"
      min-width="130"
    />
    <el-table-column
      label="貨櫃場代碼"
      prop="sono"
      align="center"
    />
    <el-table-column
      label="公證行代碼"
      prop="HBLNo"
      align="center"
      min-width="180"
    />
    <el-table-column
      label="Voy ID"
      prop="client"
      align="center"
      min-width="200"
    />
    <el-table-column
      label="建立日"
      prop="end"
      align="center"
      min-width="120"
    />
  </el-table>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        index: 'E21030004',
        vesselName: 'OSG BOSSTEC',
        voyage: 'V-2107W',
        sono: '9396',
        HBLNo: 'MPKESTI2120010',
        client: 'FKOL0001/歳運物流股',
        end: '-',
        up: '-',
        startship: 'CUL',
        endship: 'FPM',
        status: '完成訂艙'
      }, {
        index: 'E21024738',
        vesselName: 'FORMOSA CONTAINER',
        voyage: 'V-4579W',
        sono: '0455',
        HBLNo: 'PTXTAP2120003',
        client: 'FKOL0001/歳運物流股',
        end: '-',
        up: '-',
        startship: 'FPM',
        endship: 'TSS',
        status: '完成訂艙'
      }, {
        index: 'E21024156',
        vesselName: 'YM INCEPTION',
        voyage: 'V-175S',
        sono: '0213',
        HBLNo: 'PKETAP2120010',
        client: 'FKOL0001/歳運物流股',
        end: '-',
        up: '-',
        startship: 'CUL',
        endship: 'FPM',
        status: '完成訂艙'
      }, {
        index: 'E21023586',
        vesselName: 'KANWAY GLOBAL',
        voyage: 'V-2108S',
        sono: '0255',
        HBLNo: 'PKETAP2120009',
        client: 'FKOL0001/歳運物流股',
        end: '-',
        up: '-',
        startship: 'CUL',
        endship: 'WHL',
        status: '完成訂艙'
      }, {
        index: 'E21022407',
        vesselName: 'TS HONGKONG',
        voyage: 'V-21008S',
        sono: '9176',
        HBLNo: 'MPTNHUP2120001',
        client: 'FKOL0001/歳運物流股',
        end: '-',
        up: '-',
        startship: 'FPM',
        endship: 'WHL',
        status: '完成訂艙'
      }]
    }
  }
}
</script>

<style lang="scss" module>
.table {
  :global(.el-table__body-wrapper) {
    min-height: 475px;
  }
}
</style>
