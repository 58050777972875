<template>
  <div>
    <card>
      <template #title>
        {{ $route.meta.title }}
      </template>
      <template #titleBtn>
        <btnList
          @add="adduser"
          :hasAddbtn="true"
        />
      </template>
      <template #manipulatlist>
        <inputlist/>
      </template>
      <template #table>
        <tableComponent @edit="popupfunction" @delete="deleteUser" />
      </template>
      <template #pagination>
        <pageination/>
      </template>
    </card>
    <userpopup
      v-if="displayInfo"
      :titleBtnText="titleBtnText"
      @close="closeUserPopup()"
      :action="action"
      :rowData="rowData"
    />
  </div>
</template>

<script>
import card from '@/components/card/card.vue'
import pageination from '@/components/plug/page/pageination.vue'
import inputlist from '@/views/systemfile/components/inputlist.vue'
import btnList from '@/components/plug/button/button.vue'
import tableComponent from '@/views/systemfile/components/table.vue'
import userpopup from '@/views/systemfile/popup/userpopup.vue'
import { userAction } from '@/api/user'
export default {
  components: {
    card,
    pageination,
    inputlist,
    btnList,
    tableComponent,
    userpopup
  },
  data () {
    return {
      componentName: null,
      displayInfo: false,
      titleBtnText: '',
      rowData: {},
      action: ''
    }
  },
  methods: {
    closeUserPopup () {
      this.$store.dispatch('userManagementSearch')
      this.displayInfo = false
    },
    popupfunction (value, row) {
      this.displayInfo = true
      this.titleBtnText = value
      this.rowData = row
      this.action = 'edit'
      console.log('value', value)
      console.log('row', row)
    },
    adduser () {
      console.log('adduser')
      this.displayInfo = true
      this.titleBtnText = '新增'
      this.action = 'add'
      this.rowData = {}
    },
    async deleteUser (row) {
      console.log(row)
      if (!confirm('確定要刪除會員:' + row.name)) {
        return
      }
      var body = {
        action: 'D',
        id: row.id
      }
      const r = await userAction(body)
      switch (r.code) {
        case '200':
          this.$message({
            type: 'success',
            message: '刪除成功'
          })
          await this.$store.dispatch('userManagementInputList')
          await this.$store.dispatch('userManagementSearch')
          break
        case '400':
          this.$message({
            type: 'error',
            message: '刪除失敗(' + r.detail + ')'
          })
          break
        default:
          this.$message({
            type: 'info',
            message: '刪除失敗(' + r.detail + ')'
          })
      }
    }
  }
}
</script>

<style lang="scss" module>
</style>
