<template>
  <div>
    <card>
      <template #title>
        <span v-if="flagQueryHistory && $route.path === '/list/list'">{{ $t($route.meta.title) }}(歷史資料)</span>
        <span v-else>{{ $t($route.meta.title) }}</span>
      </template>
      <!--
      <template #titleBtn>
        <btnList
          @add="addData"
          @del="deleteData"
          :hasAddbtn="true"
        />
      </template>
      -->
      <template #manipulatlist>
        <tabnav v-if="$route.meta.btnList"/>
        <searchinput v-if="$route.meta.inputType === 'common'"/>
        <inputlist v-else-if="$route.meta.inputType === 'set'"/>
      </template>
      <template #table>
        <tableComponent @edit="editfunction" @search="searchHandler" v-if="$route.meta.inputType === 'common' || $route.meta.inputType === ''"/>
        <tableComponent2 v-else />
      </template>
      <template #pagination>
        <pageination/>
      </template>
    </card>
    <addlist v-if="showAddList(addlist, $route.path)" @close="addlist = false"/>
  </div>
</template>

<script>
import card from '@/components/card/card.vue'
import tabnav from '@/components/plug/list/nav.vue'
import pageination from '@/components/plug/page/pageination.vue'
// import btnList from '@/components/plug/button/button.vue'
import addlist from '@/views/list/list/components/addlist.vue'
import tableComponent from '@/views/list/list/components/table.vue'
import tableComponent2 from '@/views/mainfile/components/table.vue'
import searchinput from '@/components/share/searchinput.vue'
import inputlist from '@/components/share/inputlist.vue'

import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    card,
    pageination,
    // btnList,
    addlist,
    tableComponent,
    tabnav,
    searchinput,
    inputlist,
    tableComponent2
  },
  computed: {
    ...mapGetters(['orderId', 'orderDataInfo', 'searchInputList', 'flagQueryHistory'])
  },
  data () {
    return {
      addlist: false
    }
  },
  methods: {
    ...mapActions(['delTableSelectData', 'searchHandler']),
    showAddList (flag, path) {
      switch (path) {
        case '/sshippingprice':
          // console.log('flag', flag, 'path', path)
          return flag
        default:
          break
      }
    },
    editfunction (row, item) {
      console.log('list vue editfunction row', row, 'item', item)
      // console.log('item.url', item.url)
      // console.log('item.path', item.path)
      this.$store.commit('editRowData', row)
      const fileType = 'pdf'
      const operating = 'view'
      var spouchUrl = ''
      this.$store.commit('pre_book_no', row.pre_book_no)
      this.$store.commit('si_no', row.so_no)
      this.$store.commit('showNode', 'display')
      switch (item.url) {
        case 'list':
          this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display' } })
          break
        case 'quotation':
          // console.log('item.path !== undefined', item.path !== undefined)
          // console.log(' row[item.prop2]', row[item.prop2])
          if (typeof row.sender !== 'undefined') {
            const basicCode = String(window.localStorage.getItem('basic_code')).trim().toLowerCase()
            if (basicCode === row.sender.basic_code) {
              row.currentIdentity = 'sender'
            }
            if (basicCode === row.receiver_1.basic_code) {
              row.currentIdentity = 'receiver_1'
            }
            if (basicCode === row.receiver_2.basic_code) {
              row.currentIdentity = 'receiver_2'
            }
            this.$store.commit('infoData', row)
            console.log('quotation basic code:', basicCode)
          }
          if (item.path !== undefined) {
            this.$store.commit('pdfUrl', this.globalPDFHost + row[item.prop2])
          }
          switch (item.path) {
            case 'shipment':
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', titleA: '表單管理-出貨通知單', titleB: '出貨通知單', path: 'shipment' } })
              break
            case 'completeBooking':
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', titleA: '表單管理-裝船通知書', titleB: '裝船通知書', path: 'completeBooking' } })
              break
            case 'cargoMeasure':
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', titleA: '表單管理-丈量證明文件', titleB: '丈量證明文件', path: 'cargoMeasure' } })
              break
            case 'soReleased0':
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', titleA: '表單管理-Invoice', titleB: 'Invoice', path: 'soReleased0' } })
              break
            case 'soReleased1':
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', titleA: '表單管理-裝櫃清單', titleB: '裝櫃清單', path: 'soReleased1' } })
              break
            case 'soReleased2':
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', titleA: '表單管理-HB/L 提單做法', titleB: 'HB/L 提單做法', path: 'soReleased2' } })
              break
            case 'checkReleased':
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', titleA: '表單管理-HB/L 核對單', titleB: 'HB/L 核對單', path: 'soReleased1' } })
              break
            case 'documentReleased0':
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', titleA: '表單管理-HB/L 提單', titleB: 'HB/L 提單', path: 'documentReleased0' } })
              break
            case 'documentReleased1':
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', titleA: '表單管理-客戶帳單', titleB: '客戶帳單', path: 'documentReleased1' } })
              break
            case 'quotationConfirmed':
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', titleA: '表單管理-報價確認單', titleB: '報價確認單', path: 'quotationConfirmed' } })
              break
            case 'measure':
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', ship_num: row.ship_num, titleA: '表單管理-貨物丈量單', titleB: '貨物丈量單', path: 'measure' } })
              break
            case 'containerload':
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', ship_num: row.ship_num, vsl_name: row.vsl_name, voyage: row.voyage, cfs_delivery_place: row.cfs_delivery_place, port_discharge: row.port_discharge, titleA: '表單管理-裝櫃清單', titleB: '裝櫃清單', path: 'containerload' } })
              break
            case 'mblsi':
              this.$store.commit('pdfUrl', this.globalPDFHost + row[item.prop2].si)
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', ship_num: row.ship_num, vsl_name: row.vsl_name, voyage: row.voyage, carrier_agent: row.carrier_agent, so_no: row.so_no, titleA: '表單管理-MB/L 提單做法(SI)', titleB: 'MB/L 提單做法', path: 'mblsi' } })
              break
            case 'mbl':
              this.$store.commit('pdfUrl', this.globalPDFHost + row[item.prop2].mc)
              this.$router.push({ name: item.url, params: { dc_no: row.dc_no, orderId: row[item.key], fileType, operating, showNode: 'display', ship_num: row.ship_num, titleA: '表單管理-MB/L 核對單', titleB: 'MB/L 核對單', path: 'mbl' } })
              break
            case 'spouchdc':
              this.$router.push({ name: item.url, params: { dc_no: row.dc_no, orderId: row.dc_no, fileType, operating, showNode: 'display', titleA: '表單管理-POUCH-代理帳單', titleB: 'POUCH-代理帳單', path: 'spouchdc' } })
              break
            case 'spouchmb':
              this.$router.push({ name: item.url, params: { dc_no: row.dc_no, orderId: row.dc_no, fileType, operating, showNode: 'display', titleA: '表單管理-POUCH-MBL(主提單)', titleB: 'POUCH-MBL(主提單)', path: 'spouchmb' } })
              break
            case 'spouchma':
              this.$router.push({ name: item.url, params: { dc_no: row.dc_no, orderId: row.dc_no, fileType, operating, showNode: 'display', titleA: '表單管理-POUCH-Manifest(艙單)', titleB: 'POUCH-Manifest(艙單)', path: 'spouchma' } })
              break
            case 'spouchhb':
              this.$router.push({ name: item.url, params: { dc_no: row.dc_no, orderId: row.dc_no, fileType, operating, showNode: 'display', titleA: '表單管理-POUCH-分提單HB/L', titleB: 'POUCH-分提單HB/L', path: 'spouchhb' } })
              break
            case 'spouchiv':
              this.$router.push({ name: item.url, params: { dc_no: row.dc_no, orderId: row.dc_no, fileType, operating, showNode: 'display', titleA: '表單管理-POUCH-Invoice', titleB: 'POUCH-Invoice', path: 'spouchiv' } })
              break
            case 'spouchpl':
              this.$router.push({ name: item.url, params: { dc_no: row.dc_no, orderId: row.dc_no, fileType, operating, showNode: 'display', titleA: '表單管理-Packing List', titleB: 'Packing List', path: 'spouchpl' } })
              break
            default:
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating } })
              break
          }
          break
        case 'shipment':
          if (item.showNode === 'none') {
            this.$store.commit('showNode', 'none')
            this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'none', titleA: '表單管理-出貨通知單', titleB: '出貨通知單', path: 'shipment' } })
          } else {
            this.$router.push({ name: item.url, params: { orderId: row[item.prop], fileType, operating, showNode: 'display' } })
          }
          break
        case 'completeBooking':
          if (item.showNode === 'none') {
            this.$store.commit('showNode', 'none')
            this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'none', titleA: '表單管理-裝船通知書', titleB: '裝船通知書', path: 'completeBooking' } })
          } else {
            this.$router.push({ name: item.url, params: { orderId: row[item.prop], fileType, operating, showNode: 'display' } })
          }
          break
        case 'cargoMeasure':
          if (item.showNode === 'none') {
            this.$store.commit('showNode', 'none')
            this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'none', titleA: '表單管理-丈量證明文件', titleB: '丈量證明文件', path: 'cargoMeasure' } })
          } else {
            this.$router.push({ name: item.url, params: { orderId: row[item.prop], fileType, operating, showNode: 'display' } })
          }
          break
        case 'soReleased':
          if (item.showNode === 'none') {
            this.$store.commit('showNode', 'none')
            if (this.$store.getters.soTab === 0) {
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'none', titleA: '表單管理-HB/L 提單做法', titleB: 'HB/L 提單做法', path: 'soReleased1' } })
            }
          } else {
            this.$router.push({ name: item.url, params: { orderId: row[item.prop], fileType, operating, showNode: 'display' } })
          }
          break
        case 'ivReleased':
          if (item.showNode === 'none') {
            this.$store.commit('showNode', 'none')
            if (this.$store.getters.soTab === 1) {
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'none', titleA: '表單管理-Invoice', titleB: 'Invoice', path: 'soReleased1' } })
            }
          } else {
            this.$router.push({ name: item.url, params: { orderId: row[item.prop], fileType, operating, showNode: 'display' } })
          }
          break
        case 'plReleased':
          if (item.showNode === 'none') {
            this.$store.commit('showNode', 'none')
            if (this.$store.getters.soTab === 2) {
              this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'none', titleA: '表單管理-Packing List', titleB: 'Packing List', path: 'soReleased1' } })
            }
          } else {
            this.$router.push({ name: item.url, params: { orderId: row[item.prop], fileType, operating, showNode: 'display' } })
          }
          break
        case 'checkReleased':
          if (item.showNode === 'none') {
            this.$store.commit('showNode', 'none')
            this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'none', titleA: '表單管理-HB/L核對單', titleB: 'HB/L核對單', path: 'soReleased1' } })
          } else {
            this.$router.push({ name: item.url, params: { orderId: row[item.prop], fileType, operating, showNode: 'display' } })
          }
          break
        case 'documentReleased':
          if (item.showNode === 'none') {
            this.$store.commit('showNode', 'none')
            this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'none', titleA: this.$store.getters.soTab === 0 ? '表單管理-HB/L 提單' : '表單管理-客戶帳單', titleB: this.$store.getters.soTab === 0 ? 'HB/L 提單' : '客戶帳單', path: 'documentReleased' } })
          } else {
            this.$router.push({ name: item.url, params: { orderId: row[item.prop], fileType, operating, showNode: 'display' } })
          }
          break
        case 'quotationConfirmed':
          if (item.showNode === 'none') {
            this.$store.commit('showNode', 'none')
            this.$router.push({ name: item.url, params: { orderId: row[item.prop], showNode: 'none', titleA: '表單管理-報價確認單', titleB: '報價確認單' } })
          } else {
            this.$router.push({ name: item.url, params: { orderId: row[item.prop], showNode: 'display' } })
          }
          break
        case 'measure':
          this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', ship_num: row.ship_num, titleA: '表單管理-貨物丈量單', titleB: '貨物丈量單', path: 'measure' } })
          break
        case 'containerload':
          this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', ship_num: row.ship_num, vsl_name: row.vsl_name, voyage: row.voyage, cfs_delivery_place: row.cfs_delivery_place, port_discharge: row.port_discharge, titleA: '表單管理-裝櫃清單', titleB: '裝櫃清單', path: 'containerload' } })
          break
        case 'mblsi':
          this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', ship_num: row.ship_num, vsl_name: row.vsl_name, voyage: row.voyage, carrier_agent: row.carrier_agent, so_no: row.so_no, titleA: '表單管理-MB/L 提單做法(SI)', titleB: 'MB/L 提單做法', path: 'mblsi' } })
          break
        case 'mbl':
          this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display', ship_num: row.ship_num, titleA: '表單管理-MB/L 核對單', titleB: 'MB/L 核對單', path: 'mbl' } })
          break
        case 'spouchdc2':
          spouchUrl = `/spouch/DC/${row.vsl_name}/${row.voyage}/${row.carrier_agent}/${row.dc_no}/${row.mbl_no}/${row.o_bl_no}`
          // this.$store.commit('routerParams', row)
          console.log(row)
          this.$router.push({
            path: spouchUrl,
            // name: item.url,
            params: { fileType, operating, showNode: 'display', titleA: '表單管理-POUCH-代理帳單', titleB: 'POUCH-代理帳單' }
          })
          break
        case 'spouchmb2':
          spouchUrl = `/spouch/MB/${row.vsl_name}/${row.voyage}/${row.carrier_agent}/${row.dc_no}/${row.mbl_no}/${row.o_bl_no}`
          this.$store.commit('routerParams', row)
          this.$router.push({
            path: spouchUrl,
            params: { fileType, operating, showNode: 'display', titleA: '表單管理-POUCH-主提單', titleB: 'POUCH-主提單' }
          })
          break
        case 'spouchma2':
          spouchUrl = `/spouch/MA/${row.vsl_name}/${row.voyage}/${row.carrier_agent}/${row.dc_no}/${row.mbl_no}/${row.o_bl_no}`
          this.$store.commit('routerParams', row)
          this.$router.push({
            path: spouchUrl,
            params: { fileType, operating, showNode: 'display', titleA: '表單管理-POUCH-艙單', titleB: 'POUCH-艙單' }
          })
          break
        case 'spouchhb2':
          spouchUrl = `/spouch/HB/${row.vsl_name}/${row.voyage}/${row.carrier_agent}/${row.dc_no}/${row.mbl_no}/${row.o_bl_no}`
          this.$store.commit('routerParams', row)
          this.$router.push({
            path: spouchUrl,
            params: { fileType, operating, showNode: 'display', titleA: '表單管理-POUCH-分提單', titleB: 'POUCH-分提單' }
          })
          break
        case 'spouchiv2':
          spouchUrl = `/spouch/IV/${row.vsl_name}/${row.voyage}/${row.carrier_agent}/${row.dc_no}/${row.mbl_no}/${row.o_bl_no}`
          this.$store.commit('routerParams', row)
          this.$router.push({
            path: spouchUrl,
            params: { fileType, operating, showNode: 'display', titleA: '表單管理-POUCH-Invoice', titleB: 'POUCH-Invoice' }
          })
          break
        case 'spouchpl2':
          spouchUrl = `/spouch/PL/${row.vsl_name}/${row.voyage}/${row.carrier_agent}/${row.dc_no}/${row.mbl_no}/${row.o_bl_no}`
          this.$store.commit('routerParams', row)
          this.$router.push({
            path: spouchUrl,
            params: { fileType, operating, showNode: 'display', titleA: '表單管理-POUCH-Packing List', titleB: 'POUCH-Packing List' }
          })
          break
        default:
          this.$router.push({ name: item.url, params: { orderId: row[item.key], fileType, operating, showNode: 'display' } })
          break
      }
    },
    async deleteData () {
      // console.log('deleteData')
      this.$confirm('確定是否刪除所選資料', '提示', {
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.delTableSelectData()
        this.$store.commit('tableSelectData', [])
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(async () => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async addData () {
      this.addlist = true
    }
  }
}
</script>

<style lang="scss" module>
.margin {
  margin-bottom: 14px;
}
</style>
