<template>
  <div :class="$style.root">
    <div :class="$style.rootinner">
      <div :class="$style.left">
        <div :class="$style.border">AILSVer</div>
        <div :class="$style.border">EDIName</div>
        <div :class="$style.border">EDIType</div>
        <div :class="$style.border">SenderID</div>
        <div :class="$style.border">SenderName</div>
        <div :class="$style.border">SenderPIC</div>
        <div :class="$style.border">ReceiverID</div>
        <div :class="$style.border">MessageID</div>
        <div :class="$style.border">BookingDate</div>
        <div :class="$style.border">BookingAttn</div>
        <div :class="$style.border">BookingTel</div>
        <div :class="$style.border">BookingEmail</div>
        <div :class="$style.border">SO</div>
        <div :class="$style.border">VslRegNo</div>
        <div :class="$style.border">MBL</div>
        <div :class="$style.border">HBL</div>
        <div :class="$style.border">Vessel</div>
        <div :class="$style.border">Voyage</div>
        <div :class="$style.border">VslCallSign</div>
        <div :class="$style.border">RecPlace</div>
      </div>
      <div :class="$style.right">
        <input
          :class="$style.input"
          :value="billEditData.ailsver"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.ediname"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.editype"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.senderid"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.sendername"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.senderpic"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.receiverid"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.messageid"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.bookingdate"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.bookingattn"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.bookingtel"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.bookingemail"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.so"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.vslregno"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.mbl"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.hbl"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.vessel"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.voyage"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.vslcallsign"
          :disabled="billEditData.status_code === 3"
        />
        <input
          :class="$style.input"
          :value="billEditData.recplace"
          :disabled="billEditData.status_code === 3"
        />
      </div>
      <div :class="$style.left">
        <div :class="$style.border">ClosingDate</div>
        <div :class="$style.border">PlrName</div>
        <div :class="$style.border">PolName</div>
        <div :class="$style.border">PodName</div>
        <div :class="$style.border">PldName</div>
        <div :class="$style.border">DestName</div>
        <div :class="$style.border">NtfyName</div>
        <div :class="$style.border">AlsoNtfy</div>
        <div :class="$style.border">OFPPCC</div>
        <div :class="$style.border">Qty</div>
        <div :class="$style.border">TotalKGS</div>
        <div :class="$style.border">TotalCBM</div>
        <div :class="$style.border">TotalQTY</div>
        <div :class="$style.border">PkgUnit</div>
      </div>
      <div :class="$style.right">
        <input :class="$style.input" :value="billEditData.closingdate" :disabled="billEditData.status_code === 3"/>
        <input :class="$style.input" :value="billEditData.plrname" :disabled="billEditData.status_code === 3"/>
        <input :class="$style.input" :value="billEditData.polname" :disabled="billEditData.status_code === 3"/>
        <input :class="$style.input" :value="billEditData.podname" :disabled="billEditData.status_code === 3"/>
        <input :class="$style.input" :value="billEditData.pldname" :disabled="billEditData.status_code === 3"/>
        <input :class="$style.input" :value="billEditData.destname" :disabled="billEditData.status_code === 3"/>
        <input :class="$style.input" :value="billEditData.ntfyname" :disabled="billEditData.status_code === 3"/>
        <input :class="$style.input" :value="billEditData.alsontfy" :disabled="billEditData.status_code === 3"/>
        <input :class="$style.input" :value="billEditData.ofppcc" :disabled="billEditData.status_code === 3"/>
        <input :class="$style.input" :value="billEditData.qty" :disabled="billEditData.status_code === 3"/>
        <input :class="$style.input" :value="billEditData.totalkgs" :disabled="billEditData.status_code === 3"/>
        <input :class="$style.input" :value="billEditData.totalcbm" :disabled="billEditData.status_code === 3"/>
        <input :class="$style.input" :value="billEditData.totalqty" :disabled="billEditData.status_code === 3"/>
        <input :class="$style.input" :value="billEditData.pkgunit" :disabled="billEditData.status_code === 3"/>
      </div>
    </div>
    <div :class="$style.left">
      <div :class="$style.border">ShprName</div>
    </div>
    <textarea :class="$style.textarea" :value="billEditData.shprname" :disabled="billEditData.status_code === 3"/>
    <div :class="$style.left">
      <div :class="$style.border">CneeName</div>
    </div>
    <textarea :class="$style.textarea" :value="billEditData.cneename" :disabled="billEditData.status_code === 3"/>
    <div :class="$style.left">
      <div :class="$style.border">Marks</div>
    </div>
    <textarea :class="$style.textarea" :value="billEditData.marks" :disabled="billEditData.status_code === 3"/>
    <div :class="$style.left">
      <div :class="$style.border">Descriptions</div>
    </div>
    <textarea :class="$style.textarea" :value="billEditData.descriptions" :disabled="billEditData.status_code === 3"/>
    <div :class="$style.left">
      <div :class="$style.title">
        <div :class="[$style.border, $style.borderconter]">
          貨櫃編號
        </div>
        <div :class="[$style.border, $style.borderconter]">
          貨櫃尺寸
        </div>
        <div :class="[$style.border, $style.borderconter]">
          封條號碼1
        </div>
        <div :class="[$style.border, $style.borderconter]">
          封條號碼2
        </div>
        <div :class="[$style.border, $style.borderconter]">
          封條號碼3
        </div>
        <div :class="[$style.border, $style.borderconter]">
          货物材積合計數
        </div>
        <div :class="[$style.border, $style.borderconter]">
          重量合計數
        </div>
        <div :class="[$style.border, $style.borderconter]">
          件數合計數
        </div>
        <div :class="[$style.border, $style.borderconter]">
          件數單位
        </div>
      </div>
      <template v-for="(item, i) in billEditData.detail">
        <div :class="$style.title" :key="i">
          <input :class="[$style.input, $style.inputborder]" v-model="item.ctnrno" :disabled="billEditData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.sizetype" :disabled="billEditData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.sealno1" :disabled="billEditData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.sealno2" :disabled="billEditData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.sealno3" :disabled="billEditData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.cargocbm" :disabled="billEditData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.cargokgs" :disabled="billEditData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.cargoqty" :disabled="billEditData.status_code === 3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.cargounit" :disabled="billEditData.status_code === 3"/>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['billEditData'])
  },
  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" module>
.root {
  align-self: center;
  width: 95%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: #fff;
  margin: 20px 0;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.rootinner {
  width: 100%;
  display: grid;
  grid-template-columns: 150px 1fr 150px 1fr;
  grid-auto-rows: auto;
  border: 1px solid #eaeaea;
}

.border {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
  color: #6d6b6b;
  box-sizing: border-box;
  border-bottom: 1px solid #eaeaea;

  &.borderconter {
    justify-content: center;
  }
}

.left {
  display: grid;
  grid-auto-rows: 35px;
}

.right {
  display: grid;
  border-left: 1px solid #eaeaea;
  grid-auto-rows: 35px;
  border-right: 1px solid #eaeaea;
}

.bottom {
  grid-area: bottom;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-bottom: 1px solid #eaeaea;
}

.input {
  background: #fff;
  border: none;
  border-bottom: 1px solid #eaeaea;
  box-shadow: none;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  color: #6d6b6b;

  &.inputborder {
    border-right: 1px solid #eaeaea;
  }
}

.title {
  display: grid;
  grid-template-columns: repeat(9, 1fr) ;
  height: 35px;
}

.btn {
  align-self: flex-end;
  margin: 20px 10px;
  font-size: 12px;
  color: #6d6b6b;
  cursor: pointer;
}

.bottomtitle {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 35px 35px;
  margin: 10px;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.text {
  width: 100px;
}

.textarea {
  border: 1px solid #eaeaea;
  width: 100%;
  height: 100px;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
  color: #6d6b6b;
}

</style>
