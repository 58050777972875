<template>
  <div>
    <el-form status-icon label-width="100px" class="demo-ruleForm" :class="$style.form">
      <el-row>
          <el-form-item label="原始密碼" >
            <el-input v-model="originalPassword" type="password"></el-input>
          </el-form-item>
      </el-row>
      <el-row>
          <el-form-item label="新密碼" >
            <el-input v-model="newPassword"  type="password"></el-input>
          </el-form-item>
      </el-row>
      <el-row>
          <el-form-item label="確認密碼" >
            <el-input v-model="confirmPassword"  type="password"></el-input>
          </el-form-item>
      </el-row>
      <el-form-item>
        <el-button type="primary"  @click="savePassword" >確定修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { paswordChange } from '@/api/user'
export default {
  data () {
    return {
      error: false,
      originalPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  },
  methods: {
    checkPassword () {
      return this.newPassword.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/)
    },
    async savePassword () {
      this.error = false
      var errMsg = ''
      if (!this.checkPassword()) {
        this.error = true
        errMsg = '至少1.八位數 2.大小寫字母、數字組成的密碼'
      }
      if (this.originalPassword === '' || this.confirmPassword === '') {
        this.error = true
        errMsg = '請輸入所有資料'
      }
      if (this.newPassword !== this.confirmPassword) {
        this.error = true
        errMsg = '確認密碼不同'
      }
      if (this.newPassword === this.originalPassword) {
        this.error = true
        errMsg = '與原始密碼相同'
      }
      if (this.error) {
        this.$message({
          type: 'info',
          message: errMsg
        })
        return
      }
      var body = {
        old_password: this.originalPassword,
        new_password: this.newPassword,
        new_password2: this.confirmPassword
      }
      const rsp = await paswordChange(body)
      console.log(rsp)
      if (rsp.code === '200') {
        this.error = true
        this.$message({
          type: 'success',
          message: '修改完成'
        })
        setTimeout(() => {
          this.$router.push({ name: 'home' })
        }, 2000)
      } else {
        this.error = false
        this.$message({
          type: 'info',
          message: rsp.detail
        })
      }
    }
  }
}
</script>
<style lang="scss" module>
.text {
  color: #adadad;
  font-size: 14px;
  padding: 3px 0;
}
.form{
  max-width: 600px
}
</style>
