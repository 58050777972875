<template>
  <div :class="$style.root">
    <div :class="$style.background" :style="{width: pageWidth+'px', minHeight: pageHeight+'px'}">
      <i
        :class="[$style.close, 'el-icon-close']"
        @click="$emit('close')"
      />
      <div :class="$style.title">
        <slot name="title"/>
      </div>
      <slot name="selects"/>
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titleBtnText: String,
    pageWidth: {
      type: Number,
      default: 795
    },
    pageHeight: {
      type: Number,
      default: 500
    }
  },
  data () {
    return {
      page: ''
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" module>
.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  overflow-y: auto;
  padding: 50px 0;
  box-sizing: border-box;
}

.background {
  width: 795px;
  padding: 14px;
  border: solid 1px #dcdfe6;
  background-color: #f8f8f8;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.close {
  color: #39a85d;
  font-size: 30px;
  align-self: flex-end;
  font-weight: bolder;
  cursor: pointer;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: #68686a;
  margin-left: 20px;
}
</style>
