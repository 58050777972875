<template>
  <div>
    <card>
      <template #title >
        <span v-if="$route.params.titleB">{{ $route.params.titleB }}</span>
        <span v-else >{{ $route.meta.subtitle }}</span>
      </template>
      <template #rownav>
        <tab
          :class="$style.tab"
          @hasAddBtnFn="hasAddBtn = $event"
          @hasDelBtnFn="hasDelBtn = $event"
          @hasPdfBtnFn="hasPdfBtn = $event"
          @quotationType="quotationType = $event"
        />
      </template>
      <template #table>
        <info/>
        <div v-if="$route.name === 'documentReleased' && soTab === 1">
          <div :key="k" v-for="(v, k) in tableData">
            <card :class="$style.noline">
              <template #title>
                <span :class="$style.title">
                  {{v.DebitNo}}
                </span>
              </template>
              <template #titleBtn>
                <div style="display: flex;">
                  <label
                    tag="div"
                    :for="'updata'+k"
                    :class="[$style.btn, 'el-button', 'el-button--default',$style.addPdfBtn]"
                  >
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.94444 0.694444C6.94444 0.310913 6.63353 0 6.25 0C5.86647 0 5.55556 0.310913 5.55556 0.694444V5.55556H0.694444C0.310913 5.55556 0 5.86647 0 6.25C0 6.63353 0.310913 6.94444 0.694444 6.94444H5.55556V11.8056C5.55556 12.1891 5.86647 12.5 6.25 12.5C6.63353 12.5 6.94444 12.1891 6.94444 11.8056V6.94444H11.8056C12.1891 6.94444 12.5 6.63353 12.5 6.25C12.5 5.86647 12.1891 5.55556 11.8056 5.55556H6.94444V0.694444Z" fill="white"/>
                  </svg>
                    新增
                  </label>
                  <input
                    :id="'updata'+k"
                    type="file"
                    multiple
                    accept="application/pdf"
                    @change="uploadDebitPdf($event, v.DebitNo)"
                    v-if="showUpload"
                  />
                  <el-select v-model="emails" class="pdfEmailList" multiple placeholder="請選擇" v-if="pdfEmailList.length > 0">
                    <el-option
                      v-for="(item, k) in pdfEmailList"
                      :key="k"
                      :label="item.label"
                      :value="item.id">
                      <span>{{ item.label }}</span>
                      <span style="float: right; color: #8492a6; font-size: auto">{{ item.companyName }}</span>
                    </el-option>
                  </el-select>
                </div>
              </template>
            </card>
            <tableComponent
              :tableData="v.DebitList"
              :tableLabel="tableLabel"
              @Edit="openDetail"
              @View="openPdf"
              @SOA="routerFn"
            />
          </div>
        </div>
        <div v-else>
          <card :class="$style.noline">
            <template #title>
              <span :class="$style.title">
                上傳紀錄
              </span>
            </template>
            <template #titleBtn>
              <btnList
                :typeBtn="'danger'"
                :hasAddbtn="false"
                :hasDelbtn="false"
                :hasPdfbtn="hasPdfBtn"
                @add="addData"
                @updataPdf="routerFn"
                @del="delDate"
              />
            </template>
          </card>
          <tableComponent
            :tableData="tableData"
            :tableLabel="tableLabel"
            @Edit="openDetail"
            @View="openPdf"
            @SOA="routerFn"
          />
        </div>
      </template>
      <template #pagination>
        <pageination/>
      </template>
    </card>
    <addFile
      v-if="addFile"
      @close="addFile = false"
      :quotationType="quotationType"
    />
  </div>
</template>

<script>
import card from '@/components/card/card.vue'
import pageination from '@/components/plug/page/pageination.vue'
import tab from '@/views/list/list/components/tab.vue'
import btnList from '@/components/plug/button/button.vue'
import addFile from '@/views/list/list/components/addfile.vue'
import tableComponent from '@/components/plug/table.vue'
import info from '@/views/list/list/components/info.vue'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
export default {
  computed: {
    ...mapGetters(['tableData', 'tableLabel', 'commRsp', 'pafupdata', 'orderDataInfo', 'deliveryDetailItems', 'deliveryMasterItem', 'soTab', 'orderId', 'soTab', 'showUpload', 'pdfEmailList']),
    referenceNo: {
      get () {
        return this.$store.getters.reference_no
      },
      set (val) {
        this.$store.commit('reference_no', val)
      }
    }
  },
  components: {
    card,
    pageination,
    tab,
    btnList,
    addFile,
    tableComponent,
    info
  },
  data () {
    return {
      hasAddBtn: null,
      hasDelBtn: null,
      hasPdfBtn: null,
      quotationType: null,
      addFile: false,
      orderDataItem: {},
      emails: []
    }
  },
  mounted () {
    console.log(this.$router.currentRoute)
  },
  methods: {
    ...mapActions(['delTableSelectData', 'deliveryDetailSearch']),
    uploadDebitPdf (e, debitNo) {
      const files = e.target.files
      this.$store.commit('pafupdata', files[0])
      this.$store.commit('showUpload', false)
      this.routerFn(this.emails, debitNo)
      this.emails = []
      this.$refs.updataDebit.values = ''
    },
    async routerFn (val1, val2) {
      if (this.pafupdata !== null) {
        // this.$store.dispatch('loadingStart')
        console.log('routerFn val1', val1, 'val2', val2, 'this.orderDataInfo', this.orderDataInfo)
        var emailList = val1
        Object.values(this.orderDataInfo).forEach(item => {
          this.orderDataItem[item.label] = item.input
        })
        console.log('uploadFile', this.$route.name)
        console.log('file', this.pafupdata)
        console.log('this.orderDataItem', JSON.stringify(this.orderDataItem))
        const form = new FormData()
        form.append('file', this.pafupdata)
        // form.append('JobNo', this.orderDataItem.pre_book_no)
        // form.append('sono', (this.orderDataItem.so_no === '' ? '0' : this.orderDataItem.so_no))
        var fname = this.orderDataItem.pre_book_no + '__' + (this.orderDataItem.so_no === '' ? '0' : this.orderDataItem.so_no)
        if (JSON.stringify(this.orderDataItem) === '{}' || this.orderDataItem.pre_book_no === undefined || this.orderDataItem.so_no === undefined) {
          fname = this.$store.getters.pre_book_no + '__' + (this.$store.getters.so_no === '' ? '0' : this.$store.getters.so_no)
        }
        // var fname = this.orderDataItem.pre_book_no
        // if (JSON.stringify(this.orderDataItem) === '{}' || this.orderDataItem.pre_book_no === undefined) {
        //   fname = this.$store.getters.pre_book_no
        // }
        switch (this.$route.name) {
          case 'shipment':
            fname = fname.replace(/\//g, '').replace(/\./g, '') + '__QU.pdf'
            break
          case 'completeBooking':
            fname = fname.replace(/\//g, '').replace(/\./g, '') + '__SA.pdf'
            break
          case 'cargoMeasure':
            fname = fname.replace(/\//g, '').replace(/\./g, '') + '__MD.pdf'
            break
          case 'soReleased':
          case 'ivReleased':
          case 'plReleased':
            switch (this.soTab) {
              case 0:
                if (this.$store.getters.pafupdataType === '') {
                  fname = fname.replace(/\//g, '').replace(/\./g, '') + '__SO.pdf'
                } else {
                  fname = fname.replace(/\//g, '').replace(/\./g, '') + '__SOA.pdf'
                }
                break
              case 1:
                fname = fname.replace(/\//g, '').replace(/\./g, '') + '__IV.pdf'
                break
              case 2:
                fname = fname.replace(/\//g, '').replace(/\./g, '') + '__PL.pdf'
                break
            }
            break
          case 'checkReleased':
            if (this.$store.getters.pafupdataType === '') {
              fname = fname.replace(/\//g, '').replace(/\./g, '') + '__HC.pdf'
            } else {
              fname = fname.replace(/\//g, '').replace(/\./g, '') + '__HCA.pdf'
            }
            break
          case 'documentReleased':
            switch (this.soTab) {
              case 0:
                if (this.$store.getters.pafupdataType === '') {
                  fname = fname.replace(/\//g, '').replace(/\./g, '') + '__HB.pdf'
                } else {
                  fname = fname.replace(/\//g, '').replace(/\./g, '') + '__HBA.pdf'
                }
                break
              case 1:
                fname = fname.replace(/(.*)_(.*)$/, '$1') + '__' + val2 + '__DN.pdf'
                break
            }
            break
          case 'quotationConfirmed':
            fname = fname.replace(/\//g, '').replace(/\./g, '') + '__QC.pdf'
            break
        }
        form.append('filename', fname)
        form.append('email', JSON.stringify(emailList))
        const rsp = await axios.post('api/delivery/pdfuploader/' + fname, form,
          {
            headers: {
              'Content-Type': 'multipart/form-data;application/pdf', Accept: '*/*'
            }
          }).then((response) => response)// 報價單PDF上傳
        this.$store.dispatch('loadingClose')
        console.log('rsp', rsp)
        if (rsp.status === 200) {
          this.$message({
            type: 'success',
            message: '上傳成功!'
          })
          // this.$store.dispatch('deliveryDetailSearch', { orderId: this.orderDataItem.pre_book_no })
          switch (this.$route.name) {
            case 'soReleased':
            case 'ivReleased':
            case 'plReleased':
              switch (this.soTab) {
                case 0:
                  this.$store.dispatch('hblsoSingleSearch', { orderId: this.$route.params.orderId, file_type: 'SO' })
                  break
                case 1:
                  this.$store.dispatch('invoicePackinglistSearch', { orderId: this.$route.params.orderId, file_type: 'IV' })
                  break
                case 2:
                  this.$store.dispatch('invoicePackinglistSearch', { orderId: this.$route.params.orderId, file_type: 'PL' })
                  break
              }
              break
            case 'documentReleased':
              switch (this.soTab) {
                case 0:
                // this.$store.dispatch('setinvoicePackinglistTableLabel')
                  this.$store.dispatch('hblSingleSearch', { orderId: this.$route.params.orderId })
                  break
                case 1:
                // this.$store.dispatch('setinvoicePackinglistTableLabel')
                  this.$store.dispatch('billSingleSearch', { orderId: this.$route.params.orderId })
                  break
              }
              break
            default:
              this.$store.dispatch('preProcessinghandler', { name: this.$route.name, params: { orderId: this.$route.params.orderId } })
              break
          }
        } else {
          this.$message({
            type: 'info',
            message: '上傳失敗!'
          })
        }
        this.$store.commit('pafupdataType', '')
        this.$store.commit('pafupdata', null)
        this.$store.commit('showUpload', true)
      }
    },
    openDetail (val1, val2) {
      console.log('val1', val1, 'val2', val2)
      this.$store.commit('editRowData', val1)
      const parm = { orderId: val1.pre_book_no === undefined ? val1.messageid : val1.pre_book_no, fileType: 'data', operating: 'edit', version: val1.version }
      console.log('!@!@!@@', parm)
      console.log('!@!@!@@', this.$route.name)
      console.log('!@!@!@@', this.quotationType)
      let pathName = ''
      switch (this.$route.name) {
        case 'soReleased':
          switch (Number(this.soTab)) {
            case 2:
              this.$store.commit('billEditData', val1)
              this.$router.push({
                name: 'bill',
                params: parm
              }).catch(err => err)
              break
          }
          break
        default:
          switch (this.quotationType) {
            case 'quotation':
            case 'cargo':
            case 'notice':
            case 'lading':
            case 'customer':
            case 'confirmed':
              pathName = this.quotationType
              break
            case 'documentReleased':
              pathName = 'hblSubdivision'
              break
            default:
              pathName = this.$route.name
              break
          }
          this.$router.push({
            name: pathName,
            params: parm
          }).catch(err => err)
          break
      }
    },
    openPdf (val1, val2) {
      console.log('val1', val1, 'val2', val2, 'this.$route.name', this.$route.name, 'params', this.$route.params)
      this.$store.commit('pdfUrl', this.globalPDFHost + val1.file_path)
      var pathName = ''
      switch (this.$route.name) {
        case 'shipment':
          pathName = 'quotation'
          break
        case 'completeBooking':
          pathName = 'notice'
          break
        case 'cargoMeasure':
          pathName = 'cargo'
          break
        case 'soReleased':
        case 'ivReleased':
        case 'plReleased':
          switch (Number(this.soTab)) {
            case 0:
              pathName = 'invoice'
              break
            case 1:
              pathName = 'packingList'
              break
            case 2:
              pathName = 'bill'
              // console.log('openPdf val1.file_path.so', val1.file_path.so)
              // console.log('openPdf val1.file_path.soa', val1.file_path.soa)
              // console.log('openPdf http://kfrd.ioneit.com' + val1.file_path.so)
              // console.log('openPdf http://kfrd.ioneit.com' + val1.file_path.soa)
              break
          }
          this.$store.commit('pdfUrl', this.globalPDFHost + val1.file_path.so)
          if (val1.file_path.soa !== undefined) {
            this.$store.commit('pdfUrlBySOA', this.globalPDFHost + val1.file_path.soa)
          } else {
            this.$store.commit('pdfUrlBySOA', '')
          }
          break
        case 'checkReleased':
          this.$store.commit('pdfUrl', this.globalPDFHost + val1.file_path.hc)
          if (val1.file_path.hca !== undefined) {
            this.$store.commit('pdfUrlBySOA', this.globalPDFHost + val1.file_path.hca)
          }
          pathName = 'hblcheck'
          break
        case 'documentReleased':
          switch (Number(this.soTab)) {
            case 0:
              this.$store.commit('pdfUrl', this.globalPDFHost + val1.file_path.hb)
              if (val1.file_path.hba !== undefined) {
                this.$store.commit('pdfUrlBySOA', this.globalPDFHost + val1.file_path.hba)
              }
              pathName = 'lading'
              break
            case 1:
              pathName = 'customer'
              break
          }
          break
        case 'quotationConfirmed':
          pathName = 'confirmed'
          break
        default:
          break
      }
      this.$router.push({
        name: pathName,
        params: { orderId: val1.pre_book_no === undefined ? val1.messageid : val1.pre_book_no, fileType: 'pdf', operating: 'view', version: val1.version, titleA: this.$route.params.titleA, titleB: this.$route.params.titleB }
      })
    },
    addData () {
      this.addFile = true
      switch (this.quotationType) {
        case 'quotation':
          Object.values(this.orderDataInfo).forEach(item => {
            this.orderDataItem[item.label] = item.input
          })
          this.$store.commit('deliveryDetailItems', [])
          this.$store.commit('deliveryMasterItem', { pre_book_no: this.orderDataItem.pre_book_no })
          console.log('deliveryMasterItem', this.orderDataInfo)
          break
        case 'notice':
          this.$store.commit('editRowData', {})
          break
      }
    },
    async delDate () {
      console.log('delDate', this.$route.params)
      await this.delTableSelectData(this.$route.params)
      switch (this.commRsp.status) {
        case '200':
          this.$message({
            type: 'success',
            message: '成功'
          })
          break
        default:
          this.$message({
            type: 'info',
            message: '失敗'
          })
          break
      }
    },
    async saveRefrenceNo () {
      console.log(this.referenceNo, this.$route.params.orderId)
      var res = await this.$store.dispatch('saveRefrenceNo', { pre_book_no: this.$route.params.orderId, reference_no: this.referenceNo })
      console.log(res)
      switch (res.code) {
        case '200':
          this.$message({
            type: 'success',
            message: '修改成功'
          })
          break
        default:
          this.$message({
            type: 'info',
            message: '修改失敗'
          })
          break
      }
    }
  }
}
</script>

<style lang="scss" module>
.title {
  font-size: 16px;
  font-weight: 500;
  color: #68686a;
}

.table {
  :global(.el-table__body-wrapper) {
    min-height: 475px;
  }
}

.icon {
  font-size: 20px;
  font-weight: bold;
  color: #39A85D;
  cursor: pointer;

  &.margin {
    margin-right: 10px;
  }
}
.addPdfBtn{
  height:30px;
}
.btn {
  background-color: #4BA676 !important;
  color: #ffffff !important;
  height: 34px;
  vertical-align: middle;
  &:global(.el-button) {
    padding: 8px 12px;
    font-size: 12px;
  }
}

.tab {
  padding: 0 20px;
  box-sizing: border-box;
}

.noline {
  border: none;
}
</style>
<style>
.refNoInput {
  position: absolute;
  right: 43px;
}
.refNoInput .el-input-group__prepend,.refNoInput .el-input-group__append {
  padding: 5 10px;
}
.refNoInput .el-input__inner{
  height: 25px !important;
  width: 100px;
}
.refNoInput .el-button{
  padding: 6px 10px;
}
</style>
