<template>
  <tablecomponent
    :tableLabel="tableLabel"
    :tableData="tableData"
    @edit="openDetail"
    @search="searchHandler"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import tablecomponent from '@/components/plug/table.vue'
// import { deliveryMasterList } from '@/api/delivery'
export default {
  computed: {
    ...mapGetters(['tableData', 'tableLabel'])
  },
  components: {
    tablecomponent
  },
  methods: {
    searchHandler (payload) {
      console.log('search middle Handler payload', payload)
      this.$emit('search', payload)
    },
    openDetail (row, item) {
      this.$emit(item.node, row, item)
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" module>
</style>
