<template>
  <div :class="$style.root">
    <manipulate :class="$style.maipulate">
      <template #manipulate>
        <inputComponent v-for="(item, i) in inputList" :key="i">
          <template #title>
            {{ item.label }}
          </template>
          <template #input>
            <el-input
              :class="$style.input"
              v-model="item.input"
            />
          </template>
        </inputComponent>
      </template>
    </manipulate>
  </div>
</template>

<script>
import manipulate from '@/components/card/manipulate.vue'
import inputComponent from '@/components/card/input.vue'
export default {
  components: {
    manipulate,
    inputComponent
  },
  data () {
    return {
      inputList: [{
        label: 'Vessel Name (船名)',
        input: ''
      }, {
        label: 'Voyage (航次)',
        input: ''
      }, {
        label: '貨櫃場代碼',
        input: ''
      }, {
        label: '公證行代碼',
        input: ''
      }, {
        label: 'Voy ID',
        input: ''
      }]
    }
  }
}
</script>

<style lang="scss" module>
.root {
  margin-bottom: 10px;
}

.maipulate {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 12px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.input {
  :global(.el-input__inner) {
    height: 30px;
  }
}
</style>
