<template>
  <popup @close="$emit('close')">
    <template #title>
      新增文件{{quotationType}}
    </template>
    <template #content>
      <info :class="$style.info"/>
      <quotationpage v-if="quotationType === 'quotation'"/>
      <cargopage v-if="quotationType === 'cargo'"/>
      <noticefile v-if="quotationType === 'notice'"/>
      <billfile v-if="quotationType === 'bill'"/>
      <ladingfile v-if="quotationType === 'lading'"/>
      <customerfile v-if="quotationType === 'customer'"/>
      <div :class="$style.out">
        <el-button
          type="primary"
          :class="$style.pagerbtn"
          @click="saveFile"
        >
          儲存草稿
        </el-button>
        <el-button
          type="primary"
          :class="$style.pagerbtn"
          plain
        >
          確認上傳
        </el-button>
      </div>
    </template>
  </popup>
</template>

<script>
import popup from '@/components/card/popup.vue'
import quotationpage from '@/views/list/list/components/quotationpage.vue'
import cargopage from '@/views/list/list/components/cargopage.vue'
import noticefile from '@/views/list/list/components/noticefile.vue'
import billfile from '@/views/list/list/components/billfile.vue'
import ladingfile from '@/views/list/list/components/ladingfile.vue'
import customerfile from '@/views/list/list/components/customerfile.vue'
import info from '@/views/list/list/components/info.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['deliveryDetailItems', 'deliveryMasterItem', 'commRsp', 'orderDataInfo', 'shipmentMasterItem'])
  },
  props: {
    quotationType: null
  },
  components: {
    popup,
    quotationpage,
    cargopage,
    noticefile,
    billfile,
    ladingfile,
    customerfile,
    info
  },
  methods: {
    ...mapActions(['addDatahandler']),
    async saveFile () {
      // console.log('saveFile', this.deliveryMasterItem, this.deliveryDetailItems)
      console.log('saveFile', this.shipmentMasterItem)
      await this.addDatahandler(this.$route.params)
      switch (this.commRsp.code) {
        case 200:
        case 201:
          this.$message({
            type: 'success',
            message: '成功'
          })
          break
        default:
          this.$message({
            type: 'info',
            message: '失敗'
          })
          break
      }
    }
  }
}
</script>

<style lang="scss" module>
.select {
  align-self: flex-end;
  margin-right: 40px;
}

.pager {
  margin: 19px 0 30px 0;
}

.pagerbtn {
  align-self: center;
  &:global(.el-button) {
    margin-bottom: 20px;
  }
}

.info {
  margin-top: 15px;
  background: none;
}

.out {
  display: flex;
  justify-content: center;
}
</style>
