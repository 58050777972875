<template>
  <div :class="$style.root">
    <div :class="$style.left">
      <div :class="$style.border">船名</div>
        <div :class="$style.border">航次</div>
        <div :class="$style.border">貨櫃場代碼</div>
        <div :class="$style.border">結關日期</div>
        <div :class="$style.border">公證行代碼</div>
    </div>
    <div :class="$style.right">
      <input
          :class="$style.input"
          :value="editRowData.vsl_name"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.voyage"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.cfs_delivery_place"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.closing_date"
          :disabled="true"
        />
        <input
          :class="$style.input"
          :value="editRowData.marine_survey"
          :disabled="true"
        />
    </div>
    <div :class="$style.bottom">
      <div :class="$style.title">
        <div :class="[$style.border, $style.borderconter]">
          SO No
        </div>
        <div :class="[$style.border, $style.borderconter]">
          SO Check
        </div>
        <div :class="[$style.border, $style.borderconter]">
          總件數
        </div>
        <div :class="[$style.border, $style.borderconter]">
          總材積
        </div>
      </div>
      <template v-for="(item, i) in editRowData.So">
        <div :class="$style.title" :key="i">
          <input :class="[$style.input, $style.inputborder]" v-model="item.so_no" :disabled="true"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.so_check" :disabled="true"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.ttl_package_no" :disabled="true"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.cargo_measure" :disabled="true"/>
        </div>
      </template>
    </div>
    <div :class="$style.bottom2">
      <div :class="$style.title2">
        <div :class="[$style.border, $style.borderconter]">
          SEQ No
        </div>
        <div :class="[$style.border, $style.borderconter]">
          件數
        </div>
        <div :class="[$style.border, $style.borderconter]">
          長度
        </div>
        <div :class="[$style.border, $style.borderconter]">
          寬度
        </div>
        <div :class="[$style.border, $style.borderconter]">
          高度
        </div>
        <div :class="[$style.border, $style.borderconter]">
          堆量註記
        </div>
        <div :class="[$style.border, $style.borderconter]">
          材積（CBM）
        </div>
      </div>
      <template v-for="(so, i) in editRowData.So">
        <div :class="$style.title2" :key="i">
        <template v-for="(dimension, k) in so.Dimension">
          <input :key="k" :class="[$style.input, $style.inputborder]" v-model="dimension.sequence_no" :disabled="true"/>
          <input :key="k" :class="[$style.input, $style.inputborder]" v-model="dimension.ttl_package_no" :disabled="true"/>
          <input :key="k" :class="[$style.input, $style.inputborder]" v-model="dimension.length" :disabled="true"/>
          <input :key="k" :class="[$style.input, $style.inputborder]" v-model="dimension.width" :disabled="true"/>
          <input :key="k" :class="[$style.input, $style.inputborder]" v-model="dimension.high" :disabled="true"/>
          <input :key="k" :class="[$style.input, $style.inputborder]" v-model="dimension.remark" :disabled="true"/>
          <input :key="k" :class="[$style.input, $style.inputborder]" v-model="dimension.cargo_measure" :disabled="true"/>
        </template>
      </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['taxInfo', 'deliveryDetailItems', 'editRowData'])
  },
  data () {
    return {
      listList: [{
        sequence_no: '',
        charge_code: '',
        charge_name: '',
        org_currency: '',
        chg_rate: '',
        chg_qty: '',
        chg_unit: '',
        ttl_org_amount: '',
        tax_rate: '',
        ttl_debit_amount: '',
        tax_type: ''
      }]
    }
  },
  methods: {
    addList () {
      this.deliveryDetailItems.push({
        sequence_no: '',
        charge_code: '',
        charge_name: '',
        org_currency: '',
        chg_rate: '',
        chg_qty: '',
        chg_unit: '',
        ttl_org_amount: '',
        tax_rate: '',
        ttl_debit_amount: '',
        tax_type: ''
      })
      // this.$store.commit('deliveryDetailItems', this.deliveryDetailItems)
      // console.log(this.deliveryDetailItems)
    },
    saveFile () {
    }
  }
}
</script>

<style lang="scss" module>
.root {
  align-self: center;
  width: 95%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: #fff;
  margin: 20px 0;
  display: grid;
  grid-auto-flow: column;
  grid-template-areas: 'nav input'
                      'bottom bottom'
                      'bottom2 bottom2';
  grid-template-columns: 150px 1fr;
  grid-auto-rows: minmax(350px, auto);
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.border {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding-left: 10px;
  font-size: 14px;
  color: #6d6b6b;
  box-sizing: border-box;

  &.borderconter {
    justify-content: center;
    padding: 0;
    text-align: center;
  }

  &.noneline {
    border-bottom: 0 solid #eaeaea;
  }
}

.left {
  display: grid;
  grid-area: nav;
}

.right {
  display: grid;
  border-left: 1px solid #eaeaea;
  grid-area: input;
}

.bottom {
  grid-area: bottom;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.bottom2 {
  grid-area: bottom2;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.input {
  background: #fff;
  border: none;
  border-bottom: 1px solid #eaeaea;
  box-shadow: none;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  color: #6d6b6b;

  &.inputborder {
    border-right: 1px solid #eaeaea;
  }
}

.title {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 35px;
}

.title2 {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 35px;
}

.btn {
  align-self: flex-end;
  margin: 20px 10px;
  font-size: 12px;
  color: #6d6b6b;
  cursor: pointer;
}

.bottomtitle {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 35px;
  margin: 10px;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.text {
  width: 100px;
}
</style>
