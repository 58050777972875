<template>
  <div>
    <card>
      <template #title>
        {{ $t('CargoConditionNode') }}
      </template>
      <template #progress>
        <el-steps align-center :class="$style.outsude">
          <el-step
            v-for="(item, i) in steps"
            :key="i"
            :class="[$style.active, {[$style.activated]: activeCss === item.link}]"
            :status="item.status"
            :title="$t(item.label)"
            @click.native="routerFunction(item.link)"
          >
          <template #description>
            <div>{{item.description}}</div>
            <div :class="$style.description">
              <div :class="$style.update">
                <p v-if="item.version[0] || item.version[1] || item.version[2]">更新次數</p>
                <p v-if="item.version[0]">{{ item.version[0]}}</p>
                <p v-if="item.version[1]">{{ item.version[1]}}</p>
                <p v-if="item.version[2]">{{ item.version[2]}}</p>
              </div>
            </div>
          </template>
          </el-step>
        </el-steps>
      </template>
    </card>
  </div>
</template>

<script>
import card from '@/components/card/card.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    card
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['steps']),
    activeCss () {
      return this.$route.name
    }
  },
  methods: {
    routerFunction (routerLink) {
      console.log('routerLink', routerLink, 'this.$route.name', this.$route.name)
      if (this.$route.name !== routerLink) {
        console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$')
        this.$store.commit('soTab', 0)
        this.$store.dispatch('clearData')
        this.$router.push({ name: routerLink, params: { showNode: 'display' } })
      }
    }
  }
}
</script>

<style lang="scss" module>

.description {
  margin-top: 0.6rem;
}

.outsude {
  :global(.el-step__title) {
    line-height: normal;
    font-size: 13px;
    font-weight: 500;
    margin: 6px auto;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 4px 12px;
  }

  :global(.el-step__icon-inner) {
    display: none;
  }

  :global(.el-step__icon) {
    width: 12px;
    height: 12px;
  }

  :global(.el-step.is-center .el-step__description) {
    padding: 0;
  }

  :global(.el-step.is-horizontal .el-step__line) {
    top: 9px;
  }

  :global(.el-step__icon.is-text) {
    border-color: #67C23A;
    border: 10px solid;
  }

  :global(.el-step__title.is-wait) {
    color: #000;
  }

  :global(.el-step__head.is-process) {

    :global(.el-step__icon.is-text) {
      background: #fff;
      position: relative;
    }
    :global(.el-step__icon.is-text::after) {
      background: #67C23A;
      height: 10px;
      top: 0px;
      position: absolute;
      width: 20px;
      border-radius: 0 0 100px 100px;
      content: "";
    }
  }

  :global(.el-step__head.is-success) {
    :global(.el-step__icon.is-text) {
      background: #67C23A;
    }
  }

  :global(.el-step__title.is-success) {
    color: #3c3c3c;
  }

  :global(.el-step__description.is-success) {
    color: #3c3c3c;
  }
}

.active {
  :hover {
    :global(.el-step__title) {
      border-radius: 4px;
      background: #efefef;
      cursor: pointer;
    }
  }

  &.activated {
    :global(.el-step__title) {
      border-radius: 4px;
      background: #efefef;
    }
  }
}
</style>
