<template>
  <div :class="$style.root">
    <div :class="$style.left">
      <div :class="$style.border">
        SO No
      </div>
      <div :class="$style.border">
        VESSEL(船名)
      </div>
      <div :class="$style.border">
        VOYAGE(航次)
      </div>
      <div :class="$style.border">
        PLR(貨櫃場)
      </div>
      <div :class="$style.border">
        公證行代碼
      </div>
      <div :class="$style.border">
        結關日期
      </div>
    </div>
    <div :class="$style.right">
      <input :class="$style.input"/>
      <input :class="$style.input"/>
      <input :class="$style.input"/>
      <input :class="$style.input"/>
      <input :class="$style.input"/>
      <input :class="$style.input"/>
    </div>
    <div :class="$style.bottom">
      <div :class="$style.title">
        <div :class="[$style.border, $style.borderconter]">
          SO No
        </div>
        <div :class="[$style.border, $style.borderconter]">
          SEQ No
        </div>
        <div :class="[$style.border, $style.borderconter]">
          件數
        </div>
        <div :class="[$style.border, $style.borderconter]">
          長度
        </div>
        <div :class="[$style.border, $style.borderconter]">
          寬度
        </div>
        <div :class="[$style.border, $style.borderconter]">
          高度
        </div>
        <div :class="[$style.border, $style.borderconter]">
          堆量註記
        </div>
        <div :class="[$style.border, $style.borderconter]">
          材積（CBM）
        </div>
      </div>
      <template v-for="(item, i) in listList">
        <div :class="$style.title" :key="i">
          <input :class="[$style.input, $style.inputborder]" v-model="item.input"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.input2"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.input3"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.input4"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.input5"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.input6"/>
          <input :class="[$style.input, $style.inputborder]" v-model="item.input7"/>
          <input :class="$style.input"/>
        </div>
      </template>
      <div
        :class="$style.btn"
        @click="addList"
      >
        <i class="el-icon-circle-plus-outline"/>新增欄位
      </div>
      <div :class="$style.bottomtitle">
        <div :class="[$style.border, $style.noneline]">
          <span :class="$style.text">Total</span>
          <input :class="$style.input"/>
        </div>
        <div :class="[$style.border, $style.noneline]">
          <span :class="$style.text">總件數</span>
          <input :class="$style.input"/>
        </div>
        <div :class="[$style.border, $style.noneline]">
          <span :class="$style.text">SO Check（核單）</span>
          <input :class="$style.input"/>
        </div>
        <div :class="[$style.border, $style.noneline]">
          <span :class="$style.text">總材積</span>
          <input :class="$style.input"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      listList: [{
      }]
    }
  },
  methods: {
    addList () {
      this.listList.push({})
    }
  }
}
</script>

<style lang="scss" module>
.root {
  align-self: center;
  width: 95%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: #fff;
  margin: 20px 0;
  display: grid;
  grid-auto-flow: column;
  grid-template-areas: 'nav input'
                      'bottom bottom';
  grid-template-columns: 150px 1fr;
  grid-auto-rows: minmax(200px, auto);
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.border {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  padding-left: 10px;
  font-size: 14px;
  color: #6d6b6b;
  box-sizing: border-box;

  &.borderconter {
    justify-content: center;
  }

  &.noneline {
    border-bottom: 0 solid #eaeaea;
  }
}

.left {
  display: grid;
  grid-area: nav;
}

.right {
  display: grid;
  border-left: 1px solid #eaeaea;
  grid-area: input;
}

.bottom {
  grid-area: bottom;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.input {
  background: #fff;
  border: none;
  border-bottom: 1px solid #eaeaea;
  box-shadow: none;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  color: #6d6b6b;

  &.inputborder {
    border-right: 1px solid #eaeaea;
  }
}

.title {
  display: grid;
  grid-template-columns: repeat(7, 1fr) 120px;
  height: 35px;
}

.btn {
  align-self: flex-end;
  margin: 20px 10px;
  font-size: 12px;
  color: #6d6b6b;
  cursor: pointer;
}

.bottomtitle {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 35px 35px;
  margin: 10px;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.text {
  width: 100px;
}
</style>
