<template>
  <div :class="$style.root" style="flex-wrap: wrap" ref="outline">
    <div style="display: flex;">
      <p :class="$style.title" ref="title">
        <slot name="title" :class="$style.title"/>
      </p>
      <div :class="$style.border">
        <slot name="data"></slot>
      </div>
    </div>
    <div :class="$style.msg" style="">
      <slot name="msg"/>
    </div>
    <div :class="$style.newline" style="">
      <slot name="additional"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fixWidth: null,
    minWidth: null
  },
  mounted () {
    if (this.fixWidth) {
      this.$refs.title.style.minWidth = this.fixWidth + 'px'
    }
    if (this.minWidth) {
      this.$refs.outline.style.minWidth = this.outline + 'px'
    }
  }
}
</script>

<style lang="scss" module>
.root {
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
}

.title {
  min-width: 30px;
  margin-right: 10px;
  font-size: 13px;
  color: #7e7e7e;
  font-weight: 500;
}

.border {
  border-bottom: solid 1px #e5e5e5;
  flex: 1;
  font-size: 15px;
  padding-bottom: 3.5px;
  box-sizing: border-box;
  font-weight: 500;
  overflow : hidden;
  text-overflow : ellipsis;
  white-space : nowrap;
}

.msg {
  font-size: 12px;
  color: #ff0000;
  margin-bottom: 5px;
  text-align: right;
  width: 100%;
  margin-right: 13%;
}
.newline {
  display: block;
  font-size: 14px;
  color: #444444;
}
</style>
