import Vue from 'vue'
import Vuex from 'vuex'
import delivery from '@/store/module/delivery'
import purchase from '@/store/module/purchase'
import shipment from '@/store/module/shipment'
import soReleased from '@/store/module/soReleased'
import usermanagement from '@/store/module/usermanagement'
import customer from '@/store/module/customer'
import measureCertificate from '@/store/module/measureCertificate'
import measure from '@/store/module/measure'
import checkReleased from '@/store/module/checkReleased'
import documentReleased from '@/store/module/documentReleased'
import containerload from '@/store/module/containerload'
import mblsi from '@/store/module/mblsi'
import mbl from '@/store/module/mbl'
import formlist from '@/store/module/formlist'
import spouch from '@/store/module/spouch'
import { Loading } from 'element-ui'
import { login, forgetPassword, checkResetPassword } from '@/api/login'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    customer,
    purchase,
    delivery,
    usermanagement,
    shipment,
    soReleased,
    measureCertificate,
    checkReleased,
    documentReleased,
    measure,
    containerload,
    mblsi,
    mbl,
    formlist,
    spouch
  },
  state: {
    lang: 'tw',
    pafupdata: null,
    pafupdataType: '',
    accessToken: '',
    tableData: [],
    tableLabel: [],
    pageData: {
      offset: 0,
      limit: 10
    },
    currentPagePath: '',
    loadingInstance: null,
    tableSelectData: [],
    searchInputList: [],
    addInputlist: [],
    orderId: '',
    orderDataInfo: [],
    pdfEmailList: '',
    pdfUrl: '',
    pdfUrlBySOA: '',
    pdfUrlBySOA2: '',
    infoData: {
      sender: {
        name: null
      },
      receiver_1: {
        name: null
      },
      receiver_3: {
        name: null
      },
      receiver_4: {
        name: null
      },
      receiver_2: {
        name: null
      }
    },
    steps: [{
      label: 'Shipment',
      link: 'shipment',
      status: '',
      description: '',
      key: 'delivery_point',
      key2: ['qu_latest_version'],
      version: []
    }, {
      label: 'CompleteBooking',
      link: 'completeBooking',
      status: '',
      description: '',
      key: 'shipment_point',
      key2: ['sa_latest_version'],
      version: []
    }, {
      label: 'Delivery',
      link: 'delivery',
      status: '',
      description: '',
      key: 'container_inyard_point',
      key2: [''],
      version: []
    }, {
      label: 'CargoMeasure',
      link: 'cargoMeasure',
      status: '',
      description: '',
      key: 'measure_point',
      key2: ['md_latest_version'],
      version: []
    }, {
      label: 'SoReleased',
      link: 'soReleased',
      status: '',
      description: '',
      key: 'so_point',
      key2: ['so_latest_version', 'iv_latest_version', 'pl_latest_version'],
      version: []
    }, {
      label: 'CustomsReleased',
      link: 'customsReleased',
      status: '',
      description: '',
      key: 'export_released_point',
      key2: [''],
      version: []
    }, {
      label: 'CheckReleased',
      link: 'checkReleased',
      status: '',
      description: '',
      key: 'hbl_point',
      key2: ['hc_latest_version'],
      version: []
    }, {
      label: 'QuotationConfirmed',
      link: 'quotationConfirmed',
      status: '',
      description: '',
      key: 'delivery_check_point',
      key2: ['qc_latest_version'],
      version: []
    }, {
      label: 'DocumentReleased',
      link: 'documentReleased',
      status: '',
      description: '',
      key: 'bill_notice_point',
      key2: ['hb_latest_version', 'dn_latest_version'],
      version: []
    }, {
      label: 'Departure',
      link: 'departure',
      status: '',
      description: '',
      key: 'peparture_point',
      key2: [''],
      version: []
    }, {
      label: 'PouchReceived',
      link: 'pouchReceived',
      status: '',
      description: '',
      key: 'pouch_point',
      key2: [''],
      version: []
    }, {
      label: 'Arrival',
      link: 'arrival',
      status: '',
      description: '',
      key: 'arrival_point',
      key2: [''],
      version: []
    }, {
      label: 'DoRelease',
      link: 'doRelease',
      status: '',
      description: '',
      key: 'release_point',
      key2: [''],
      version: []
    }],
    commRsp: {},
    commTitle: '',
    editRowData: {},
    dcNo: '',
    routerParams: {},
    pre_book_no: '',
    privateKeyCode: '',
    so_no: '',
    showNode: 'display',
    shipmentQuery: {},
    showUpload: true,
    spouchTitle: '',
    spouchLevel: '',
    todayDate: '',
    userData: {},
    flagQueryHistory: false
  },
  getters: {
    accessToken: (state) => state.accessToken,
    tableData: (state) => state.tableData,
    pageData: (state) => state.pageData,
    currentPagePath: (state) => state.currentPagePath,
    tableSelectData: (state) => state.tableSelectData,
    searchInputList: (state) => state.searchInputList,
    addInputlist: (state) => state.addInputlist,
    loadingInstance: (state) => state.loadingInstance,
    tableLabel: (state) => state.tableLabel,
    orderId: (state) => state.orderId,
    orderDataInfo: (state) => state.orderDataInfo,
    pdfEmailList: (state) => state.pdfEmailList,
    pdfUrl: (state) => state.pdfUrl,
    pdfUrlBySOA: (state) => state.pdfUrlBySOA,
    pdfUrlBySOA2: (state) => state.pdfUrlBySOA2,
    privateKeyCode: (state) => state.privateKeyCode,
    infoData: (state) => state.infoData,
    pafupdata: (state) => state.pafupdata,
    pafupdataType: (state) => state.pafupdataType,
    steps: (state) => state.steps,
    shipmentQuery: (state) => state.shipmentQuery,
    commRsp: (state) => state.commRsp,
    commTitle: (state) => state.commTitle,
    showUpload: (state) => state.showUpload,
    editRowData: (state) => state.editRowData,
    dcNo: (state) => state.dcNo,
    spouchTitle: (state) => state.spouchTitle,
    spouchLevel: (state) => state.spouchLevel,
    routerParams: (state) => state.routerParams,
    pre_book_no: (state) => state.pre_book_no,
    so_no: (state) => state.so_no,
    showNode: (state) => state.showNode,
    todayDate: (state) => state.todayDate,
    userData: (state) => state.userData,
    flagQueryHistory: (state) => state.flagQueryHistory
  },
  mutations: {
    flagQueryHistory (state, payload) {
      state.flagQueryHistory = payload
    },
    userData (state, payload) {
      state.userData = payload
    },
    lang (state, payload) {
      state.lang = payload.lang
    },
    pafupdata (state, payload) {
      state.pafupdata = payload
    },
    pafupdataType (state, payload) {
      state.pafupdataType = payload
    },
    accessToken (state, payload) {
      state.accessToken = payload.accessToken
    },
    tableData (state, payload) {
      state.tableData = payload
    },
    pageData (state, payload) {
      state.pageData = payload
    },
    currentPagePath (state, payload) {
      state.currentPagePath = payload
    },
    loadingInstance (state, payload) {
      state.loadingInstance = payload
    },
    tableSelectData (state, payload) {
      state.tableSelectData = payload
    },
    searchInputList (state, payload) {
      state.searchInputList = payload
    },
    addInputlist (state, payload) {
      state.addInputlist = payload
    },
    tableLabel (state, payload) {
      state.tableLabel = payload
    },
    orderId (state, payload) {
      state.orderId = payload
    },
    orderDataInfo (state, payload) {
      state.orderDataInfo = payload
    },
    pdfEmailList (state, payload) {
      state.pdfEmailList = payload
    },
    pdfUrl (state, payload) {
      state.pdfUrl = payload
    },
    pdfUrlBySOA (state, payload) {
      state.pdfUrlBySOA = payload
    },
    pdfUrlBySOA2 (state, payload) {
      state.pdfUrlBySOA2 = payload
    },
    privateKeyCode (state, payload) {
      state.privateKeyCode = payload
    },
    infoData (state, payload) {
      state.infoData = payload
    },
    steps (state, payload) {
      state.steps = payload
    },
    shipmentQuery (state, payload) {
      state.shipmentQuery = payload
    },
    commRsp (state, payload) {
      state.commRsp = payload
    },
    commTitle (state, payload) {
      state.commTitle = payload
    },
    showUpload (state, payload) {
      state.showUpload = payload
    },
    editRowData (state, payload) {
      state.editRowData = payload
    },
    dcNo (state, payload) {
      state.dcNo = payload
    },
    spouchTitle (state, payload) {
      state.spouchTitle = payload
    },
    spouchLevel (state, payload) {
      state.spouchLevel = payload
    },
    routerParams (state, payload) {
      state.routerParams = payload
    },
    pre_book_no (state, payload) {
      state.pre_book_no = payload
    },
    so_no (state, payload) {
      state.so_no = payload
    },
    showNode (state, payload) {
      state.showNode = payload
    }
  },
  actions: {
    userData: (context, payload) => {
      context.commit('userData', payload)
    },
    EmptyInfoData: (state) => {
      state.commit('infoData', {
        sender: {
          name: null
        },
        receiver_1: {
          name: null
        },
        receiver_2: {
          name: null
        },
        receiver_3: {
          name: null
        },
        receiver_4: {
          name: null
        }
      })
    },
    ForgetPassword: async (state, payload) => {
      try {
        const data = await forgetPassword(payload)
        return data
      } catch (err) {
        console.warn('[vuex] forget password', err)
        return err
      }
    },
    CheckResetPassword: async (state, payload) => {
      try {
        const data = await checkResetPassword(payload)
        return data
      } catch (err) {
        console.warn('[vuex] check Reset Password', err)
        return err
      }
    },
    Login: async ({ commit, dispatch }, payload) => {
      try {
        const data = await login(payload.id.trim(), payload.password.trim())
        // console.log('Login data', data)
        if (data.code === 200) {
          await commit('accessToken', data.data.accessToken)
          return data
        }
        return data
      } catch (err) {
        console.warn('[vuex] Login', err)
        return err
      }
    },
    clearData: (state, payload) => {
      state.commit('pdfEmailList', [])
      state.commit('tableData', [])
      state.commit('tableLabel', [])
      state.commit('pageData', {})
    },
    setTableData: (state, payload) => {
      state.commit('tableData', payload.data.ListData)
      // console.log('%', payload.data.PageData.count % payload.data.PageData.limit)
      // console.log('/', payload.data.PageData.count / payload.data.PageData.limit)
      payload.data.PageData.totalPage = payload.data.PageData.count % payload.data.PageData.limit === 0 ? payload.data.PageData.count / payload.data.PageData.limit : Math.ceil(payload.data.PageData.count / payload.data.PageData.limit)
      payload.data.PageData.currentPage = 1
      state.commit('pageData', payload.data.PageData)
    },
    setSearchCriteria: (state, payload) => {
      state.commit('searchCriteria', payload)
    },
    setCurrentPagePath: (state, payload) => {
      state.commit('currentPagePath', payload)
    },
    searchHandler: async (state, payload) => {
      console.log('searchHandler', state.getters.currentPagePath, 'payload', payload)
      switch (state.getters.currentPagePath) {
        case 'sshippingprice':
          await state.dispatch('formlistSearch', 'QU')
          break
        case 'list':
          await state.dispatch('purchaseSearch', payload)
          break
        case 'usermanagement':
          await state.dispatch('userManagementSearch')
          break
        case 'customermanagement':
          await state.dispatch('customerSearch')
          break
        case 'suppliermanagement':
          await state.dispatch('supplierSearch')
          break
        case 'smeasure':
          console.log('smeasure')
          await state.dispatch('measureSearch')
          break
        case 'spackinglist':
          await state.dispatch('containerloadSearch')
          break
        case 'smblso':
          await state.dispatch('mblsiSearch')
          break
        case 'smblcheck':
          await state.dispatch('mblSearch')
          break
        case 'scargo':
          await state.dispatch('formlistSearch', 'SA')
          break
        case 'smcertificate':
          await state.dispatch('formlistSearch', 'MD')
          break
        case 'sclearanceIV':
          await state.dispatch('formlistSearch', 'IV')
          break
        case 'sclearancePL':
          await state.dispatch('formlistSearch', 'PL')
          break
        case 'shblso':
          await state.dispatch('formlistSearch', 'SO')
          break
        case 'shblcheck':
          await state.dispatch('formlistSearch', 'HC')
          break
        case 'sdochbl':
          await state.dispatch('formlistSearch', 'HB')
          break
        case 'quotationConfirmation':
          await state.dispatch('formlistSearch', 'QC')
          break
        case 'sdocbill':
          await state.dispatch('formlistSearch', 'DN')
          break
        case 'spouchdc':
          await state.dispatch('spouchSearch', 'DC')
          break
        case 'spouchmb':
          await state.dispatch('spouchSearch', 'MB')
          break
        case 'spouchma':
          await state.dispatch('spouchSearch', 'MA')
          break
        case 'spouchhb':
          await state.dispatch('spouchSearch', 'HB')
          break
        case 'spouchiv':
          await state.dispatch('spouchSearch', 'IV')
          break
        case 'spouchpl':
          await state.dispatch('spouchSearch', 'PL')
          break
      }
    },
    modAddInputlist: async (state, payload) => {
      switch (state.getters.currentPagePath) {
        case 'customermanagement':
          await state.dispatch('modInputlistByCustomer')
          break
        case 'suppliermanagement':
          await state.dispatch('modInputlistBySupplier')
          break
      }
    },
    handleCurrentChange: async (state, payload) => {
      const currentPage = payload
      const path = state.getters.currentPagePath
      switch (path) {
        case 'sshippingprice':
          // await state.dispatch('deliveryMasterHandleCurrentChange', currentPage)
          await state.dispatch('formlistHandleCurrentChange', { file_type: 'QU', currentPage: currentPage })
          break
        case 'usermanagement':
          await state.dispatch('userManagementHandleCurrentChange', currentPage)
          break
        case 'userauthority':
          await state.dispatch('userauthorityHandleCurrentChange', currentPage)
          break
        case 'list':
          await state.dispatch('purchaseHandleCurrentChange', currentPage)
          break
        case 'customermanagement':
          await state.dispatch('customerHandleCurrentChange', currentPage)
          break
        case 'suppliermanagement':
          await state.dispatch('supplierHandleCurrentChange', currentPage)
          break
        case 'completeBooking':
          await state.dispatch('shipmentHandleCurrentChange', currentPage)
          break
        case 'cargoMeasure':
          await state.dispatch('measureCertificateHandleCurrentChange', currentPage)
          break
        case 'soReleased':
          await state.dispatch('hblsoSingleAndInvoicePackinglistCurrentChange', currentPage)
          break
        case 'ivReleased':
          await state.dispatch('hblsoSingleAndInvoicePackinglistCurrentChange', currentPage)
          break
        case 'plReleased':
          await state.dispatch('hblsoSingleAndInvoicePackinglistCurrentChange', currentPage)
          break
        case 'checkReleased':
          await state.dispatch('hblChecklistSingleCurrentChange', currentPage)
          break
        case 'smeasure':
          await state.dispatch('measureHandleCurrentChange', currentPage)
          break
        case 'spackinglist':
          await state.dispatch('containerloadHandleCurrentChange', currentPage)
          break
        case 'smblso':
          await state.dispatch('mblsiHandleCurrentChange', currentPage)
          break
        case 'smblcheck':
          await state.dispatch('mblHandleCurrentChange', currentPage)
          break
        case 'scargo':
          await state.dispatch('formlistHandleCurrentChange', { file_type: 'SA', currentPage: currentPage })
          break
        case 'smcertificate':
          await state.dispatch('formlistHandleCurrentChange', { file_type: 'MD', currentPage: currentPage })
          break
        case 'sclearanceIV':
          await state.dispatch('formlistHandleCurrentChange', { file_type: 'IV', currentPage: currentPage })
          break
        case 'sclearancePL':
          await state.dispatch('formlistHandleCurrentChange', { file_type: 'PL', currentPage: currentPage })
          break
        case 'shblso':
          await state.dispatch('formlistHandleCurrentChange', { file_type: 'SO', currentPage: currentPage })
          break
        case 'shblcheck':
          await state.dispatch('formlistHandleCurrentChange', { file_type: 'HC', currentPage: currentPage })
          break
        case 'sdochbl':
          await state.dispatch('formlistHandleCurrentChange', { file_type: 'HB', currentPage: currentPage })
          break
        case 'quotationConfirmation':
          await state.dispatch('formlistHandleCurrentChange', { file_type: 'QC', currentPage: currentPage })
          break
        case 'sdocbill':
          await state.dispatch('formlistHandleCurrentChange', { file_type: 'DN', currentPage: currentPage })
          break
        case 'spouchdc':
          await state.dispatch('spouchHandleCurrentChange', { doc_type: 'DC', currentPage: currentPage })
          break
        case 'spouchmb':
          await state.dispatch('spouchHandleCurrentChange', { doc_type: 'MB', currentPage: currentPage })
          break
        case 'spouchma':
          await state.dispatch('spouchHandleCurrentChange', { doc_type: 'MA', currentPage: currentPage })
          break
        case 'spouchhb':
          await state.dispatch('spouchHandleCurrentChange', { doc_type: 'HB', currentPage: currentPage })
          break
        case 'spouchiv':
          await state.dispatch('spouchHandleCurrentChange', { doc_type: 'IV', currentPage: currentPage })
          break
        case 'spouchpl':
          await state.dispatch('spouchHandleCurrentChange', { doc_type: 'PL', currentPage: currentPage })
          break
        case 'spouchDetailDC':
          await state.dispatch('spouchDetaileHandleCurrentChange', { doc_type: 'DC', currentPage: currentPage })
          break
        case 'spouchDetailMB':
          await state.dispatch('spouchDetaileHandleCurrentChange', { doc_type: 'MB', currentPage: currentPage })
          break
        case 'spouchDetailMA':
          await state.dispatch('spouchDetaileHandleCurrentChange', { doc_type: 'MA', currentPage: currentPage })
          break
        case 'spouchDetailHB':
          await state.dispatch('spouchDetaileHandleCurrentChange', { doc_type: 'HB', currentPage: currentPage })
          break
        case 'spouchDetailIV':
          await state.dispatch('spouchDetaileHandleCurrentChange', { doc_type: 'IV', currentPage: currentPage })
          break
        case 'spouchDetailPL':
          await state.dispatch('spouchDetaileHandleCurrentChange', { doc_type: 'PL', currentPage: currentPage })
          break
        default:
      }
      // console.log('handleCurrentChange body', body)
      // console.log('handleCurrentChange result', result)
    },
    handleDealDate: async (state, payload) => {
      const currentPage = payload
      const path = state.getters.currentPagePath
      switch (path) {
        case 'sshippingprice':
          await state.dispatch('deliveryMasterHandleCurrentChange', currentPage)
          break
        case 'usermanagement':
          await state.dispatch('userManagementHandleCurrentChange', currentPage)
          break
        default:
      }
      // console.log('handleCurrentChange body', body)
      // console.log('handleCurrentChange result', result)
    },
    delTableSelectData: async (state, payload) => {
      const currentPath = state.getters.currentPagePath
      console.log('delTableSelectData', currentPath)
      switch (currentPath) {
        // case 'sshippingprice':
        //   await state.dispatch('delTableSelectDataByDeliveryMaster')
        //   break
        case 'list':
          await state.dispatch('delTableSelectDataByPurchase')
          break
        case 'customermanagement':
          await state.dispatch('delTableSelectDataByCustomer')
          break
        case 'suppliermanagement':
          await state.dispatch('delTableSelectDataBySupplier')
          break
        case 'shipment':
          await state.dispatch('delTableSelectDataByDelivery', payload)
          break
        case 'completeBooking':
          await state.dispatch('delDataByShipment')
          break
      }
    },
    // preProcessinghandler: async (state, payload) => {
    //   const to = payload
    //   const toPath = to.name
    //   state.commit('currentPagePath', toPath)
    //   console.log('preProcessinghandler toPath', toPath)
    //   switch (toPath) {
    //     default:
    //       await state.dispatch('clearData')
    //       break
    //   }
    // },
    loadingStart: async (state) => {
      state.commit('loadingInstance', Loading.service({ fullscreen: true, background: 'rgba(0, 0, 0, 0.6)' }))
    },
    loadingClose: async (state) => {
      state.getters.loadingInstance.close()
      state.commit('loadingInstance', null)
    },
    preProcessinghandler: async (state, payload) => {
      const to = payload
      const toPath = to.name
      const params = to.params
      console.log('params', params)
      state.commit('currentPagePath', toPath)
      console.log('totototototo', toPath)
      // state.dispatch('loadingStart')
      if (params.operating !== 'view') {
        state.commit('infoData', [])
      }
      // console.log('preProcessinghandler toPath', toPath)
      // console.log('preProcessinghandler params', params)
      await state.dispatch('clearData')
      // state.commit('soTab', 0)
      switch (toPath) {
        case 'completeBooking':
        case 'delivery':
        case 'cargoMeasure':
        case 'ivReleased':
        case 'plReleased':
        case 'soReleased':
        case 'customsReleased':
        case 'checkReleased':
        case 'quotationConfirmed':
        case 'departure':
        case 'pouchReceived':
        case 'arrival':
        case 'doRelease':
          if (state.getters.orderDataInfo.length < 1) {
            params.withoutInfoData = true
            await state.dispatch('deliveryDetailSearch', params)
          }
          break
        case 'quotation':
          if (state.getters.orderDataInfo.length < 1) {
            await state.dispatch('deliveryDetailSearch', params)
          }
          break
      }
      switch (toPath) {
        case 'shipment':
          await state.dispatch('deliveryDetailSearch', params)
          break
        case 'quotation':
          switch (params.path !== undefined) {
            case true:
              break
            default:
              await state.dispatch('beforEditByDelivery', params)
              break
          }
          break
        case 'completeBooking':
        case 'scargoReport':
          await state.dispatch('shipmentSearch', params)
          break
        case 'notice':
          await state.dispatch('beforEditByShipment', params)
          break
        case 'cargoMeasure':
        case 'smcertificateReport':
          await state.dispatch('measureCertificateSearch', params)
          break
        case 'bill':
          await state.dispatch('hblsoSingleSearch', params)
          break
        case 'soReleased':
        case 'ivReleased':
        case 'plReleased':
          console.log('toPath', toPath, 'sotab 012')
          break
        case 'checkReleased':
          await state.dispatch('hblChecklistSingleSearch', params)
          break
        case 'quotationConfirmed':
          await state.dispatch('quotationConfirmedSearch', params)
          break
        // case 'confirmed':
        //   await state.dispatch('beforEditByConfirmed', params)
        //   break
        case 'sshippingprice':
          // await state.dispatch('setDeliveryMasterInputList')
          // await state.dispatch('deliveryMasterSearch')
          await state.dispatch('formlistSearch', 'QU')
          break
        case 'usermanagement':
          await state.dispatch('userManagementInputList')
          await state.dispatch('userManagementSearch')
          break
        case 'list':
          await state.dispatch('setPurchaseInputList')
          // await state.dispatch('purchaseSearch')
          break
        case 'customermanagement':
          await state.dispatch('setCustomerInputList')
          await state.dispatch('customerSearch')
          break
        case 'suppliermanagement':
          await state.dispatch('setSupplierInputList')
          await state.dispatch('supplierSearch')
          break
        case 'smeasure':
          await state.dispatch('setmeasureInputList')
          await state.dispatch('measureSearch')
          break
        case 'measure':
          await state.dispatch('measureDetailSearch', params.ship_num)
          break
        case 'spackinglist':
          await state.dispatch('containerloadInputList')
          await state.dispatch('containerloadSearch')
          break
        case 'containerload':
          await state.dispatch('containerloadDetailSearch', { ship_num: params.ship_num, vsl_name: params.vsl_name, voyage: params.voyage, cfs_delivery_place: params.cfs_delivery_place, port_discharge: params.port_discharge })
          break
        case 'smblso':
          await state.dispatch('mblsiInputList')
          await state.dispatch('mblsiSearch')
          break
        case 'smblcheck':
          await state.dispatch('mblInputList')
          await state.dispatch('mblSearch')
          break
        case 'mblsi':
          await state.dispatch('mblsiDetailSearch', { ship_num: params.ship_num, vsl_name: params.vsl_name, voyage: params.voyage, carrier_agent: params.carrier_agent, so_no: params.so_no })
          break
        case 'mbl':
          await state.dispatch('mblDetailSearch', params.ship_num)
          break
        case 'scargo':
          await state.dispatch('formlistSearch', 'SA')
          break
        case 'smcertificate':
          await state.dispatch('formlistSearch', 'MD')
          break
        case 'sclearanceIV':
          await state.dispatch('formlistSearch', 'IV')
          break
        case 'sclearancePL':
          await state.dispatch('formlistSearch', 'PL')
          break
        case 'shblso':
          await state.dispatch('formlistSearch', 'SO')
          break
        case 'shblcheck':
          await state.dispatch('formlistSearch', 'HC')
          break
        case 'sdochbl':
          await state.dispatch('formlistSearch', 'HB')
          break
        case 'quotationConfirmation':
          await state.dispatch('formlistSearch', 'QC')
          break
        case 'sdocbill':
          await state.dispatch('formlistSearch', 'DN')
          break
        case 'spouchdc':
          await state.dispatch('spouchSearch', 'DC')
          break
        case 'spouchmb':
          await state.dispatch('spouchSearch', 'MB')
          break
        case 'spouchma':
          await state.dispatch('spouchSearch', 'MA')
          break
        case 'spouchhb':
          await state.dispatch('spouchSearch', 'HB')
          break
        case 'spouchiv':
          await state.dispatch('spouchSearch', 'IV')
          break
        case 'spouchpl':
          await state.dispatch('spouchSearch', 'PL')
          break
        case 'spouchDetailDC':
        case 'spouchDetailMB':
        case 'spouchDetailMA':
        case 'spouchDetailHB':
        case 'spouchDetailIV':
        case 'spouchDetailPL':
          // await state.commit('routerParams', params)
          // 由tab vue 去觸發searchPouchDetail dispatch
          break
        case 'spouchdc2':
          await state.commit('dcNo', params.dc_no)
          await state.commit('routerParams', params)
          // await state.dispatch('spouchDetailSearch', { doc_type: 'DC', voyage: params.voyage, carrier_agent: params.carrier_agent, vsl_name: params.vsl_name, dc_no: params.dc_no })
          break
        case 'spouchmb2':
          state.commit('dcNo', params.dc_no)
          await state.dispatch('spouchDetailSearch', { doc_type: 'MB', dc_no: params.dc_no })
          break
        case 'spouchma2':
          state.commit('dcNo', params.dc_no)
          await state.dispatch('spouchDetailSearch', { doc_type: 'MA', dc_no: params.dc_no })
          break
        case 'spouchhb2':
          state.commit('dcNo', params.dc_no)
          await state.dispatch('spouchDetailSearch', { doc_type: 'HB', dc_no: params.dc_no })
          break
        case 'spouchiv2':
          state.commit('dcNo', params.dc_no)
          await state.dispatch('spouchDetailSearch', { doc_type: 'IV', dc_no: params.dc_no })
          break
        case 'spouchpl2':
          state.commit('dcNo', params.dc_no)
          await state.dispatch('spouchDetailSearch', { doc_type: 'PL', dc_no: params.dc_no })
          break
        case 'userauthority':
          await state.dispatch('userPermissions')
          break
        default:
          await state.dispatch('clearData')
          break
      }
    },
    preProcessinghandlerByPdf: async (state, payload) => {
      const to = payload
      const toPath = to.name
      const params = to.params
      // console.log('preProcessinghandlerByPdf toPath', toPath)
      switch (toPath) {
        case 'shipment':
        case 'quotation':
          await state.dispatch('deliveryDetailSearch', params)
          break
        case 'list':
          await state.dispatch('deliveryDetailSearchByQuotation', params)
          break
        default:
          break
      }
    },
    addDatahandler: async (state, payload) => {
      console.log('addDatahandler', state.getters.currentPagePath)
      switch (state.getters.currentPagePath) {
        case 'shipment':
          await state.dispatch('addDataByDelivery', payload)
          break
        case 'customermanagement':
          await state.dispatch('addDataByCustomer')
          break
        case 'suppliermanagement':
          await state.dispatch('addDataBySupplier')
          break
        case 'completeBooking':
          await state.dispatch('addDataByShipment')
          break
        default:
          break
      }
    },
    editDatahandler: async (state, payload) => {
      // console.log('editDatahandler', state.getters.currentPagePath)
      // console.log('payload', payload)
      switch (state.getters.currentPagePath) {
        case 'quotation':
          await state.dispatch('editDataByDelivery')
          break
        case 'notice':
          await state.dispatch('editDataByShipment', payload)
          break
        // case 'customermanagement':
        //   await state.dispatch('addDataByCustomer')
        //   break
        // case 'suppliermanagement':
        //   await state.dispatch('addDataBySupplier')
        //   break
        default:
          break
      }
    }
  }
})
