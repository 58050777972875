import axios from 'axios'

export const purchaseSingle = async (body) => await axios.post('api/purchase/single', body).then((response) => response)// 訂單詳細資訊

export const purchaseList = async (body) => await axios.post('api/purchase/list', body).then((response) => response)// 訂單詳細資訊

export const purchaseCreate = async (body) => await axios.post('api/purchase/create', body).then((response) => response)// 訂單新增

export const purchaseDelete = async (body) => await axios.post('api/purchase/delete', body).then((response) => response)// 訂單刪除

export const purchaseUpdate = async (body) => await axios.post('api/purchase/update', body).then((response) => response)// 訂單修改
